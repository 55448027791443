import { push } from 'react-router-redux'
import { updateWelcomeCard as updateWelcomeCardApi, finishOnboarding as finishOnboardingApi } from '../api/cards'
import {
  UPDATE_WELCOME_CARD_REQUEST,
  UPDATE_WELCOME_CARD_SUCCESS,
  UPDATE_WELCOME_CARD_FAILURE,
  FINISH_ONBOARDING_REQUEST,
  FINISH_ONBOARDING_SUCCESS,
  FINISH_ONBOARDING_FAILURE
} from '../constants/cards'
import { providerAppUrl } from '../utils/urls'

export const updateWelcomeCard = (card, userId) => async (dispatch, getState) => {
  const index = getState().settings.welcomeCards.indexOf(card)

  if (index === -1) {
    dispatch({ type: UPDATE_WELCOME_CARD_FAILURE, card, error: 'Invalid welcome card' })
    return false
  }

  dispatch(push(`/become-a-cleaner/${userId}/cards/${card}`))

  dispatch({ type: UPDATE_WELCOME_CARD_REQUEST, card })
  try {
    await updateWelcomeCardApi(card)

    dispatch({
      type: UPDATE_WELCOME_CARD_SUCCESS,
      card
    })
    return true
  } catch (error) {
    dispatch({ type: UPDATE_WELCOME_CARD_FAILURE, card, error })
    return false
  }
}

export const finishOnboarding = page => async dispatch => {
  dispatch({ type: FINISH_ONBOARDING_REQUEST })

  try {
    const response = await finishOnboardingApi()
    const { data: { finishOnboarding: { success } } } = response

    if (success) {
      dispatch({ type: FINISH_ONBOARDING_SUCCESS })
      window.location.assign(`//${providerAppUrl}/mobile/provider/${page}`)
    } else {
      dispatch({ type: FINISH_ONBOARDING_FAILURE })
    }
  } catch (exception) {
    dispatch({ type: FINISH_ONBOARDING_FAILURE, exception })
    throw exception
  }
}
