import {
  FETCH_LIVE_STATE,
  FETCH_LIVE_STATE_SUCCESS,
  FETCH_LIVE_STATE_FAIL,
  LIVE_STATE_CLICK_START,
  LIVE_STATE_CLICK_END
} from '../constants/live_state'

export const initialState = {
  applicantLive: false,
  showCheckStatusButton: false,
  redirectTo: '',
  checkedByUser: false,
  isLoading: false,
  isChecking: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIVE_STATE: {
      return {
        ...state,
        isLoading: true
      }
    }
    case FETCH_LIVE_STATE_FAIL: {
      return {
        ...state,
        isLoading: false
      }
    }
    case FETCH_LIVE_STATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        showCheckStatusButton: action.payload.data.data.show_check_status_button
      }
    }
    case LIVE_STATE_CLICK_START: {
      return {
        ...state,
        isChecking: true
      }
    }
    case LIVE_STATE_CLICK_END: {
      return {
        ...state,
        applicantLive: action.data.applicant_live,
        showCheckStatusButton: action.data.show_check_status_button,
        redirectTo: action.data.redirect_to,
        checkedByUser: true,
        isChecking: false
      }
    }
    default:
      return state
  }
}
