import { connect } from 'react-redux'
import Welcome from '../components/welcome'
import { getOnfidoData, startOnfidoCheck, checkAlreadyStarted } from '../actions/onfido'

export default connect(
  state => ({
    onfido: state.onfido,
    country: state.settings.country.name
  }),
  dispatch => ({
    getOnfidoData: userId => dispatch(getOnfidoData(userId)),
    onDocumentUploadCompleted: userId => dispatch(startOnfidoCheck(userId)),
    checkAlreadyStarted: userId => dispatch(checkAlreadyStarted(userId))
  })
)(Welcome)
