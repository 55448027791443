import PropTypes from 'prop-types'
import React from 'react'
import humps from 'humps'
import VerticalsItem from './verticals_item'

const VerticalsGroup = ({ verticals, group, handleVerticalChange }) => (
  <React.Fragment>
    <p className='text-left'>{I18n.t(`verticals.groups.label_${humps.decamelize(group)}`)}:</p>
    {Object.keys(verticals[group]).map(item => (
      <VerticalsItem
        key={item}
        handleVerticalChange={handleVerticalChange}
        group={group}
        item={item}
        value={verticals[group][item]}
      />
    ))}
    <hr />
  </React.Fragment>
)

VerticalsGroup.propTypes = {
  verticals: PropTypes.object.isRequired,
  group: PropTypes.string.isRequired,
  handleVerticalChange: PropTypes.func.isRequired
}

export default VerticalsGroup
