import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import Menu from '../../containers/menu'

const Page = ({ isLoading, children, progress }) => (
  <div className={cx('page', { 'page--loading': isLoading })}>
    <Menu languagesMenuEnabled />

    <div className='page__progress-bar'>
      <div style={{ width: `${isNaN(progress) ? 0 : progress}%` }} />
    </div>

    {children}
  </div>
)

Page.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  progress: PropTypes.number.isRequired
}

Page.defaultProps = {
  isLoading: false
}

export default Page
