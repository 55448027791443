import PropTypes from 'prop-types'
import React from 'react'

const ListItem = ({ children, extraClasses }) => (
  <div className={['list-item'].concat(extraClasses).join(' ')}>{children}</div>
)

ListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  extraClasses: PropTypes.arrayOf(PropTypes.string)
}

ListItem.defaultTypes = {
  extraClasses: []
}

export default ListItem
