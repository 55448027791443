import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'lasagna'

const TextField = ({ textAnswer, onContinue, onTextChange }) => (
  <div>
    <textarea rows={5} value={textAnswer} onChange={e => onTextChange(e.currentTarget.value)} />

    <div className='answer-list__actions'>
      <Button
        disabled={/^\s*$/.test(textAnswer)}
        action
        expanded
        rounded
        screaming
        onClick={() => onContinue(textAnswer)}
      >
        {I18n.t('eligibility.continue')}
      </Button>
    </div>
  </div>
)

TextField.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  textAnswer: PropTypes.string.isRequired
}

export default TextField
