import {
  CLOSE_ERROR_BOX,
  OPEN_ERROR_BOX,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_FAIL,
  SUCCESS_REDIRECT,
  UPDATE_FORM_DATA,
  FIELD_PASSWORD,
  FIELD_GENDER,
  FIELD_DOB_DAY,
  FIELD_DOB_MONTH,
  FIELD_DOB_YEAR,
  FIELD_DOB,
  FIELD_NATIONALITY,
  FIELD_POSTCODE,
  FIELD_ADDRESS1,
  FIELD_ADDRESS2,
  FIELD_TOWN,
  GET_PERSONAL_DETAILS_SETTINGS_SUCCESS
} from '../constants/personal_details'
import { GET_CONTACTS_SETTINGS_SUCCESS } from '../constants/contact_details'

import { parseErrorKeys } from '../utils/shared'

export const initialState = {
  user: {
    [FIELD_PASSWORD]: '',
    [FIELD_GENDER]: '',
    [FIELD_DOB_DAY]: '',
    [FIELD_DOB_MONTH]: '',
    [FIELD_DOB_YEAR]: '',
    [FIELD_NATIONALITY]: '',
    [FIELD_POSTCODE]: '',
    [FIELD_ADDRESS1]: '',
    [FIELD_ADDRESS2]: '',
    [FIELD_TOWN]: ''
  },
  data: {
    success: false,
    redirectTo: ''
  },
  errors: {
    [FIELD_PASSWORD]: '',
    [FIELD_GENDER]: '',
    [FIELD_DOB]: '',
    [FIELD_NATIONALITY]: '',
    [FIELD_POSTCODE]: '',
    [FIELD_ADDRESS1]: '',
    [FIELD_ADDRESS2]: '',
    [FIELD_TOWN]: ''
  },
  isLoading: false,
  isErrorBoxVisible: false,
  settings: null
}

const parseErrors = errors => {
  const address1 = (errors[2].address1 && errors[2].address1[0]) || ''
  const password = (errors[0].password && errors[0].password[0]) || ''
  const nationality = (errors[1].nationality && errors[1].nationality[0]) || ''
  const dob = (errors[1].dob && errors[1].dob[0]) || ''
  const gender = (errors[1].gender && errors[1].gender[0]) || ''
  const town = (errors[2].town && errors[2].town[0]) || ''
  const postcode = (errors[2].postcode && errors[2].postcode[0]) || ''

  return {
    address1,
    password,
    nationality,
    dob,
    gender,
    town,
    postcode
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_ERROR_BOX: {
      return {
        ...state,
        isErrorBoxVisible: false
      }
    }
    case OPEN_ERROR_BOX: {
      return {
        ...state,
        isErrorBoxVisible: true
      }
    }
    case SUBMIT_FORM: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SUBMIT_FORM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: {
          success: true,
          redirectTo: action.payload.data.data.redirect_to
        }
      }
    }
    case SUBMIT_FORM_FAIL: {
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          ...parseErrors(action.error.response ? action.error.response.data.errors : {})
        }
      }
    }
    case SUCCESS_REDIRECT: {
      return {
        ...state,
        isLoading: true
      }
    }
    case UPDATE_FORM_DATA: {
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.key]: action.payload.val
        },
        errors: {
          ...state.errors,
          [parseErrorKeys(action.payload.key)]: ''
        }
      }
    }
    case GET_PERSONAL_DETAILS_SETTINGS_SUCCESS: {
      const settings = action.payload.data.data
      return {
        ...state,
        settings,
        user: {
          ...state.user,
          [FIELD_POSTCODE]:
            settings.postcode && settings.postcode !== '' ? settings.postcode : state.user[FIELD_POSTCODE]
        }
      }
    }
    case GET_CONTACTS_SETTINGS_SUCCESS: {
      const { fullPostcode } = action.payload.data.data
      return {
        ...state,
        user: {
          ...state.user,
          [FIELD_POSTCODE]: fullPostcode || state.user[FIELD_POSTCODE]
        }
      }
    }
    default:
      return state
  }
}
