import gql from 'graphql-tag'
import client from './gql'

export const createOnfidoJWT = () => {
  const mutation = gql`
    mutation createOnfidoJWT {
      createOnfidoJWT {
        token
      }
    }
  `

  return client.mutate({ mutation })
}

export const createOnfidoCheck = () => {
  const mutation = gql`
    mutation createOnfidoCheck {
      createOnfidoCheck {
        result
      }
    }
  `

  return client.mutate({ mutation })
}

export const getOnfidoApplicant = () => {
  const query = gql`
    query getOnfidoApplicant {
      onfidoApplicant {
        result
      }
    }
  `

  return client.query({ query })
}

export const updateUserProfileAbilities = services => {
  const mutation = gql`
    mutation applicantUpdateUserProfileAbilities(
      $petsOk: Boolean
      $windowsOk: Boolean
      $laundryOk: Boolean
      $ironingOk: Boolean
    ) {
      updateUserProfileAbilities(
        abilities: { petsOk: $petsOk, windowsOk: $windowsOk, laundryOk: $laundryOk, ironingOk: $ironingOk }
      ) {
        ironingOk
        laundryOk
        petsOk
        windowsOk
      }
    }
  `

  return client.mutate({
    mutation,
    variables: {
      petsOk: services.indexOf('pets') !== -1,
      windowsOk: services.indexOf('windows') !== -1,
      laundryOk: services.indexOf('laundry') !== -1,
      ironingOk: services.indexOf('ironing') !== -1
    }
  })
}

export const updateUserVerticals = (verticals, comment) => {
  const mutation = gql`
    mutation updateUserVerticals($vertical: UserVertical) {
      updateUserVerticals(vertical: $vertical) {
        success
      }
    }
  `

  return client.mutate({
    mutation,
    variables: {
      vertical: {
        ...verticals,
        comment
      }
    }
  })
}
