export const GET_CALL_REQUEST = 'GET_CALL_REQUEST'
export const GET_CALL_REQUEST_SUCCESS = 'GET_CALL_REQUEST_SUCCESS'
export const GET_CALL_REQUEST_FAIL = 'GET_CALL_REQUEST_FAIL'
export const POST_CALL_REQUEST = 'POST_CALL_REQUEST'
export const POST_CALL_REQUEST_SUCCESS = 'POST_CALL_REQUEST_SUCCESS'
export const POST_CALL_REQUEST_FAIL = 'POST_CALL_REQUEST_FAIL'

export const ASAP = 'ASAP'
export const SPECIFIC_TIME = 'SPECIFIC_TIME'
export const NEVER = 'NEVER'
