import { push } from 'react-router-redux'
import {
  GET_EXPERIENCE_REQUEST,
  GET_EXPERIENCE_SUCCESS,
  GET_EXPERIENCE_FAILURE,
  SET_EXPERIENCE_REQUEST,
  SET_EXPERIENCE_FAILURE,
  SET_EXPERIENCE_SUCCESS,
  SET_EXPERIENCE_TEXT_REQUEST,
  SET_EXPERIENCE_TEXT_FAILURE,
  SET_EXPERIENCE_TEXT_SUCCESS,
  UPDATE_EXPERIENCE_FIELD
} from '../../constants/post_onfido/experience'
import { UPDATE_WELCOME_CARDS } from '../../constants/settings'
import { experienceQuestion, storeExperienceQuestion, storeExperienceText } from '../../api/experience'

export const getExperienceQuestion = () => dispatch => {
  dispatch({ type: GET_EXPERIENCE_REQUEST })

  return experienceQuestion()
    .then(({ data: { experienceQuestion: question, welcomeCards } }) => {
      dispatch({ type: GET_EXPERIENCE_SUCCESS, question })
      dispatch({ type: UPDATE_WELCOME_CARDS, welcomeCards })
    })
    .catch(exception => {
      dispatch({ type: GET_EXPERIENCE_FAILURE, exception })
    })
}

export const saveExperience = (selected, userId) => dispatch => {
  dispatch({ type: SET_EXPERIENCE_REQUEST, selected, userId })

  return storeExperienceQuestion(selected)
    .then(({ data }) => {
      dispatch({ type: SET_EXPERIENCE_SUCCESS, data })
      if (data.storeExperienceQuestion.success) {
        dispatch(push(`/become-a-cleaner/${userId}/experience-text`))
      } else {
        throw new Error('Save experience answer failed!')
      }
    })
    .catch(exception => {
      dispatch({ type: SET_EXPERIENCE_FAILURE, exception })
    })
}

export const saveExperienceText = (headline, description, userId) => dispatch => {
  dispatch({ type: SET_EXPERIENCE_TEXT_REQUEST, headline, description })

  return storeExperienceText(headline, description)
    .then(() => {
      dispatch({ type: SET_EXPERIENCE_TEXT_SUCCESS })
      dispatch(push(`/become-a-cleaner/${userId}/services`))
    })
    .catch(exception => {
      dispatch({ type: SET_EXPERIENCE_TEXT_FAILURE, exception })
    })
}

export const updateExperienceField = (name, value) => dispatch =>
  dispatch({ type: UPDATE_EXPERIENCE_FIELD, name, value })
