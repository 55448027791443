import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router'
import { Button } from 'lasagna'
import Page from '../shared/page'
import petsnotokIcon from '../../../svg/petsnotok.svg'
import petsokIcon from '../../../svg/petsok.svg'
import ironingIcon from '../../../svg/ironing.svg'
import laundryIcon from '../../../svg/laundry.svg'
import windowsIcon from '../../../svg/windows.svg'

const Services = ({ isSaving, selectedServices, saveServices, handleServiceChange, routeParams: { userId } }) => (
  <Page isLoading={isSaving} progress={60}>
    <div className='row services'>
      <div className='small-12 large-6 large-offset-3'>
        <p className='services__title'>{I18n.t('services.cleaning_services.title')}</p>
      </div>
      <div className='small-4 large-2 large-offset-3 column'>
        <button
          disabled={isSaving}
          className={cx('services__item', {
            'services__item--active': selectedServices.indexOf('ironing') > -1
          })}
          onClick={() => handleServiceChange('ironing')}
        >
          <svg>
            <use xlinkHref={`#${ironingIcon.id}`} />
          </svg>
          {I18n.t('services.cleaning_services.ironing')}
        </button>
      </div>
      <div className='small-4 large-2 column'>
        <button
          disabled={isSaving}
          className={cx('services__item', {
            'services__item--active': selectedServices.indexOf('laundry') > -1
          })}
          onClick={() => handleServiceChange('laundry')}
        >
          <svg>
            <use xlinkHref={`#${laundryIcon.id}`} />
          </svg>
          {I18n.t('services.cleaning_services.laundry')}
        </button>
      </div>
      <div className='small-4 large-2 column'>
        <button
          disabled={isSaving}
          className={cx('services__item', {
            'services__item--active': selectedServices.indexOf('windows') > -1
          })}
          onClick={() => handleServiceChange('windows')}
        >
          <svg>
            <use xlinkHref={`#${windowsIcon.id}`} />
          </svg>
          {I18n.t('services.cleaning_services.windows')}
        </button>
      </div>

      <div className='small-12 large-6 large-offset-3'>
        <p className='services__title'>{I18n.t('services.pets.title')}</p>
      </div>
      <div className='small-4 small-offset-2 large-2 large-offset-4 column'>
        <button
          disabled={isSaving || selectedServices.indexOf('pets') > -1}
          className={cx('services__item', {
            'services__item--active': selectedServices.indexOf('pets') > -1
          })}
          onClick={() => handleServiceChange('pets')}
        >
          <svg>
            <use xlinkHref={`#${petsokIcon.id}`} />
          </svg>
          {I18n.t('services.pets.ok')}
        </button>
      </div>
      <div className='small-4 large-2 column'>
        <button
          disabled={isSaving || selectedServices.indexOf('pets') === -1}
          className={cx('services__item', {
            'services__item--active': selectedServices.indexOf('pets') === -1
          })}
          onClick={() => handleServiceChange('pets')}
        >
          <svg>
            <use xlinkHref={`#${petsnotokIcon.id}`} />
          </svg>
          {I18n.t('services.pets.not_ok')}
        </button>
      </div>
      <div className='small-12 large-6 large-offset-3'>
        <div className='simple-box services__action'>
          <Button
            action
            expanded
            rounded
            screaming
            disabled={isSaving}
            onClick={() => saveServices(selectedServices, userId)}
          >
            {I18n.t('services.next')}
          </Button>

          <Link className='back-button' to={`/become-a-cleaner/${userId}/experience-text`}>
            &lt; {I18n.t('services.back')}
          </Link>
        </div>
      </div>
    </div>
  </Page>
)

Services.propTypes = {
  routeParams: PropTypes.shape({
    userId: PropTypes.string
  }),
  isSaving: PropTypes.bool.isRequired,
  selectedServices: PropTypes.array.isRequired,
  saveServices: PropTypes.func.isRequired,
  handleServiceChange: PropTypes.func.isRequired
}

export default Services
