import bathroom from '../../../images/eligibility/bathroom.jpg'
import hand from '../../../images/eligibility/hand.png'
import iron from '../../../images/eligibility/iron.jpg'
import kitchen from '../../../images/eligibility/kitchen.jpg'
import notes from '../../../images/eligibility/notes.png'
import triangle from '../../../images/eligibility/triangle.png'

const images = {
  bathroom,
  hand,
  iron,
  kitchen,
  notes,
  triangle
}

export default images
