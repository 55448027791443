import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withCookies, Cookies } from 'react-cookie'
import Menu from '../components/shared/menu'
import { setPreferredLanguage } from '../actions/settings'
import { logOutApplicant } from '../actions/applicant'

const MenuWrapper = props => {
  const { invalidating, languages, currentLanguage, languagesMenuEnabled, withoutUser, isMobile, cookies } = props

  return (
    <Menu
      invalidating={invalidating}
      logOutApplicant={props.logOutApplicant}
      languages={languages}
      currentLanguage={currentLanguage}
      languagesMenuEnabled={languagesMenuEnabled}
      withoutUser={withoutUser}
      isMobile={isMobile}
      setPreferredLanguage={
        withoutUser
          ? key => {
            cookies.set('preferred_language', key, {
              path: '/',
              domain: window.location.hostname.replace('app.', '')
            })
            window.location.reload()
          }
          : props.setPreferredLanguage
      }
    />
  )
}

MenuWrapper.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  invalidating: PropTypes.bool.isRequired,
  logOutApplicant: PropTypes.func.isRequired,
  languages: PropTypes.object,
  currentLanguage: PropTypes.string,
  languagesMenuEnabled: PropTypes.bool,
  withoutUser: PropTypes.bool,
  isMobile: PropTypes.bool,
  setPreferredLanguage: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  userId: ownProps.userId,
  invalidating: state.applicant.invalidating,
  languages: state.settings.languages,
  currentLanguage: state.settings.country.locale,
  isMobile: state.settings.isMobile
})

const mapDispatchToProps = dispatch => ({
  logOutApplicant: url => dispatch(logOutApplicant(url)),
  setPreferredLanguage: language => dispatch(setPreferredLanguage(language))
})

export default connect(mapStateToProps, mapDispatchToProps)(withCookies(MenuWrapper))
