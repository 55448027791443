import { connect } from 'react-redux'
import MobileVerification from '../components/mobile_verification'
import {
  getApplicantMobile,
  openEditMobile,
  closeEditMobile,
  setApplicantMobile,
  changeModalMobile,
  requestVerificationCode,
  checkVerificationCode,
  changeCode
} from '../actions/mobile_verification'
import { makeDispatcher } from '../utils/shared'

const mapStateToProps = state => ({
  loading: state.mobileVerification.loading,
  mobile: state.mobileVerification.mobile,
  invalid: state.mobileVerification.invalid,
  allowSubmit: state.mobileVerification.allowSubmit,

  modal: {
    visible: state.mobileVerification.modal.visible,
    saving: state.mobileVerification.modal.saving,
    errors: state.mobileVerification.modal.errors,
    mobile: state.mobileVerification.modal.mobile
  },

  resent: state.mobileVerification.resent,
  resendError: state.mobileVerification.resendError,
  editedMobile: state.mobileVerification.editedMobile || '',
  code: state.mobileVerification.code
})

const mapDispatchToProps = dispatch => ({
  getApplicantMobile: makeDispatcher(dispatch, getApplicantMobile),
  setApplicantMobile: makeDispatcher(dispatch, setApplicantMobile),

  onOpenEdit: makeDispatcher(dispatch, openEditMobile),
  onCloseEdit: makeDispatcher(dispatch, closeEditMobile),

  onModalMobileChange: makeDispatcher(dispatch, changeModalMobile),
  onCodeChange: makeDispatcher(dispatch, changeCode),

  onResend: () => dispatch(requestVerificationCode(true)),
  onSubmit: makeDispatcher(dispatch, checkVerificationCode)
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerification)
