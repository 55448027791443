import { push } from 'react-router-redux'
import {
  SET_RIGHT_TO_WORK_REQUEST,
  SET_RIGHT_TO_WORK_FAILURE,
  SET_RIGHT_TO_WORK_SUCCESS
} from '../constants/right_to_work'
import { storeRightToWorkQuestion } from '../api/right_to_work'

export const redirectToNextUrl = (selected, userId, successRedirectUrl) => dispatch =>
  dispatch(
    push(selected === 'right-to-work-no' ? `/become-a-cleaner/${userId}/right_to_work_fail` : successRedirectUrl)
  )

export const saveRightToWork = (selected, userId) => dispatch => {
  dispatch({ type: SET_RIGHT_TO_WORK_REQUEST, selected })

  return storeRightToWorkQuestion(selected === 'right-to-work-yes')
    .then(res => {
      const { successRedirectUrl } = res.data.storeRightToWork

      dispatch({ type: SET_RIGHT_TO_WORK_SUCCESS })
      dispatch(redirectToNextUrl(selected, userId, successRedirectUrl))
    })
    .catch(exception => {
      dispatch({ type: SET_RIGHT_TO_WORK_FAILURE, exception })
    })
}
