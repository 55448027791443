import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cx from 'classnames'
import { Button } from 'lasagna'
import AnswerList from '../shared/answer_list'
import FreeTextAnswerList from './free_text_answer_list'
import TextField from './text_field'
import Page from '../shared/page'
import Modal from '../../shared/components/modal'
import YoutubeVideo from '../shared/youtube_video'
import infoIcon from '../../../svg/info.svg'
import images from './images'

class Eligibility extends Component {
  componentWillMount() {
    this.setState({ modalVisible: false })

    this.props.apiGetQuestion(this.props.router.location.query.question)
  }

  onSelectAnswer = (questionValue, freeTextValue = '') => {
    this.props.apiSendAnswer(
      {
        key: this.props.question.key,
        value: questionValue,
        freeTextValue
      },
      this.props.nextQuestion
    )
  }

  goToPreviousQuestion = () => {
    this.props.apiGetQuestion(this.props.previousQuestion)
  }

  isGermanBusinessLicense = () => this.props.country === 'de' && this.props.question.key === 'business_license2'

  // If we are on 'business_license2' question in DE, we have to split question title
  // (last word should be a separate string put in link that will open a modal)
  //
  humanTitleFirstPart = str => {
    if (this.isGermanBusinessLicense()) {
      return `${str.substring(0, str.lastIndexOf(' '))} `
    }

    return str
  }

  humanTitleLastPart = str => {
    if (this.isGermanBusinessLicense()) {
      return str.split(' ').slice(-1)
    }

    return ''
  }

  toggleBusinessLicenceModal = () => this.setState({ modalVisible: !this.state.modalVisible })

  render() {
    const { answeredQuestionsTotal, isLoading, previousQuestion, question, questionsTotal } = this.props
    const percentageProgressBar = answeredQuestionsTotal / questionsTotal * 100
    const freeTextQuestion = !!question.answers.filter(i => i.allowFreeText).length

    return (
      <Page isLoading={isLoading} progress={percentageProgressBar}>
        <div className='row'>
          <Modal closeHandler={this.toggleBusinessLicenceModal} showModal={this.state.modalVisible}>
            <div>
              <div className='modal__title'>{I18n.t('business_license_information.title1')}</div>
              <div className='modal__body'>{I18n.t('business_license_information.desc1')}</div>
              <div className='modal__title'>{I18n.t('business_license_information.title2')}</div>
              <div className='modal__body'>{I18n.t('business_license_information.desc2')}</div>
              <div className='modal__title'>{I18n.t('business_license_information.title3')}</div>
              <div className='modal__button-box'>
                <Button
                  id='business-license-information-no'
                  action
                  expanded
                  hollow
                  className='button-sbmt ghost'
                  onClick={this.toggleBusinessLicenceModal}
                >
                  {I18n.t('business_license_information.button_no')}
                </Button>

                <Button
                  id='business-license-information-yes'
                  expanded
                  hollow
                  className='button-sbmt ghost'
                  onClick={this.toggleBusinessLicenceModal}
                >
                  {I18n.t('business_license_information.button_yes')}
                </Button>
              </div>
            </div>
          </Modal>

          <div
            className={cx(
              'small-12',
              { 'large-6 large-offset-3': !question.humanDescription },
              { 'large-5 large-offset-2': !!question.humanDescription }
            )}
          >
            {question.image &&
              question.image.name && (
              <div className='page__title'>
                <img src={images[question.image.name]} />
              </div>
            )}
            <h3 className='page__title'>
              <span
                dangerouslySetInnerHTML={{
                  __html: this.humanTitleFirstPart(question.humanTitle)
                }}
              />

              {this.isGermanBusinessLicense() && (
                <a className='page__title-link' onClick={this.toggleBusinessLicenceModal}>
                  {this.humanTitleLastPart(question.humanTitle)}
                  <svg>
                    <use xlinkHref={`#${infoIcon.id}`} />
                  </svg>
                </a>
              )}
            </h3>
            {question.youtube &&
              question.youtube.id && (
              <div className='page__title'>
                <YoutubeVideo videoId={question.youtube.id} description={question.youtube.description} />
              </div>
            )}
            <div className='page__wrapper'>
              {question.type === 'select_field' &&
                !freeTextQuestion && (
                <AnswerList
                  answers={question.answers}
                  questionKey={question.key}
                  onSelectAnswer={this.onSelectAnswer}
                />
              )}
              {question.type === 'select_field' &&
                freeTextQuestion && <FreeTextAnswerList answers={question.answers} onContinue={this.onSelectAnswer} />}
              {question.type === 'text_field' && (
                <TextField
                  onContinue={this.onSelectAnswer}
                  onTextChange={this.props.onTextFieldAnswerChange}
                  textAnswer={question.textAnswer}
                />
              )}
            </div>
            {!!previousQuestion && (
              <button className='back-button' onClick={this.goToPreviousQuestion}>
                &lt; {I18n.t('back')}
              </button>
            )}
          </div>

          {!!question.humanDescription && (
            <div
              className='large-3 text-left page__description'
              dangerouslySetInnerHTML={{
                __html: question.humanDescription
              }}
            />
          )}
        </div>
      </Page>
    )
  }
}

Eligibility.propTypes = {
  router: PropTypes.shape({
    location: PropTypes.shape({
      query: PropTypes.shape({
        applicant_created: PropTypes.string,
        question: PropTypes.string
      })
    })
  }),
  question: PropTypes.shape({
    humanTitle: PropTypes.string,
    humanDescription: PropTypes.string,
    key: PropTypes.string,
    answers: PropTypes.arrayOf(
      PropTypes.shape({
        human: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        selected: PropTypes.bool
      })
    )
  }),
  country: PropTypes.string,
  apiGetQuestion: PropTypes.func.isRequired,
  apiSendAnswer: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  previousQuestion: PropTypes.string,
  nextQuestion: PropTypes.string,
  answeredQuestionsTotal: PropTypes.number,
  questionsTotal: PropTypes.number,
  onTextFieldAnswerChange: PropTypes.func.isRequired
}

Eligibility.defaultProps = {
  router: {
    location: {
      query: {
        applicant_created: '',
        question: ''
      }
    }
  },
  question: {
    answers: [],
    humanTitle: '',
    key: ''
  },
  isLoading: false,
  answeredQuestionsTotal: 0,
  questionsTotal: 1,
  previousQuestion: undefined,
  nextQuestion: undefined
}

export default Eligibility
