import React from 'react'
import PropTypes from 'prop-types'
import cardStart from '../../../svg/cards/start.svg'
import cardProfile from '../../../svg/cards/profile.svg'
import cardPayment from '../../../svg/cards/payment.svg'
import cardOffersTypes from '../../../svg/cards/offers_types.svg'
import cardOffersAccepting from '../../../svg/cards/offers_accepting.svg'
import cardRemember from '../../../svg/cards/remember.svg'
import cardWaryOf from '../../../svg/cards/wary_of.svg'
import cardEnd from '../../../svg/cards/end.svg'
import cardVertical from '../../../svg/cards/verticals.svg'
import performanceScore from '../../../svg/cards/performance_score.svg'
import registationFee from '../../../svg/cards/registration_fee.svg'

const images = {
  start: cardStart.id,
  profile: cardProfile.id,
  payment: cardPayment.id,
  'offers-types': cardOffersTypes.id,
  'offers-accepting': cardOffersAccepting.id,
  'performance-score': performanceScore.id,
  'registration-fee': registationFee.id,
  'welcome-call': cardWaryOf.id,
  'welcome-call-after-webinar': cardWaryOf.id,
  'welcome-call-after-registration-fee': cardWaryOf.id,
  webinar: cardWaryOf.id,
  remember: cardRemember.id,
  'wary-of': cardWaryOf.id,
  end: cardEnd.id,
  verticals: cardVertical.id
}

const CardImage = ({ card }) =>
  images[card] ? (
    <svg style={card === 'verticals' ? { maxWidth: '100%' } : {}}>
      <use xlinkHref={`#${images[card]}`} />
    </svg>
  ) : null

CardImage.propTypes = {
  card: PropTypes.string.isRequired
}

export default CardImage
