import React from 'react'
import PropTypes from 'prop-types'
import { InfoContainer, Button } from 'lasagna'
import CardImage from './image'

const End = ({ name, finishOnboarding }) => {
  const verticalsEnabled = name === 'verticals'

  return (
    <div className='row'>
      <div className='small-12 large-6 large-offset-3'>
        <InfoContainer
          title={I18n.t(`cards.${name}.title`)}
          footer={
            <Button
              action
              expanded
              rounded
              screaming
              onClick={() => {
                finishOnboarding(verticalsEnabled ? 'services' : 'notifications')
              }}
            >
              {I18n.t(`cards.${name}.cta`)}
            </Button>
          }
        >
          <CardImage card={name} />

          <div
            dangerouslySetInnerHTML={{
              __html: I18n.t(`cards.${name}.content_html`)
            }}
          />
        </InfoContainer>
      </div>
    </div>
  )
}

End.propTypes = {
  name: PropTypes.string.isRequired,
  finishOnboarding: PropTypes.func.isRequired
}

export default End
