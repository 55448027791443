import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { InfoContainer, Button } from 'lasagna'
import { WELCOME_CARD_START } from '../../constants/cards'
import Progress from './progress'
import CardImage from './image'

const Card = ({ params: { card, userId }, updateWelcomeCard, welcomeCards }) => {
  const currentCard = card || WELCOME_CARD_START
  const index = welcomeCards.indexOf(currentCard)
  const i18nBaseKey = currentCard.replace(/-/g, '_')

  return (
    <div className='row'>
      <div className='small-12 large-6 large-offset-3'>
        <InfoContainer
          title={I18n.t(`cards.${i18nBaseKey}.title`)}
          footer={
            <React.Fragment>
              {index > 0 && <Progress current={index} total={welcomeCards.length - 2} />}
              <Button
                action
                expanded
                rounded
                screaming
                onClick={() => {
                  updateWelcomeCard(welcomeCards[index + 1], userId)
                }}
              >
                {I18n.t(`cards.${i18nBaseKey}.cta`)}
              </Button>
              {index > 0 && (
                <Link to={`/become-a-cleaner/${userId}/cards/${welcomeCards[index - 1]}`}>
                  &lt; {I18n.t('cards.back')}
                </Link>
              )}
            </React.Fragment>
          }
        >
          {I18n.t(`cards.${i18nBaseKey}.intro_html`, {
            defaultValue: ''
          }) !== '' && (
            <div
              dangerouslySetInnerHTML={{
                __html: I18n.t(`cards.${i18nBaseKey}.intro_html`)
              }}
            />
          )}

          <CardImage card={currentCard} />

          <div
            dangerouslySetInnerHTML={{
              __html: I18n.t(`cards.${i18nBaseKey}.content_html`)
            }}
          />
        </InfoContainer>
      </div>
    </div>
  )
}

Card.propTypes = {
  updateWelcomeCard: PropTypes.func.isRequired,
  welcomeCards: PropTypes.array,
  params: PropTypes.shape({
    card: PropTypes.string
  })
}

export default Card
