import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { allDocumentsUploaded } from '../../utils/shared'
import Header from '../shared/header'
import DocumentBox from './document_box'
import Spinner from '../../shared/components/spinner'
import TrackingUtils from '../../shared/utils/tracking_utils'

class MyDocuments extends Component {
  async componentWillMount() {
    await this.props.apiFetchDocuments()

    TrackingUtils.trackEvent('autoTrack', {
      eventCategory: 'Provider Funnel',
      eventLabel: 'in complete page',
      eventAction: 'documents upload became visible'
    })
  }

  documentMap = d => (
    <DocumentBox
      key={d.key}
      documentKey={d.key}
      name={d.name}
      description={d.description}
      exampleImage={d.exampleImage}
      filename={d.filename}
      uploaded={d.uploaded}
      uploadFile={this.props.uploadFile}
      isIos={this.props.os === 'ios'}
      help={d.help}
      fileSizeValidation={this.props.fileSizeValidation}
      approved={d.approved}
      where={d.where}
      isMobileApp={this.props.isMobileApp}
      isMobile={this.props.isMobile}
    />
  )

  conditionalDescription = () =>
    allDocumentsUploaded(this.props.myDocuments.data)
      ? I18n.t('my_documents.description_uploaded')
      : I18n.t('my_documents.description_not_uploaded')

  renderDocumentsOrEmptyText = (documents, status) => (
    <div className='my-documents__section small-12'>
      <div className='my-documents__status'>{I18n.t(`my_documents.documents_list.${status}.title`)}</div>
      {!!documents.length && <div className='my-documents__boxes'>{documents.map(this.documentMap)}</div>}
      {!documents.length && (
        <div className='my-documents__desc'>{I18n.t(`my_documents.documents_list.${status}.empty`)}</div>
      )}
    </div>
  )

  renderDocuments = () => (
    <div>
      <div className='row'>
        {this.renderDocumentsOrEmptyText(
          this.props.myDocuments.data.filter(d => !d.uploaded && !d.approved),
          'not_submitted'
        )}
        {this.renderDocumentsOrEmptyText(this.props.myDocuments.data.filter(d => d.uploaded && !d.approved), 'pending')}
        {this.renderDocumentsOrEmptyText(this.props.myDocuments.data.filter(d => d.approved), 'approved')}
      </div>
    </div>
  )

  render() {
    const { myDocuments } = this.props

    return (
      <div className='my-documents'>
        <Header title={I18n.t('my_documents.title')} description={this.conditionalDescription()} />

        {myDocuments.isUploading ? <Spinner config={{ top: '20rem' }} /> : this.renderDocuments()}
      </div>
    )
  }
}

MyDocuments.propTypes = {
  myDocuments: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        key: PropTypes.string,
        name: PropTypes.string,
        uploaded: PropTypes.bool,
        help: PropTypes.string
      })
    ),
    isUploading: PropTypes.bool
  }),
  fileSizeValidation: PropTypes.bool,
  os: PropTypes.string,
  uploadFile: PropTypes.func.isRequired,
  isMobileApp: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  apiFetchDocuments: PropTypes.func.isRequired
}

MyDocuments.defaultProps = {
  myDocuments: {
    data: [],
    isUploading: false
  },
  os: ''
}

export default MyDocuments
