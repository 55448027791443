import React from 'react'
import Menu from '../../containers/menu'
import badgeIosDe from '../../../svg/apps/appstore-badge-de.svg'
import badgeIosEn from '../../../svg/apps/appstore-badge-en.svg'
import badgeIosFr from '../../../svg/apps/appstore-badge-fr.svg'
import badgeIosIt from '../../../svg/apps/appstore-badge-it.svg'
import badgeIosNl from '../../../svg/apps/appstore-badge-nl.svg'
import badgeGoogleDe from '../../../svg/apps/google-play-badge-de.svg'
import badgeGoogleEn from '../../../svg/apps/google-play-badge-en.svg'
import badgeGoogleFr from '../../../svg/apps/google-play-badge-fr.svg'
import badgeGoogleIt from '../../../svg/apps/google-play-badge-it.svg'
import badgeGoogleNl from '../../../svg/apps/google-play-badge-nl.svg'

const config = {
  de: {
    storePrefix: 'de',
    badgeIos: badgeIosDe,
    badgeGoogle: badgeGoogleDe
  },
  de_en: {
    storePrefix: 'de',
    badgeIos: badgeIosEn,
    badgeGoogle: badgeGoogleEn
  },
  nl: {
    storePrefix: 'nl',
    badgeIos: badgeIosNl,
    badgeGoogle: badgeGoogleNl
  },
  nl_en: {
    storePrefix: 'nl',
    badgeIos: badgeIosEn,
    badgeGoogle: badgeGoogleEn
  },
  en: {
    storePrefix: 'gb',
    badgeIos: badgeIosEn,
    badgeGoogle: badgeGoogleEn
  },
  uk: {
    storePrefix: 'gb',
    badgeIos: badgeIosEn,
    badgeGoogle: badgeGoogleEn
  },
  ie: {
    storePrefix: 'ie',
    badgeIos: badgeIosEn,
    badgeGoogle: badgeGoogleEn
  },
  it: {
    storePrefix: 'it',
    badgeIos: badgeIosIt,
    badgeGoogle: badgeGoogleIt
  },
  sg: {
    storePrefix: 'sg',
    badgeIos: badgeIosEn,
    badgeGoogle: badgeGoogleEn
  },
  fr: {
    storePrefix: 'fr',
    badgeIos: badgeIosFr,
    badgeGoogle: badgeGoogleFr
  }
}

const DownloadApp = () => (
  <div>
    <Menu languagesMenuEnabled />

    <div className='row'>
      <div className='small-12 large-6 large-offset-3 column descriptive-step'>
        <h2 className='text-center descriptive-step__title'>{I18n.t('download_app.title')}</h2>

        <div className='descriptive-step__title'>
          <p>{I18n.t('download_app.body_start')}</p>
          <p>
            <strong>{I18n.t('download_app.download_ios')}</strong>
            <br />
            <a
              href={`https://itunes.apple.com/${config[I18n.locale].storePrefix}/app/helpling-partner/id1152910034`}
              target='_blank'
              rel='noopener noreferrer'
            >
              <svg className='welcome__app welcome__app--ios'>
                <use xlinkHref={`#${config[I18n.locale].badgeIos.id}`} />
              </svg>
            </a>
          </p>

          <p>
            <strong>{I18n.t('download_app.download_android')}</strong>
            <br />
            <a
              href='https://play.google.com/store/apps/details?id=com.helpling.app.h2.provider'
              target='_blank'
              rel='noopener noreferrer'
            >
              <svg className='welcome__app welcome__app--google'>
                <use xlinkHref={`#${config[I18n.locale].badgeGoogle.id}`} />
              </svg>
            </a>
          </p>

          <p>{I18n.t('download_app.body_end')}</p>
        </div>
      </div>
    </div>
  </div>
)

export default DownloadApp
