import {
  GET_CALL_REQUEST,
  GET_CALL_REQUEST_SUCCESS,
  GET_CALL_REQUEST_FAIL,
  POST_CALL_REQUEST,
  POST_CALL_REQUEST_SUCCESS,
  POST_CALL_REQUEST_FAIL
} from '../constants/call_request'

export const initialState = {
  data: [],
  isLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CALL_REQUEST:
    case POST_CALL_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case GET_CALL_REQUEST_SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false
      }
    }
    case POST_CALL_REQUEST_SUCCESS: {
      return {
        ...state,
        data: [
          {
            ...action.payload.data.data
          }
        ],
        isLoading: false
      }
    }
    case GET_CALL_REQUEST_FAIL:
    case POST_CALL_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false
      }
    }
    default:
      return state
  }
}
