import gql from 'graphql-tag'
import moment from 'moment'
import client from './gql'

export const storeWorkingHours = hours => {
  const workingHours = hours.map((day, index) => ({
    day: index,
    openTime: moment(day.from, 'HH:mm').toJSON(),
    closeTime: moment(day.to, 'HH:mm').toJSON(),
    available: !!day.available
  }))

  const mutation = gql`
    mutation applicantStoreWorkingHours($workingHours: [WorkingHourInput]) {
      storeWorkingHours(workingHours: $workingHours) {
        day
        valid
      }
    }
  `

  return client.mutate({
    mutation,
    variables: {
      workingHours
    }
  })
}

export const updateBidDurationRange = (minBidDuration, maxBidDuration) => {
  const mutation = gql`
    mutation applicantUpdateBidDurationRange($minBidDuration: Int, $maxBidDuration: Int) {
      updateBidDurationRange(minBidDuration: $minBidDuration, maxBidDuration: $maxBidDuration) {
        minBidDuration
        maxBidDuration
        errors {
          minBidDuration
          maxBidDuration
        }
      }
    }
  `

  return client.mutate({ mutation, variables: { minBidDuration, maxBidDuration } })
}
