import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import AnswerList from '../shared/answer_list'
import Menu from '../../containers/menu'

class RightToWork extends Component {
  render() {
    const { isSaving, selected, saveRightToWork, routeParams: { userId } } = this.props

    return (
      <div className={cx('page', { 'page--loading': isSaving })}>
        <Menu languagesMenuEnabled />

        <div className='row'>
          <div className='small-12 large-6 large-offset-3 column'>
            <h3 className='page__title'>
              <span>{I18n.t('right_to_work.title')}</span>
            </h3>

            <div className='page__wrapper'>
              <AnswerList
                answers={[
                  {
                    human: I18n.t('right_to_work.answer_yes'),
                    value: 'right-to-work-yes',
                    selected: selected === 'right-to-work-yes'
                  },
                  {
                    human: I18n.t('right_to_work.answer_no'),
                    value: 'right-to-work-no',
                    selected: selected === 'right-to-work-no'
                  }
                ]}
                onSelectAnswer={answer => saveRightToWork(answer, userId)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RightToWork.propTypes = {
  isSaving: PropTypes.bool,
  selected: PropTypes.string,
  saveRightToWork: PropTypes.func.isRequired,
  routeParams: PropTypes.shape({
    userId: PropTypes.string.isRequired
  })
}

export default RightToWork
