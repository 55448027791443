import PropTypes from 'prop-types'
import React from 'react'
import Header from '../shared/header'

const PageWithLink = ({ title, description, link, cta }) => (
  <div className='text-center page-with-link'>
    <Header title={title} description={description} />
    {!!link &&
      !!cta && (
      <div className='align-center'>
        <div className='large-5 small-12'>
          <a href={link} className='button btn medium expanded button-sbmt action'>
            {cta}
          </a>
        </div>
      </div>
    )}
  </div>
)

PageWithLink.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  cta: PropTypes.string
}

PageWithLink.defaultProps = {
  link: '',
  cta: ''
}

export default PageWithLink
