import gql from 'graphql-tag'
import client from './gql'

export const experienceQuestion = () => {
  const query = gql`
    query applicantFetchExperienceQuestion {
      experienceQuestion {
        humanTitle
        key
        type
        answers {
          human
          value
        }
      }
      welcomeCards
    }
  `

  return client.query({ query })
}

export const storeExperienceQuestion = value => {
  const mutation = gql`
    mutation applicantSaveExperienceQuestion($value: Int!) {
      storeExperienceQuestion(value: $value) {
        success
      }
    }
  `

  return client.mutate({ mutation, variables: { value } })
}

export const storeExperienceText = (experienceHeadline, experienceDescription) => {
  const mutation = gql`
    mutation applicantStoreExperienceText($experienceHeadline: String!, $experienceDescription: String!) {
      storeExperience(experienceHeadline: $experienceHeadline, experienceDescription: $experienceDescription) {
        success
      }
    }
  `

  return client.mutate({
    mutation,
    variables: { experienceHeadline, experienceDescription }
  })
}
