export const GET_CONTACTS_SETTINGS = 'GET_CONTACTS_SETTINGS'
export const GET_CONTACTS_SETTINGS_SUCCESS = 'GET_CONTACTS_SETTINGS_SUCCESS'
export const GET_CONTACTS_SETTINGS_FAIL = 'GET_CONTACTS_SETTINGS_FAIL'

export const SUBMIT_CONTACTS_FORM = 'SUBMIT_CONTACTS_FORM'
export const SUBMIT_CONTACTS_FORM_SUCCESS = 'SUBMIT_CONTACTS_FORM_SUCCESS'
export const SUBMIT_CONTACTS_FORM_FAIL = 'SUBMIT_CONTACTS_FORM_FAIL'

export const CLOSE_CONTACTS_ERROR_BOX = 'CLOSE_CONTACTS_ERROR_BOX'
export const OPEN_CONTACTS_ERROR_BOX = 'OPEN_CONTACTS_ERROR_BOX'

export const UPDATE_CONTACTS_FORM_DATA = 'UPDATE_CONTACTS_FORM_DATA'
