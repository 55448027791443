export const GET_PERSONAL_DETAILS_SETTINGS = 'GET_PERSONAL_DETAILS_SETTINGS'
export const GET_PERSONAL_DETAILS_SETTINGS_SUCCESS = 'GET_PERSONAL_DETAILS_SETTINGS_SUCCESS'
export const GET_PERSONAL_DETAILS_SETTINGS_FAIL = 'GET_PERSONAL_DETAILS_SETTINGS_FAIL'

export const SUBMIT_FORM = 'SUBMIT_FORM'
export const SUBMIT_FORM_SUCCESS = 'SUBMIT_FORM_SUCCESS'
export const SUBMIT_FORM_FAIL = 'SUBMIT_FORM_FAIL'

export const CLOSE_ERROR_BOX = 'CLOSE_ERROR_BOX'
export const OPEN_ERROR_BOX = 'OPEN_ERROR_BOX'

export const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA'
export const SUCCESS_REDIRECT = 'SUCCESS_REDIRECT'

export const FIELD_PASSWORD = 'password'
export const FIELD_GENDER = 'gender'
export const FIELD_DOB_DAY = 'dobDay'
export const FIELD_DOB_MONTH = 'dobMonth'
export const FIELD_DOB_YEAR = 'dobYear'
export const FIELD_DOB = 'dob'
export const FIELD_NATIONALITY = 'nationality'
export const FIELD_POSTCODE = 'postcode'
export const FIELD_ADDRESS1 = 'address1'
export const FIELD_ADDRESS2 = 'address2'
export const FIELD_TOWN = 'town'
