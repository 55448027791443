import PropTypes from 'prop-types'
import React, { Component } from 'react'
import logo from '../../../svg/logo.svg'
import LanguagesMenu from './languages_menu'
import { mainAppUrl } from '../../utils/urls'

class Menu extends Component {
  state = {
    languagesMenuOpen: false
  }

  toggleLanguagesMenu = () => {
    this.setState({ languagesMenuOpen: !this.state.languagesMenuOpen })
  }

  renderLanguagesLink = () => {
    const { languages, currentLanguage, languagesMenuEnabled } = this.props

    if (!languagesMenuEnabled || Object.keys(languages).length < 2) {
      return null
    }
    return (
      <button
        className='hero__nav--menu column shrink menu__item menu__item--lang'
        onClick={this.toggleLanguagesMenu}
        disabled={this.state.languagesMenuOpen}
      >
        <span className='menu__label'>{currentLanguage.slice(-2)}</span>
        <span className='menu__arrow-down' />
      </button>
    )
  }

  renderLanguagesList = () => {
    const { languages, currentLanguage, languagesMenuEnabled, setPreferredLanguage } = this.props

    if (!languagesMenuEnabled || Object.keys(languages).length < 2 || !this.state.languagesMenuOpen) {
      return null
    }
    return (
      <LanguagesMenu
        languages={languages}
        currentLanguage={currentLanguage}
        setPreferredLanguage={setPreferredLanguage}
        onClose={() => this.setState({ languagesMenuOpen: false })}
      />
    )
  }

  render() {
    const { logOutApplicant, isMobile, withoutUser, invalidating } = this.props

    return (
      <div>
        <div className='menu'>
          <div className='menu__container row'>
            <svg className='menu__logo'>
              <use xlinkHref={`#${logo.id}`} />
            </svg>
            <nav className='menu__nav'>
              {this.renderLanguagesLink()}

              <button
                disabled={invalidating}
                style={{
                  visibility: withoutUser ? 'hidden' : 'visible'
                }}
                className='hero__nav--menu column shrink menu__item menu__item--main'
                onClick={() => logOutApplicant(isMobile ? `//${mainAppUrl}/mobile/provider/login` : `//${mainAppUrl}/`)}
              >
                <span className='menu__label'>{I18n.t('logout')}</span>
              </button>
            </nav>
          </div>
        </div>

        {this.renderLanguagesList()}
      </div>
    )
  }
}

Menu.propTypes = {
  logOutApplicant: PropTypes.func.isRequired,
  languages: PropTypes.object,
  currentLanguage: PropTypes.string,
  languagesMenuEnabled: PropTypes.bool,
  withoutUser: PropTypes.bool,
  isMobile: PropTypes.bool,
  setPreferredLanguage: PropTypes.func.isRequired,
  invalidating: PropTypes.bool.isRequired
}

export default Menu
