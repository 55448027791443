import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Input } from 'lasagna'
import cx from 'classnames'
import NotificationBox from '../shared/notification_box'
import {
  FIELD_PASSWORD,
  FIELD_GENDER,
  FIELD_DOB_DAY,
  FIELD_DOB_MONTH,
  FIELD_DOB_YEAR,
  FIELD_NATIONALITY,
  FIELD_POSTCODE,
  FIELD_ADDRESS1,
  FIELD_ADDRESS2,
  FIELD_TOWN
} from '../../constants/personal_details'
import Spinner from '../../shared/components/spinner'
import Menu from '../../containers/menu'
import PostcodeSelector from './postcode_selector'

class PersonalDetails extends Component {
  state = {
    timeoutEventHandler: null
  }

  componentDidMount() {
    this.props.getSettings()
    window.addEventListener('scroll', this.handleScrollEvent)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollEvent)
  }

  TIMEOUT_DELAY = 3000

  dateDays = () => [...Array(31)].map((_, i) => i + 1).map(n => ({ value: n.toString(), text: n }))

  dateMonths = () => I18n.t('personal_details.month_names').map((m, i) => ({ value: (i + 1).toString(), text: m }))

  dateYears = (first, last) =>
    [...Array(last - first)].map((_, i) => i + 1 + first).map(n => ({ value: n.toString(), text: n }))

  handleSubmit = event => {
    event.preventDefault()
    this.props.sendForm().catch(() => {
      const timeoutId = setTimeout(this.props.closeErrorBox, this.TIMEOUT_DELAY)
      this.setState({ timeoutEventHandler: timeoutId })
    })
  }

  handleCloseNotificationBox = () => {
    clearTimeout(this.state.timeoutEventHandler)
    this.props.closeErrorBox()
  }

  handleScrollEvent = () => this.props.isErrorBoxVisible && this.handleCloseNotificationBox()

  dobValidationError() {
    const { user } = this.props
    if (!user[FIELD_DOB_DAY] || !user[FIELD_DOB_MONTH] || !user[FIELD_DOB_YEAR]) {
      return I18n.t('personal_details.errors.date_of_birth.empty')
    }
    return I18n.t('personal_details.errors.date_of_birth.adult')
  }

  passwordErrorLabel = errors => {
    if (!errors.password) {
      return ''
    }
    return this.props.localSettings.onlyStrongPasswordAllowed
      ? I18n.t('personal_details.errors.password_strong')
      : I18n.t('personal_details.errors.password')
  }

  renderForm() {
    const { errors, localSettings: { dateOfBirth, nationalities, allowedPostcodes }, user, updateFormData } = this.props

    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className='row'>
          <div className='small-12 columns'>
            <Input
              name={FIELD_GENDER}
              type='select'
              label={I18n.t('personal_details.label.gender')}
              error={errors.gender ? I18n.t('personal_details.errors.gender') : ''}
              value={user[FIELD_GENDER]}
              onChange={updateFormData}
              options={[
                { value: 'male', text: I18n.t('personal_details.gender.male') },
                { value: 'female', text: I18n.t('personal_details.gender.female') }
              ]}
            />
          </div>
        </div>

        <div
          className={cx('row collapse personal-details__group', {
            'personal-details__group--invalid': errors.dob
          })}
        >
          <div className='small-12 columns'>
            <span>{errors.dob ? this.dobValidationError() : I18n.t('personal_details.label.date_of_birth')}</span>
          </div>

          <div className='small-4 columns'>
            <Input
              name={FIELD_DOB_DAY}
              type='select'
              noBorder
              label={I18n.t('personal_details.label.day')}
              error={errors.dob && !user[FIELD_DOB_DAY] ? I18n.t('personal_details.label.day') : ''}
              value={user[FIELD_DOB_DAY]}
              onChange={updateFormData}
              options={this.dateDays()}
            />
          </div>

          <div className='small-4 columns'>
            <Input
              name={FIELD_DOB_MONTH}
              type='select'
              noBorder
              label={I18n.t('personal_details.label.month')}
              error={errors.dob && !user[FIELD_DOB_MONTH] ? I18n.t('personal_details.label.month') : ''}
              value={user[FIELD_DOB_MONTH]}
              onChange={updateFormData}
              options={this.dateMonths()}
            />
          </div>

          <div className='small-4 columns'>
            <Input
              name={FIELD_DOB_YEAR}
              type='select'
              noBorder
              label={I18n.t('personal_details.label.year')}
              error={errors.dob && !user[FIELD_DOB_YEAR] ? I18n.t('personal_details.label.year') : ''}
              value={user[FIELD_DOB_YEAR]}
              onChange={updateFormData}
              options={this.dateYears(dateOfBirth.first, dateOfBirth.last)}
            />
          </div>
        </div>

        <div className='row'>
          <div className='small-12 columns'>
            <Input
              name={FIELD_NATIONALITY}
              type='select'
              label={I18n.t('personal_details.label.nationality')}
              error={errors.nationality ? I18n.t('personal_details.errors.nationality') : ''}
              value={user[FIELD_NATIONALITY]}
              onChange={updateFormData}
              options={nationalities.map(n => ({ value: n[1], text: n[0] }))}
            />
          </div>
        </div>

        <div className='row'>
          <div className='small-12 columns'>
            <Input
              name={FIELD_ADDRESS1}
              label={I18n.t('personal_details.label.address1')}
              error={errors.address1 ? I18n.t('personal_details.errors.address1') : ''}
              value={user[FIELD_ADDRESS1]}
              onChange={updateFormData}
            />
          </div>
        </div>

        <div className='row'>
          <div className='small-12 columns'>
            <Input
              name={FIELD_ADDRESS2}
              label={I18n.t('personal_details.label.address2')}
              value={user[FIELD_ADDRESS2]}
              onChange={updateFormData}
            />
          </div>
        </div>

        <div className='row'>
          <div className='small-12 columns'>
            <Input
              name={FIELD_TOWN}
              label={I18n.t('personal_details.label.town')}
              error={errors.town ? I18n.t('personal_details.errors.town') : ''}
              value={user[FIELD_TOWN]}
              onChange={updateFormData}
            />
          </div>
        </div>

        <div className='row'>
          <div className='small-12 columns'>
            <PostcodeSelector
              error={errors.postcode}
              active={user[FIELD_POSTCODE] !== ''}
              onChange={updateFormData}
              value={user[FIELD_POSTCODE]}
              allowedPostcodes={allowedPostcodes}
            />
          </div>
        </div>

        <div className='row'>
          <div className='small-12 columns'>
            <Input
              name={FIELD_PASSWORD}
              type='password'
              label={I18n.t('personal_details.label.password')}
              error={this.passwordErrorLabel(errors)}
              value={user[FIELD_PASSWORD]}
              onChange={updateFormData}
            />
          </div>
          <div className='small-12 columns'>
            <small className='personal-details__hint-box'>
              <p
                dangerouslySetInnerHTML={{
                  __html: I18n.t('personal_details.hint')
                }}
              />
            </small>
          </div>
        </div>

        <div className='simple-box'>
          <Button action expanded rounded screaming>
            {I18n.t('personal_details.submit_button')}
          </Button>
        </div>
      </form>
    )
  }

  render() {
    const { isErrorBoxVisible, isLoading, localSettings } = this.props

    return (
      <div
        className={cx('personal-details', {
          'personal-details--loading': isLoading
        })}
      >
        <NotificationBox
          errorState
          message={I18n.t('personal_details.errors.dialog_box')}
          open={isErrorBoxVisible}
          onClose={this.handleCloseNotificationBox}
        />

        <Menu languagesMenuEnabled />

        <div className='row'>
          <div className='small-12 large-offset-3 large-6'>
            <header className='personal-details__header'>
              <h2 className='personal-details__title'>{I18n.t('personal_details.title')}</h2>
            </header>

            {!localSettings && <Spinner config={{ top: '250px' }} />}
            {localSettings && this.renderForm()}
          </div>
        </div>
      </div>
    )
  }
}

PersonalDetails.propTypes = {
  sendForm: PropTypes.func.isRequired,
  closeErrorBox: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    password: PropTypes.string,
    gender: PropTypes.string,
    dob: PropTypes.string,
    nationality: PropTypes.string,
    postcode: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    town: PropTypes.string
  }),
  user: PropTypes.shape({
    password: PropTypes.string,
    gender: PropTypes.string,
    dob: PropTypes.string,
    nationality: PropTypes.string,
    postcode: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    town: PropTypes.string
  }),
  isLoading: PropTypes.bool,
  isErrorBoxVisible: PropTypes.bool,
  updateFormData: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
  localSettings: PropTypes.object
}

PersonalDetails.defaultProps = {
  errors: {
    password: '',
    gender: '',
    dob: '',
    nationality: '',
    postcode: '',
    address1: '',
    address2: '',
    town: ''
  },
  isErrorBoxVisible: false,
  isLoading: false,
  localSettings: null
}

export default PersonalDetails
