import PropTypes from 'prop-types'
import React from 'react'

const ProgressBar = ({ progress, modifierClass }) => (
  <div className={`progress-bar ${modifierClass}`}>
    <div className='progress-bar__indicator'>
      <span style={{ width: `${progress}%` }} />
    </div>
    <div className='progress-bar__percentage'>{progress}%</div>
  </div>
)

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  modifierClass: PropTypes.string
}

ProgressBar.defaultProps = {
  modifierClass: ''
}

export default ProgressBar
