import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ title, description }) => (
  <header className='provider-header row'>
    <div className='small-12 large-offset-2 large-8 columns'>
      <h2 className='provider-header__title'>{title}</h2>
      <h3 className='provider-header__description' dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  </header>
)

Header.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
}

Header.defaultProps = {
  title: '',
  description: ''
}

export default Header
