import { push } from 'react-router-redux'
import {
  HANDLE_VERTICAL_CHANGE,
  HANDLE_COMMENT_CHANGE,
  SET_VERTICALS_REQUEST,
  SET_VERTICALS_FAILURE,
  SET_VERTICALS_SUCCESS
} from '../../constants/post_onfido/verticals'
import { updateUserVerticals } from '../../api'

export const saveVerticals = (verticals, comment, userId) => dispatch => {
  dispatch({ type: SET_VERTICALS_REQUEST })
  const collected = Object.keys(verticals)
    .map(group => verticals[group])
    .reduce((acc, cur) => Object.assign(acc, cur), {})

  return updateUserVerticals(collected, comment)
    .then(res => {
      dispatch({ type: SET_VERTICALS_SUCCESS, res })
      dispatch(push(`/become-a-cleaner/${userId}/availability`))
    })
    .catch(err => {
      dispatch({ type: SET_VERTICALS_FAILURE, err })
    })
}

export const handleVerticalChange = (group, item) => ({
  type: HANDLE_VERTICAL_CHANGE,
  group,
  item
})
export const handleCommentChange = value => ({
  type: HANDLE_COMMENT_CHANGE,
  value
})
