import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Complete from '../components/complete'
import { fetchProgress, setCurrentPage } from '../actions/complete'
import { sendCallRequest } from '../actions/call_request'

const mapStateToProps = state => ({
  isLoading: state.complete.isLoading,
  application: state.complete.progress,
  currentPage: state.complete.currentPage,
  callPreferencesSet: state.complete.callPreferencesSet,
  introductionVideoId: state.complete.introductionVideoId,
  externalDocumentsPage: state.complete.externalDocumentsPage,
  disabledReason: state.complete.disabledReason,
  score: state.complete.score,
  directPhoneNumber: state.settings.directPhoneNumber,
  country: state.settings.country.locale
})
const mapDispatchToProps = dispatch => ({
  apiFetchCompleteProgress: () => dispatch(fetchProgress()),
  goToUrl: url => dispatch(push(url)),
  setCurrentPage: page => dispatch(setCurrentPage(page)),
  sendCallRequest: (requestType, requestReason, slot) => dispatch(sendCallRequest(requestType, requestReason, slot))
})

export default connect(mapStateToProps, mapDispatchToProps)(Complete)
