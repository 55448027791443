import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Onfido from 'onfido-sdk-ui'
import { de } from './locales/de'
import { nl } from './locales/nl'
import { it } from './locales/it'

let checkCreated = false

class OnfidoSdk extends Component {
  componentDidMount() {
    const { token, onDocumentUploadCompleted, country, nationality } = this.props
    const locales = { de, nl, it }
    const countryName = country === 'UK' ? 'United Kingdom' : country

    const config = {
      token,
      onComplete: () => {
        if (!checkCreated) {
          onDocumentUploadCompleted()
          checkCreated = true
        }
      },
      steps: [
        {
          type: 'welcome',
          options: {
            title: I18n.t('welcome.onfido.pre_upload.title'),
            descriptions: [I18n.t('welcome.onfido.pre_upload.desc1'), I18n.t('welcome.onfido.pre_upload.desc2')],
            nextButton: I18n.t('welcome.onfido.pre_upload.next')
          }
        },
        {
          type: 'document',
          options: {
            documentTypes: {
              passport: true,
              national_identity_card: true,
              driving_licence: countryName === 'United Kingdom' && nationality === 'United Kingdom'
            }
          }
        },
        'complete'
      ]
    }

    if (Object.keys(locales).indexOf(I18n.locale) > -1) {
      config.language = { phrases: locales[I18n.locale] }
    }
    this.onfido = Onfido.init(config)
  }

  componentWillUnmount() {
    if (this.onfido && typeof this.onfido.tearDown === 'function') {
      this.onfido.tearDown()
    }
  }

  render() {
    return <div id='onfido-mount' />
  }
}

OnfidoSdk.propTypes = {
  country: PropTypes.string.isRequired,
  nationality: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onDocumentUploadCompleted: PropTypes.func.isRequired
}

export default OnfidoSdk
