import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'
import ballonSadIcon from '../../../svg/balloon_sad.svg'

const NotificationBox = ({ boldPrefix, bottom, errorState, onClose, message, open }) => (
  <div
    className={cx(
      'notification-box',
      { 'notification-box--bottom': bottom },
      { 'notification-box--error': errorState },
      { 'notification-box--visible': open }
    )}
  >
    <div className='row'>
      <div className='small-12 large-offset-3 large-6 notification-box__container'>
        {errorState && (
          <svg className='notification-box__icon'>
            <use xlinkHref={`#${ballonSadIcon.id}`} />
          </svg>
        )}
        {boldPrefix && <strong>{boldPrefix} </strong>}
        {message}
        <button onClick={onClose} className='notification-box__close'>
          ×
        </button>
      </div>
    </div>
  </div>
)

NotificationBox.propTypes = {
  boldPrefix: PropTypes.string,
  bottom: PropTypes.bool,
  errorState: PropTypes.bool,
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

NotificationBox.defaultProps = {
  boldPrefix: '',
  message: '',
  open: false
}

export default NotificationBox
