import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import I18n from 'i18n-js'
import * as Sentry from '@sentry/browser'
import { TranslationsProvider, Retry } from 'lasagna'
import Spinner from './src/components/shared/spinner'
import { LOCALE_EN } from './src/constants/settings'
import configureStore from './store'
import Application from './index'

const { NODE_ENV } = process.env
if (NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://7984b3c95fbb4117b802ce0279a06129@sentry.io/1240535' })
}

global.I18n = I18n
I18n.defaultLocale = LOCALE_EN
I18n.translations = {}
I18n.fallbacks = true

const container = document.getElementById('applicant_application')
const store = configureStore()
const history = syncHistoryWithStore(browserHistory, store)

const render = Component => {
  ReactDOM.render(
    <TranslationsProvider
      locales={window.locales}
      globalLocalesPath={process.env.H2_LOCALES_PATH}
      loadingComponent={<Spinner config={{ top: '250px' }} />}
      retryComponent={<Retry />}
      appName='h2-applicant'
      noPrefix
    >
      <AppContainer>
        <Component store={store} history={history} />
      </AppContainer>
    </TranslationsProvider>,
    container
  )
}

render(Application)

if (module.hot) {
  module.hot.accept('./index', () => {
    render(Application)
  })
}
