import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Page from '../shared/page'
import VerificationCodeInput from './verification_code_input'
import ChangeMobileModal from './change_mobile_modal'
import styles from './index.scss'

class MobileVerification extends React.Component {
  componentDidMount() {
    this.props.getApplicantMobile(this.props.routeParams.userId)
  }

  render() {
    const {
      loading,
      mobile,
      invalid,
      resent,
      resendError,

      onOpenEdit,
      onCloseEdit,

      modal,
      onModalMobileChange,
      setApplicantMobile,

      code,
      onCodeChange,

      onResend,

      allowSubmit,
      onSubmit
    } = this.props
    return (
      <Page isLoading={loading} progress={0}>
        <div className={cx('small-12 large-6 large-offset-3', styles.container)}>
          <ChangeMobileModal
            {...modal}
            onChange={onModalMobileChange}
            onClose={onCloseEdit}
            onSubmit={setApplicantMobile}
          />

          <div className={styles.mobile}>
            <div className={styles.number}>{mobile}</div>
            <div className={styles.open_modal}>
              {I18n.t('mobile_verification.wrong_number')}{' '}
              <button type='button' className='btn-link' onClick={onOpenEdit}>
                {I18n.t('mobile_verification.edit')}
              </button>
            </div>
          </div>

          <div className={styles.label}>{I18n.t('mobile_verification.description')}</div>

          <VerificationCodeInput
            onChange={onCodeChange}
            code={code}
            onSubmit={() => onSubmit(code)}
            enabled={allowSubmit}
            invalid={invalid}
          />

          {!resent && (
            <div className={styles.resend}>
              <div>{I18n.t('mobile_verification.resend_label')}</div>
              <button className='btn-link' onClick={onResend}>
                {I18n.t('mobile_verification.resend_action')}
              </button>

              {resendError && <div className={styles.resend_error}>{I18n.t('mobile_verification.try_later')}</div>}
            </div>
          )}
        </div>
      </Page>
    )
  }
}

MobileVerification.propTypes = {
  getApplicantMobile: PropTypes.func.isRequired,
  setApplicantMobile: PropTypes.func.isRequired,

  loading: PropTypes.bool.isRequired,
  mobile: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  resent: PropTypes.bool.isRequired,
  resendError: PropTypes.bool.isRequired,

  onOpenEdit: PropTypes.func.isRequired,
  onCloseEdit: PropTypes.func.isRequired,

  modal: PropTypes.shape({
    visible: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired,
    error: PropTypes.string,
    mobile: PropTypes.string
  }),

  onModalMobileChange: PropTypes.func.isRequired,

  code: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCodeChange: PropTypes.func.isRequired,

  onResend: PropTypes.func.isRequired,

  allowSubmit: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,

  routeParams: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }).isRequired
}

export default MobileVerification
