class TrackingUtils {}

TrackingUtils.trackScreen = name => {
  if (!window.dataLayer) return

  dataLayer.push({
    event: 'screenView',
    screenName: name
  })
}

/**
 * Track an event on GTM
 * @param  {string} eventName name of the event
 * @param  {object} params    addtional parameters, something like { param1: 'abc', param2: 'def' }
 */
TrackingUtils.trackEvent = (eventName, params = {}) => {
  if (!window.dataLayer) return

  dataLayer.push({
    ...params,
    event: eventName
  })
}

/**
 * Push something to gtm if defined
 * @param  {object} params    some parameters, something like { param1: 'abc', param2: 'def' }
 */
TrackingUtils.track = (params = {}) => {
  if (!window.dataLayer) return

  dataLayer.push(params)
}

TrackingUtils.autoTrack = (eventAction, eventLabel = null) => {
  if (!window.dataLayer) return

  dataLayer.push({
    event: 'autoTrack',
    eventCategory: 'Provider App',
    eventLabel: eventLabel || `on page: ${document.location.href}`,
    eventAction
  })
}

TrackingUtils.trackApplicant = (params = {}) => {
  if (!window.dataLayer) return

  dataLayer.push({
    userAccountType: 'applicant',
    daysToJob: null,
    futureEventsCount: 0,
    pastEventsCount: 0,
    userBookingType: null,
    userWorkStatus: null,
    ...params
  })
}

export default TrackingUtils
