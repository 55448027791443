import { push } from 'react-router-redux'
import {
  SUBMIT_CONTACTS_FORM,
  SUBMIT_CONTACTS_FORM_SUCCESS,
  SUBMIT_CONTACTS_FORM_FAIL,
  UPDATE_CONTACTS_FORM_DATA,
  GET_CONTACTS_SETTINGS,
  GET_CONTACTS_SETTINGS_SUCCESS,
  GET_CONTACTS_SETTINGS_FAIL
} from '../constants/contact_details'
import TrackingUtils from '../shared/utils/tracking_utils'

export const submitForm = ({ email, firstname, lastname, mobile, postcode }) => ({
  types: [SUBMIT_CONTACTS_FORM, SUBMIT_CONTACTS_FORM_SUCCESS, SUBMIT_CONTACTS_FORM_FAIL],
  payload: {
    request: {
      method: 'post',
      url: 'applicant/applications',
      data: {
        email,
        firstname,
        lastname,
        mobile,
        postcode
      }
    }
  }
})

export const updateFormData = (key, val) => ({
  type: UPDATE_CONTACTS_FORM_DATA,
  payload: { key, val }
})

export const sendForm = user => (dispatch, getState) =>
  dispatch(submitForm(user)).then(res => {
    if (res.type === SUBMIT_CONTACTS_FORM_SUCCESS) {
      const { currentUser, applicantID, redirectUrl } = res.payload.data.data
      const { os, isMobile, name } = getState().settings

      TrackingUtils.trackEvent('applicationCreated', { applicantID })
      TrackingUtils.trackApplicant({
        mobileOs: os,
        mobileDevice: isMobile,
        country: name,
        ...currentUser
      })

      dispatch(push(redirectUrl))
    }
  })

export const getSettings = postcode => ({
  types: [GET_CONTACTS_SETTINGS, GET_CONTACTS_SETTINGS_SUCCESS, GET_CONTACTS_SETTINGS_FAIL],
  payload: {
    request: {
      method: 'get',
      url: 'applicant/contact_details/settings',
      params: { postcode }
    }
  }
})
