import React from 'react'

export default () => (
  <footer>
    <div className='row'>
      <div className='large-12 small-12'>
        {I18n.t('footer.all_rights_reserved')} <a href={I18n.t('footer.imprint_link')}>{I18n.t('footer.imprint')}</a>
        <br />
        &copy;{new Date().getFullYear()} Helpling
      </div>
    </div>
  </footer>
)
