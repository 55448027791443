import { INVALIDATE_APPLICANT, INVALIDATE_APPLICANT_SUCCESS, INVALIDATE_APPLICANT_FAIL } from '../constants/applicant'

export const invalidateApplicant = () => ({
  types: [INVALIDATE_APPLICANT, INVALIDATE_APPLICANT_SUCCESS, INVALIDATE_APPLICANT_FAIL],
  payload: {
    request: {
      method: 'delete',
      url: 'users/sign_out'
    }
  }
})

export const logOutApplicant = redirectUrl => dispatch =>
  dispatch(invalidateApplicant()).then(() => window.location.assign(redirectUrl))
