import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './verification_code_input.scss'

export default class VerificationCodeInput extends React.Component {
  static propTypes = {
    code: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)
    this.inputRefs = []
  }

  onChange(index, value) {
    const code = this.props.code.slice()
    if (value === '') {
      code[index] = ''
    } else {
      const number = Number(value.slice(-1))
      code[index] = Number.isNaN(number) ? '' : `${number}`
    }

    this.props.onChange(code)

    if (index < 4 && code[index] !== '') {
      this.inputRefs[index + 1].focus()
    }
  }

  setInputReference(index, ref) {
    this.inputRefs[index] = ref
  }

  render() {
    const { code, onSubmit, enabled, invalid } = this.props

    return (
      <React.Fragment>
        <div className={cx(styles.code, { [styles.code_error]: invalid })}>
          {code.map((value, index) => (
            <input
              key={index}
              type='text'
              pattern='[0-9]*'
              inputMode='numeric'
              className={styles.number}
              value={code[index]}
              onChange={e => this.onChange(index, e.target.value)}
              onClick={() => this.onChange(index, '')}
              ref={ref => this.setInputReference(index, ref)}
              disabled={!enabled}
            />
          ))}
        </div>

        <button
          type='button'
          className='button action expanded rounded screaming'
          onClick={onSubmit}
          disabled={!enabled}
          ref={ref => this.setInputReference(4, ref)}
        >
          {I18n.t('mobile_verification.next')}
        </button>
      </React.Fragment>
    )
  }
}
