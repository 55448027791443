import PropTypes from 'prop-types'
import React from 'react'
import humps from 'humps'
import cx from 'classnames'

const VerticalsItem = ({ handleVerticalChange, group, item, value }) => (
  <p>
    <button
      key={item}
      className={cx('services__item', {
        'services__item--active': value
      })}
      onClick={() => handleVerticalChange(group, item)}
    >
      {I18n.t(`verticals.items.${humps.decamelize(item)}`)}
    </button>
  </p>
)

VerticalsItem.propTypes = {
  handleVerticalChange: PropTypes.func.isRequired,
  group: PropTypes.string.isRequired,
  item: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired
}

export default VerticalsItem
