import { push } from 'react-router-redux'
import {
  HANDLE_SERVICE_CHANGE,
  SET_SERVICES_REQUEST,
  SET_SERVICES_FAILURE,
  SET_SERVICES_SUCCESS
} from '../../constants/post_onfido/services'
import { updateUserProfileAbilities } from '../../api'

export const saveServices = (selectedServices, userId) => dispatch => {
  dispatch({ type: SET_SERVICES_REQUEST })

  return updateUserProfileAbilities(selectedServices)
    .then(res => {
      dispatch({ type: SET_SERVICES_SUCCESS, res })
      dispatch(push(`/become-a-cleaner/${userId}/verticals`))
    })
    .catch(err => {
      dispatch({ type: SET_SERVICES_FAILURE, err })
    })
}

export const handleServiceChange = service => ({
  type: HANDLE_SERVICE_CHANGE,
  service
})
