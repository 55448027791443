import PropTypes from 'prop-types'
import React from 'react'
import Spinjs from 'spin.js'

export default class Spinner extends React.Component {
  static propTypes = {
    config: PropTypes.object,
    color: PropTypes.string
  }

  static defaultProps = {
    config: {},
    color: '#6B6B6B'
  }

  componentDidMount() {
    const { color, config } = this.props

    const spinConfig = {
      width: 2,
      radius: 6.5,
      length: 6,
      color,
      ...config
    }

    this.spinner = new Spinjs(spinConfig)
    this.spinner.spin(this.refs.spinner) // eslint-disable-line react/no-string-refs
  }

  componentWillUnmount() {
    this.spinner.stop()
  }

  render() {
    return <span ref='spinner' /> // eslint-disable-line react/no-string-refs
  }
}
