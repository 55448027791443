import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Answer from './answer'

class AnswerList extends Component {
  answerList = () =>
    this.props.answers.map(a => (
      <Answer
        answerText={a.human}
        key={a.value}
        onSelectAnswer={this.props.onSelectAnswer}
        isSelected={a.selected}
        value={a.value}
      />
    ))

  render() {
    return <div className='answer-list'>{this.answerList()}</div>
  }
}

AnswerList.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      human: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      selected: PropTypes.bool
    })
  ).isRequired,
  onSelectAnswer: PropTypes.func.isRequired
}

AnswerList.defaultProps = {
  answers: [],
  onSelectAnswer: () => {}
}

export default AnswerList
