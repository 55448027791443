export const FETCH_COMPLETE_PROGRESS = 'FETCH_COMPLETE_PROGRESS'
export const FETCH_COMPLETE_PROGRESS_SUCCESS = 'FETCH_COMPLETE_PROGRESS_SUCCESS'
export const FETCH_COMPLETE_PROGRESS_FAIL = 'FETCH_COMPLETE_PROGRESS_FAIL'
export const COMPLETE_SET_CURRENT_PAGE = 'COMPLETE_SET_CURRENT_PAGE'
export const COMPLETE_CALL_PREFERENCES_PAGE = 'COMPLETE_CALL_PREFERENCES_PAGE'
export const COMPLETE_DOCUMENTS_PAGE = 'COMPLETE_DOCUMENTS_PAGE'
export const APPLICATION_PROGRESS_PATH = 'applicant/progress'
export const COMPLETE_WELCOME_PHONE_CALL_PAGE = 'COMPLETE_WELCOME_PHONE_CALL_PAGE'
export const COMPLETE_EXTERNAL_DOCUMENTS_PAGE = 'COMPLETE_EXTERNAL_DOCUMENTS_PAGE'
export const COMPLETE_DISABLED_PAGE = 'COMPLETE_DISABLED_PAGE'
