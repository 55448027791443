import { connect } from 'react-redux'
import End from '../components/cards/end'
import { finishOnboarding } from '../actions/cards'

export default connect(
  state => ({
    name: state.settings.featureFlags.verticalServices ? 'verticals' : 'end'
  }),
  dispatch => ({
    finishOnboarding: page => dispatch(finishOnboarding(page))
  })
)(End)
