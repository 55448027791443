import {
  GET_EXPERIENCE_REQUEST,
  GET_EXPERIENCE_SUCCESS,
  SET_EXPERIENCE_REQUEST,
  SET_EXPERIENCE_FAILURE,
  SET_EXPERIENCE_SUCCESS,
  SET_EXPERIENCE_TEXT_REQUEST,
  SET_EXPERIENCE_TEXT_FAILURE,
  SET_EXPERIENCE_TEXT_SUCCESS,
  UPDATE_EXPERIENCE_FIELD
} from '../../constants/post_onfido/experience'

export const initialState = {
  isLoading: true,
  question: {
    humanTitle: '',
    answers: []
  },
  isSaving: false,
  selected: null,
  headline: '',
  description: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPERIENCE_REQUEST: {
      return {
        ...state,
        isLoading: true
      }
    }
    case GET_EXPERIENCE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        experienceQuestion: action.question
      }
    }
    case SET_EXPERIENCE_REQUEST: {
      return {
        ...state,
        selected: action.selected,
        isSaving: true
      }
    }
    case SET_EXPERIENCE_SUCCESS:
    case SET_EXPERIENCE_FAILURE: {
      return {
        ...state,
        isSaving: false
      }
    }
    case SET_EXPERIENCE_TEXT_REQUEST: {
      return {
        ...state,
        isSaving: true
      }
    }
    case SET_EXPERIENCE_TEXT_SUCCESS:
    case SET_EXPERIENCE_TEXT_FAILURE: {
      return {
        ...state,
        isSaving: false
      }
    }
    case UPDATE_EXPERIENCE_FIELD: {
      return {
        ...state,
        [action.name]: action.value
      }
    }
    default:
      return state
  }
}
