import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../../shared/components/modal'
import { ASAP, SPECIFIC_TIME, NEVER } from '../../constants/call_request'
import Header from '../shared/header'
import call from '../../../svg/call.svg'
import callUs from '../../../svg/call_us.svg'
import TrackingUtils from '../../shared/utils/tracking_utils'

class CallPreferences extends Component {
  state = {
    callRequestModalVisible: false,
    callRequestSlotSelected: false,
    callRequestType: '',
    callRequestReason: '',
    submitting: false
  }

  openCallRequestModal = requestType => {
    TrackingUtils.trackEvent('autoTrack', {
      eventCategory: 'Provider Funnel',
      eventLabel: 'in call request area',
      eventAction: `click on request type: ${requestType}, and open the modal`
    })
    return this.setState({
      callRequestModalVisible: true,
      callRequestType: requestType
    })
  }

  closeCallRequestModal = () => this.setState({ callRequestModalVisible: false }, () => this.props.nextAction())

  handleTextareaChange = e => this.setState({ callRequestReason: e.target.value })

  handleCallRequestSlotSelect = () => this.setState({ callRequestSlotSelected: true })

  submitCallRequest = async e => {
    this.setState({ submitting: true })

    if (e) {
      e.preventDefault()
    }

    const { callRequestType, callRequestReason } = this.state
    const timeSlot = (e && e.target && e.target.slot && e.target.slot.value) || undefined
    const requestReason = callRequestReason || undefined

    TrackingUtils.trackEvent('autoTrack', {
      eventCategory: 'Provider Funnel',
      eventLabel: 'in call request area',
      eventAction: `submit request type: ${callRequestType}`
    })
    await this.props.sendCallRequest(callRequestType, requestReason, timeSlot)
    this.closeCallRequestModal()
  }

  renderReasonTextarea = () => {
    if (this.state.callRequestType === NEVER) {
      return (
        <textarea
          id='call-request-reason'
          className='info-page__textarea'
          value={this.state.callRequestReason}
          onChange={this.handleTextareaChange}
        />
      )
    }

    return null
  }

  renderCallTimeOption = index => {
    const value = I18n.t(`complete.call_request.preferred_call_time.slot_${index}`)
    return (
      <div key={`slot${index}`}>
        <input
          id={`slot${index}`}
          type='radio'
          name='slot'
          onClick={this.handleCallRequestSlotSelect}
          value={value}
          required
          readOnly
          className='call-box__radio'
        />
        <label
          htmlFor={`slot${index}`}
          className='button btn medium expanded button-sbmt info-page__ghost-button call-box__button'
        >
          {value}
        </label>
      </div>
    )
  }

  renderModalAsapAction = () => {
    const { directPhoneNumber, score } = this.props
    const directCallEnabled = directPhoneNumber !== '' && score <= 2

    return (
      <div>
        {directCallEnabled && (
          <a
            onClick={() => {
              TrackingUtils.trackEvent('autoTrack', {
                eventCategory: 'Provider Funnel',
                eventLabel: 'in call request area',
                eventAction: 'click on call us now cta'
              })
            }}
            className='button btn primary medium expanded show-for-small-only'
            href={`tel:${directPhoneNumber.replace(/ /g, '')}`}
          >
            <svg style={{ height: '15px', width: '32px' }}>
              <use xlinkHref={`#${call.id}`} />
            </svg>
            {I18n.t('complete.call_request.modal.asap.call_now')}
          </a>
        )}

        <button
          disabled={this.state.submitting}
          className='button btn action medium expanded'
          onClick={this.submitCallRequest}
        >
          {directCallEnabled
            ? I18n.t('complete.call_request.modal.asap.dismiss')
            : I18n.t('my_documents.close_example')}
        </button>
      </div>
    )
  }

  renderModalNever = () => (
    <button
      disabled={this.state.submitting}
      className='button btn action medium expanded button-sbmt modal-button'
      onClick={this.submitCallRequest}
    >
      {I18n.t('complete.call_request.modal.submit_button')}
    </button>
  )

  renderModalSpecificTime = () => (
    <form onSubmit={e => this.submitCallRequest(e)}>
      {[...Array(5).keys()].slice(1).map(this.renderCallTimeOption)}
      <button
        disabled={!this.state.callRequestSlotSelected || this.state.submitting}
        className='button btn action medium expanded button-sbmt modal-button'
        type='submit'
      >
        {I18n.t('my_documents.submit')}
      </button>
    </form>
  )

  renderModalAction = callRequestType => {
    if (callRequestType === ASAP) {
      return this.renderModalAsapAction()
    }

    if (callRequestType === NEVER) {
      return this.renderModalNever()
    }

    return this.renderModalSpecificTime()
  }

  renderImage = callRequestType => {
    if (callRequestType !== ASAP || this.props.directPhoneNumber === '') {
      return null
    }

    return (
      <svg style={{ maxWidth: '100%' }}>
        <use xlinkHref={`#${callUs.id}`} />
      </svg>
    )
  }

  render() {
    const { callRequestModalVisible, callRequestType } = this.state
    const { directPhoneNumber, score } = this.props
    const callRequestTypeTitleLabel =
      callRequestType === ASAP && directPhoneNumber !== '' && score <= 2 ? `${ASAP}_CALL_US` : callRequestType

    return (
      <div className='text-center call-preferences'>
        <Header
          title={I18n.t('complete.call_preferences.title')}
          description={I18n.t('complete.call_preferences.description')}
        />
        <div className='align-center'>
          <div className='large-8 small-12'>
            <button
              id='call-asap'
              className='button btn medium expanded button-sbmt action'
              onClick={() => this.openCallRequestModal(ASAP)}
            >
              {I18n.t('complete.call_request.button.asap')}
            </button>
            <button
              id='call-specific-time'
              className='button btn medium expanded button-sbmt info-page__ghost-button'
              onClick={() => this.openCallRequestModal(SPECIFIC_TIME)}
            >
              {I18n.t('complete.call_request.button.specific_time')}
            </button>
            <button
              id='call-never'
              className='button btn medium expanded button-sbmt info-page__ghost-button'
              onClick={() => this.openCallRequestModal(NEVER)}
            >
              {I18n.t('complete.call_request.button.never')}
            </button>
          </div>
        </div>
        <Modal
          closeHandler={() => this.setState({ callRequestModalVisible: false })}
          showModal={callRequestModalVisible}
          classModificator={['narrow']}
        >
          <div className='text-center'>
            {this.renderImage(callRequestType)}

            <div
              className='modal__title'
              dangerouslySetInnerHTML={{
                __html: I18n.t(`complete.call_request.modal.title.${callRequestTypeTitleLabel}`, {
                  number: this.props.directPhoneNumber
                })
              }}
            />

            <div
              className='modal__body'
              dangerouslySetInnerHTML={{
                __html: I18n.t(`complete.call_request.modal.description.${callRequestType}`)
              }}
            />
            {this.renderReasonTextarea()}
            {this.renderModalAction(callRequestType)}
          </div>
        </Modal>
      </div>
    )
  }
}

CallPreferences.propTypes = {
  sendCallRequest: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired,
  directPhoneNumber: PropTypes.string,
  score: PropTypes.number.isRequired
}

export default CallPreferences
