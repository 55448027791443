import {
  SET_RIGHT_TO_WORK_REQUEST,
  SET_RIGHT_TO_WORK_SUCCESS,
  SET_RIGHT_TO_WORK_FAILURE
} from '../constants/right_to_work'

export const initialState = {
  isSaving: false,
  selected: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_RIGHT_TO_WORK_REQUEST: {
      return {
        ...state,
        selected: action.selected,
        isSaving: true
      }
    }
    case SET_RIGHT_TO_WORK_SUCCESS:
    case SET_RIGHT_TO_WORK_FAILURE: {
      return {
        ...state,
        isSaving: false
      }
    }
    default:
      return state
  }
}
