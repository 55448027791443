import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAIL,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_FAIL,
  UPLOAD_DOCUMENT_SUCCESS
} from '../constants/my_documents'

export const initialState = {
  data: [],
  isUploading: false,
  allDocumentsUploaded: false
}

const updateDocument = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT_SUCCESS: {
      if (state.key !== action.meta.previousAction.documentKey) {
        return state
      }
      return Object.assign({}, state, {
        uploaded: true,
        filename: action.meta.previousAction.filename
      })
    }
    default:
      return state
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS: {
      return {
        ...state,
        isUploading: true
      }
    }
    case FETCH_DOCUMENTS_FAIL: {
      return {
        ...state,
        isUploading: false
      }
    }
    case FETCH_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isUploading: false
      }
    }
    case UPLOAD_DOCUMENT: {
      return {
        ...state,
        isUploading: true
      }
    }
    case UPLOAD_DOCUMENT_FAIL: {
      return {
        ...state,
        isUploading: false
      }
    }
    case UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        data: state.data.map(d => updateDocument(d, action)),
        isUploading: false,
        allDocumentsUploaded: action.payload.data.data.all_uploaded
      }
    }
    default:
      return state
  }
}
