import { push } from 'react-router-redux'
import TrackingUtils from '../shared/utils/tracking_utils'
import {
  fetchApplicantMobile,
  updateApplicantMobile,
  mobileVerificationCodeRequest,
  mobileVerificationCodeCheck
} from '../api/mobile_verification'

import {
  GET_APPLICANT_MOBILE_REQUEST,
  GET_APPLICANT_MOBILE_SUCCESS,
  GET_APPLICANT_MOBILE_FAILURE,
  OPEN_EDIT_MOBILE_MODAL,
  CLOSE_EDIT_MOBILE_MODAL,
  SET_APPLICANT_MOBILE_REQUEST,
  SET_APPLICANT_MOBILE_SUCCESS,
  SET_APPLICANT_MOBILE_FAILURE,
  CHANGE_MODAL_MOBILE,
  CHANGE_CODE,
  VERIFICATION_CODE_REQUEST,
  VERIFICATION_CODE_SUCCESS,
  VERIFICATION_CODE_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_FAILURE
} from '../constants/mobile_verification'
import { formatGraphQLErrors } from '../utils/shared'

export const openEditMobile = () => ({
  type: OPEN_EDIT_MOBILE_MODAL
})

export const closeEditMobile = () => ({
  type: CLOSE_EDIT_MOBILE_MODAL
})

export const changeModalMobile = mobile => ({ type: CHANGE_MODAL_MOBILE, mobile })

export const requestVerificationCode = (resend = false) => async dispatch => {
  dispatch({ type: VERIFICATION_CODE_REQUEST })
  TrackingUtils.autoTrack('requested new mobile verification code')

  try {
    const { data: { mobileVerificationCodeRequest: { success } } } = await mobileVerificationCodeRequest()
    if (success) {
      dispatch({ type: VERIFICATION_CODE_SUCCESS, resend })
    } else {
      dispatch({ type: VERIFICATION_CODE_FAILURE, resend })
    }
  } catch (error) {
    dispatch({ type: VERIFICATION_CODE_FAILURE, error, resend })
  }
}

export const checkVerificationCode = code => async (dispatch, getState) => {
  dispatch({ type: VERIFY_REQUEST })

  const { userId, attempts } = getState().mobileVerification
  TrackingUtils.autoTrack(`mobile verification attempt ${attempts}`)

  try {
    const { data: { mobileVerificationCodeCheck: { success } } } = await mobileVerificationCodeCheck(code.join(''))
    if (success) {
      dispatch({ type: VERIFY_SUCCESS })
      TrackingUtils.autoTrack(`mobile verification success after attempt ${attempts}`)

      dispatch(push(`/become-a-cleaner/${userId}/eligibility`))
    } else {
      dispatch({ type: VERIFY_FAILURE })
    }
  } catch (error) {
    dispatch({ type: VERIFY_FAILURE, error })
  }
}

export const getApplicantMobile = userId => async dispatch => {
  dispatch({ type: GET_APPLICANT_MOBILE_REQUEST })

  try {
    const {
      data: { currentProvider: { mobile }, mobileVerificationRequired: { required } }
    } = await fetchApplicantMobile()

    if (!required) {
      dispatch(push(`/become-a-cleaner/${userId}/eligibility`))
    } else {
      dispatch(requestVerificationCode())
    }
    dispatch({ type: GET_APPLICANT_MOBILE_SUCCESS, mobile, userId })
  } catch (error) {
    dispatch({ type: GET_APPLICANT_MOBILE_FAILURE, error })
  }
}

export const setApplicantMobile = newMobile => async dispatch => {
  dispatch({ type: SET_APPLICANT_MOBILE_REQUEST })
  try {
    const { data: { updateMobile: { success, errors, mobile } } } = await updateApplicantMobile(newMobile)
    if (success) {
      dispatch({ type: SET_APPLICANT_MOBILE_SUCCESS, mobile })
      dispatch(requestVerificationCode())
    } else {
      dispatch({ type: SET_APPLICANT_MOBILE_FAILURE, errors: formatGraphQLErrors(errors) })
    }
  } catch (error) {
    dispatch({ type: SET_APPLICANT_MOBILE_FAILURE, errors: { base: error.message } })
  }
}

export const changeCode = code => (dispatch, getState) => {
  dispatch({ type: CHANGE_CODE, code })
  const { attempts } = getState().mobileVerification

  if (code.every(n => n !== '') && attempts === 0) {
    dispatch(checkVerificationCode(code))
  }
}
