import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
// eslint-disable-next-line
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'unfetch'
import { apiUrl } from '../utils/urls'

export default new ApolloClient({
  link: new HttpLink({
    uri: `//${apiUrl}/api/v2/rr`,
    credentials: 'include',
    fetch
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
})
