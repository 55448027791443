import { combineReducers } from 'redux'
import { routerReducer as routing } from 'react-router-redux'
import callRequest from './call_request'
import eligibility from './eligibility'
import earnings from './earnings'
import liveState from './live_state'
import contactDetails from './contact_details'
import personalDetails from './personal_details'
import settings from './settings'
import myDocuments from './my_documents'
import identifications from './identifications'
import progressStage from './progress_stage'
import complete from './complete'
import experience from './post_onfido/experience'
import onfido from './onfido'
import applicant from './applicant'
import services from './post_onfido/services'
import verticals from './post_onfido/verticals'
import availability from './post_onfido/availability'
import rightToWork from './right_to_work'
import mobileVerification from './mobile_verification'

export default combineReducers({
  applicant,
  eligibility,
  earnings,
  callRequest,
  liveState,
  myDocuments,
  identifications,
  contactDetails,
  personalDetails,
  progressStage,
  routing,
  settings,
  complete,
  experience,
  onfido,
  services,
  availability,
  rightToWork,
  verticals,
  mobileVerification
})
