import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AnswerList from '../shared/answer_list'
import Page from '../shared/page'
import Spinner from '../shared/spinner'

class Experience extends Component {
  static propTypes = {
    isLoading: PropTypes.bool,
    isSaving: PropTypes.bool,
    selected: PropTypes.number,
    question: PropTypes.shape({
      humanTitle: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          human: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired
        })
      )
    }),
    getExperienceQuestion: PropTypes.func.isRequired,
    saveExperience: PropTypes.func.isRequired,
    routeParams: PropTypes.shape({
      userId: PropTypes.string.isRequired
    })
  }

  static defaultProps = {}

  componentWillMount() {
    this.props.getExperienceQuestion()
  }

  render() {
    const { isLoading, isSaving, selected, saveExperience, question, routeParams: { userId } } = this.props

    if (isLoading) {
      return <Spinner />
    }

    return (
      <Page isLoading={isLoading || isSaving} progress={20}>
        <div className='row experience'>
          <div className='small-12 large-6 large-offset-3 column'>
            <div className='experience__thankyou'>
              <h2 className='experience__header'>{I18n.t('experience.thank_you')}</h2>
              <p>{I18n.t('experience.additional_questions')}</p>
            </div>

            <h3 className='page__title'>
              <span>{question.humanTitle}</span>
            </h3>
            <div className='page__wrapper'>
              <AnswerList
                answers={question.answers.map(answer =>
                  Object.assign({}, answer, { selected: selected === answer.value })
                )}
                onSelectAnswer={answer => saveExperience(answer, userId)}
              />
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

export default Experience
