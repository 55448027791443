import { connect } from 'react-redux'
import ExperienceText from '../../components/post_onfido/experience_text'
import { saveExperienceText, updateExperienceField } from '../../actions/post_onfido/experience'
import { makeDispatcher } from '../../utils/shared'

export default connect(
  state => ({
    isSaving: state.experience.isSaving,
    headline: state.experience.headline,
    description: state.experience.description
  }),
  dispatch => ({
    saveExperienceText: makeDispatcher(dispatch, saveExperienceText),
    updateExperienceField: makeDispatcher(dispatch, updateExperienceField)
  })
)(ExperienceText)
