export const nl = {
  welcome: {
    title: 'Bijna klaar!',
    description_p_1:
      'Jouw Helpling account is bijna klaar voor gebruik. De volgende stap is een verificatie van je identiteitsbewijs.',
    description_p_2:
      'Voor een veilige en snelle ID verificatie werkt Helpling samen met Onfido. Zodra je op de "Volgende" knop klikt zal je worden doorgeleid naar de website van Onfido. Daar kun je jouw identiteitsbewijs uploaden.',
    next_button: 'Volgende'
  },
  document_selector: {
    title: 'Verifieer jouw identiteit',
    hint: 'Selecteer het type document dat je wilt uploaden'
  },
  capture: {
    driving_licence: {
      front: {
        title: 'Voorkant rijbewijs',
        instructions: 'Upload de voorkant van jouw rijbewijs via de computer',
        webcam: 'Houd de voorkant van jouw rijbewijs in het kader (het wordt automatisch gedetecteerd)'
      },
      back: {
        title: 'Achterkant van rijbewijs',
        instructions: 'Upload de achterkant van jouw rijbewijs via de computer',
        webcam: 'Houd de achterkant van jouw rijbewijs in het kader (het wordt automatisch gedetecteerd)'
      }
    },
    national_identity_card: {
      front: {
        title: 'Voorkant identiteitsbewijs',
        instructions: 'Upload de voorkant van jouw identiteitsbewijs via de computer',
        webcam: 'Houd de voorkant van jouw identiteitsbewijs in het kader (het wordt automatisch gedetecteerd)'
      },
      back: {
        title: 'Achterkant identiteitsbewijs',
        instructions: 'Upload de achterkant van jouw identiteitsbewijs via de computer',
        webcam: 'Upload de achterkant van jouw identiteitsbewijs via de computer'
      }
    },
    passport: {
      front: {
        title: 'Paspoortpagina met foto',
        instructions: 'Upload jouw paspoortpagina met foto via de computer',
        webcam: 'Upload jouw paspoortpagina met foto via de computer'
      }
    },
    document: {
      help: 'Problemen? Je kunt ook een bestand uploaden'
    },
    face: {
      title: 'Neem een selfie',
      upload_title: 'Selfie',
      instructions: 'Upload een selfie via de computer',
      webcam: 'Upload een selfie via de computer',
      help: 'Problemen? Je kunt ook een selfie uploaden',
      button: 'Neem een selfie'
    }
  },
  confirm: {
    document: {
      title: 'Controleer leesbaarheid'
    },
    driving_licence: {
      message: 'Zorg dat de details van jouw rijbewijs goed leesbaar zijn, zonder waas of flits.'
    },
    national_identity_card: {
      message: 'Zorg dat de details van jouw identiteitsbewijs goed leesbaar zijn, zonder waas of flits.'
    },
    passport: {
      message: 'Zorg dat de details van jouw paspoort goed leesbaar zijn, zonder waas of flits.'
    },
    face: {
      title: 'Controlleer selfie',
      message: 'Zorg dat jouw gezicht goed zichtbaar is op de selfie.'
    },
    confirm: 'Bevestigen',
    continue: 'Verder',
    redo: 'Opnieuw doen'
  },
  capture_parentheses: '(of hier een bestand uploaden)',
  complete: {
    message: 'Verificatie compleet',
    submessage: 'Bedankt'
  },
  cross_device: {
    client_success: {
      title: 'Upload succesvol',
      sub_title: 'Je kan nu terug naar de computer om door te gaan',
      body: 'De computer kan een paar seconden bezig zijn met de update'
    },
    link: {
      title: 'Verificatie voortzetten met mobiele telefoon',
      sub_title: 'Wij sturen je per SMS een beveiligde link',
      link_copy: { action: 'Kopie', success: 'Gekopieerd' },
      button_copy: { action: 'Stuur link', status: 'Wordt gestuurd' },
      sms_label: 'Mobiel nummer',
      copy_link_label: 'Kopieer link:'
    },
    submit: {
      title: 'Super, dat is alles wat we nodig hebben',
      sub_title: 'We zijn nu klaar omm jouw identiteit te verifieren',
      selfie_uploaded: 'Selfie geupload',
      action: 'Verificatie indienen',
      multiple_docs_uploaded: 'Documenten geupload',
      one_doc_uploaded: 'Document geupload'
    },
    phone_number_placeholder: 'Vul jouw mobiele nummer in',
    loading: 'Laden...',
    mobile_connected: {
      title: {
        message: 'Verbonden met jouw mobiele telefoon',
        submessage: 'Zodra je klaar bent, ga je naar de volgende stap'
      },
      tips: {
        item_1: 'Houd dit venster open terwijl je jouw mobiel gebruikt',
        item_2: 'Jouw mobiele link verloopt in 1 uur.',
        item_3: 'Ververs deze pagina niet'
      }
    },
    mobile_notification_sent: {
      title: 'Controleer jouw mobiele telefoon',
      submessage: 'Wij hebben een beveiligde link gestuurd naar %{number}',
      bold_message: 'Het kan een paar minuten duren voor het aankomt',
      tips: {
        item_1: 'Houd dit venster open terwijl je jouw mobiel gebruikt',
        item_2: 'Jouw mobiele link verloopt in 1 uur'
      },
      resend_link: 'Verzend link opnieuw'
    },
    switch_device: {
      header: "Heb je jouw mobiel nodig om foto's te maken?",
      submessage: 'Vervolg de verificatie veilig op jouw mobiel'
    },
    tips: 'Tips'
  },
  errors: {
    invalid_capture: {
      message: 'Geen document gedetecteerd',
      instruction: 'Zorg dat het hele document in de foto staat'
    },
    invalid_type: { message: 'Bestand is niet aan het uploaden', instruction: 'Probeer een ander bestandstype' },
    unsupported_file: { message: 'Niet ondersteund bestandstype', instruction: 'Probeer een .jpg. of .png bestand' },
    invalid_size: { message: 'Bestandsgrootte te groot', instruction: 'Het bestand moet kleiner zijn dan 10MB' },
    no_face: { message: 'Geen gezicht gevonden', instruction: 'Jouw gezicht is nodig in de selfie' },
    multiple_faces: {
      message: 'Meerdere gezichten gevonden',
      instruction: 'Alleen jouw gezicht kan in de slefie voorkomen'
    },
    server_error: { message: 'Verbinding verbroken', instruction: 'Probeer het alsjeblieft nog eens' },
    glare_detected: {
      message: 'Flits gedetecteerd',
      instruction: 'Alle details moeten duidelijk zichtbaar en leesbaar zijn'
    },
    sms_failed: { message: 'Er is iets fout gegaan', instruction: 'Kopieer onderstaande link naar jouw mobiel' },
    sms_overuse: {
      message: 'Teveel pogingen om opnieuw te sturen',
      instruction: 'Kopieer onderstaande link naar jouw mobiel'
    },
    lazy_loading: { message: 'Er is een fout opgedoken tijdens het laden van het component' },
    invalid_number: { message: 'Controleer jouw mobiele nummer' },
    generic_client_error: {
      message: 'Er is iets fout gegaan',
      instruction: 'Je moet de verificatie herstarten op de computer'
    }
  },
  passport: 'Paspoort',
  driving_licence: 'Rijbewijs',
  national_identity_card: 'Identiteitskaart',
  short_passport: 'Paspoort',
  short_driving_licence: 'Rijbewijs',
  short_national_identity_card: 'Kaart',
  back: 'terug',
  cancel: 'Annuleren'
}
