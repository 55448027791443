import { push } from 'react-router-redux'
import {
  FETCH_QUESTION,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTION_FAIL,
  SELECT_ANSWER,
  SUBMIT_ANSWER,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAIL,
  CHANGE_TEXT_FIELD_ANSWER
} from '../constants/eligibility'

import { fetchQuestionPath, submitQuestionPath } from '../api/eligibility'

const getActualAnswer = answers => answers.find(a => a.selected)

export const selectAnswer = answerValue => ({
  type: SELECT_ANSWER,
  answerValue
})

export const apiGetQuestion = question => ({
  types: [FETCH_QUESTION, FETCH_QUESTION_SUCCESS, FETCH_QUESTION_FAIL],
  payload: {
    request: {
      method: 'get',
      url: fetchQuestionPath(),
      params: {
        question
      }
    }
  }
})

export const getQuestion = question => dispatch =>
  dispatch(apiGetQuestion(question)).then(res => {
    const redirectTo = (((res.payload || {}).data || {}).data || {}).redirect_to

    if (redirectTo) {
      dispatch(push(redirectTo))
    }
  })

export const submitAnswer = reqData => ({
  types: [SUBMIT_ANSWER, SUBMIT_ANSWER_SUCCESS, SUBMIT_ANSWER_FAIL],
  payload: {
    request: {
      method: 'patch',
      url: submitQuestionPath(),
      data: {
        application: reqData
      }
    }
  }
})

export const sendAnswer = (req, nextQuestion) => (dispatch, getState) => {
  const actualAnswer = getActualAnswer(getState().eligibility.data.question.answers)
  const reqData = {
    [req.key]: req.value
  }

  if (req.freeTextValue && req.freeTextValue !== '') {
    reqData.free_text_value = req.freeTextValue
  }

  dispatch(selectAnswer(req.value))
  return dispatch(submitAnswer(reqData))
    .then(() => dispatch(getQuestion(nextQuestion)))
    .catch(() => actualAnswer && dispatch(selectAnswer(actualAnswer.value)))
}

export const changeTextFieldAnswer = textAnswer => ({
  type: CHANGE_TEXT_FIELD_ANSWER,
  textAnswer
})
