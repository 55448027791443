import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Menu from '../../containers/menu'
import Footer from '../shared/footer'
import Sidebar from './sidebar'

class Index extends Component {
  componentWillMount() {
    this.props.apiFetchCompleteProgress()
  }

  render() {
    const {
      currentPage,
      callPreferencesSet,
      disabledReason,
      application,
      isLoading,
      params,
      children,
      selectedForFastTrack,
      documents
    } = this.props
    const documentsUploaded = !!documents.length && documents.every(doc => doc.uploaded)

    return (
      <div className='layout'>
        <div className='layout__content'>
          <Menu languagesMenuEnabled />

          <div className='row'>
            <div className='large-7 large-offset-1 large-order-2 small-12 small-order-1'>{children}</div>

            <div className='large-3 large-order-1 small-12 small-order-2 navigation-box--container'>
              {!isLoading && (
                <Sidebar
                  currentPage={currentPage}
                  callPreferencesSet={callPreferencesSet}
                  disabledReason={disabledReason}
                  application={application}
                  userId={params.userId}
                  selectedForFastTrack={selectedForFastTrack}
                  documentsUploaded={documentsUploaded}
                />
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    )
  }
}

Index.propTypes = {
  params: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }),
  apiFetchCompleteProgress: PropTypes.func.isRequired,
  application: PropTypes.shape({
    progress: PropTypes.number.isRequired,
    states: PropTypes.arrayOf(
      PropTypes.shape({
        identifier: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  currentPage: PropTypes.string.isRequired,
  callPreferencesSet: PropTypes.bool.isRequired,
  disabledReason: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  selectedForFastTrack: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired
}

export default Index
