import {
  GET_CALL_REQUEST,
  GET_CALL_REQUEST_SUCCESS,
  GET_CALL_REQUEST_FAIL,
  POST_CALL_REQUEST,
  POST_CALL_REQUEST_SUCCESS,
  POST_CALL_REQUEST_FAIL
} from '../constants/call_request'

import { callRequestPath } from '../api/call_request'

export const getCallRequest = () => ({
  types: [GET_CALL_REQUEST, GET_CALL_REQUEST_SUCCESS, GET_CALL_REQUEST_FAIL],
  payload: {
    request: {
      method: 'get',
      url: callRequestPath(),
      data: {}
    }
  }
})

export const sendCallRequest = (callRequestType, doNotCallMeReason, preferredCallTime) => ({
  types: [POST_CALL_REQUEST, POST_CALL_REQUEST_SUCCESS, POST_CALL_REQUEST_FAIL],
  payload: {
    request: {
      method: 'post',
      url: callRequestPath(),
      data: {
        call_request: {
          callRequestType,
          preferredCallTime,
          doNotCallMeReason
        }
      }
    }
  }
})
