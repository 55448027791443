import {
  FETCH_COMPLETE_PROGRESS,
  FETCH_COMPLETE_PROGRESS_FAIL,
  FETCH_COMPLETE_PROGRESS_SUCCESS,
  COMPLETE_SET_CURRENT_PAGE,
  COMPLETE_DOCUMENTS_PAGE,
  COMPLETE_WELCOME_PHONE_CALL_PAGE,
  COMPLETE_EXTERNAL_DOCUMENTS_PAGE,
  COMPLETE_DISABLED_PAGE
} from '../constants/complete'
import { POST_CALL_REQUEST_SUCCESS } from '../constants/call_request'

export const initialState = {
  progress: {
    progress: 0,
    info: [],
    states: []
  },
  isLoading: true,
  currentPage: '',
  callPreferencesSet: false,
  introductionVideoId: '',
  externalDocumentsPage: false,
  disabledReason: '',
  score: 5,
  selectedForFastTrack: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COMPLETE_PROGRESS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case FETCH_COMPLETE_PROGRESS_FAIL: {
      return {
        ...state,
        isLoading: false
      }
    }
    case FETCH_COMPLETE_PROGRESS_SUCCESS: {
      let currentPage = ''
      const { payload: { data: { data } } } = action

      if (data.call_preferences_set || data.selected_for_fast_track) {
        currentPage = COMPLETE_DOCUMENTS_PAGE
      }

      if (data.call_preferences_set && data.external_documents_page) {
        currentPage = COMPLETE_EXTERNAL_DOCUMENTS_PAGE
      }

      if (data.states.filter(e => e.identifier === 'documents' && e.state === 'completed').length) {
        currentPage = COMPLETE_WELCOME_PHONE_CALL_PAGE
      }

      if (data.disabled_reason) {
        currentPage = COMPLETE_DISABLED_PAGE
      }

      return {
        ...state,
        progress: data,
        isLoading: false,
        currentPage,
        callPreferencesSet: data.call_preferences_set,
        introductionVideoId: data.introduction_video_id,
        externalDocumentsPage: data.external_documents_page,
        disabledReason: data.disabled_reason,
        score: data.score,
        selectedForFastTrack: data.selected_for_fast_track
      }
    }
    case COMPLETE_SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.page
      }
    }
    case POST_CALL_REQUEST_SUCCESS: {
      return {
        ...state,
        callPreferencesSet: true
      }
    }
    default:
      return state
  }
}
