import { PROGRESS_STAGE, PROGRESS_STAGE_SUCCESS, PROGRESS_STAGE_FAIL } from '../constants/progress_stage'

export const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case PROGRESS_STAGE: {
      return [...state]
    }
    case PROGRESS_STAGE_SUCCESS: {
      return [...action.payload.data.data]
    }
    case PROGRESS_STAGE_FAIL: {
      return [...state]
    }
    default:
      return state
  }
}
