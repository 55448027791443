import PropTypes from 'prop-types'
import React, { Component } from 'react'
import DocumentBox from '../identifications/document_box'
import Spinner from '../../shared/components/spinner'
import Menu from '../../containers/menu'
import Footer from '../shared/footer'

class IdentificationsDocument extends Component {
  componentWillMount() {
    if (!this.props.identifications.data.length) {
      this.props.fetchIdentificationsDocuments()
    }
    if (!this.props.myDocuments.data.length) {
      this.props.apiFetchDocuments()
    }
  }

  goToNextStep = () => {
    const {
      routeParams: { document_id: documentId, userId },
      identifications: { data },
      goToDocument,
      goToComplete
    } = this.props
    const doc = data.filter(i => i.name === documentId)[0]

    if (!doc) {
      return
    }

    const sameGroupDocs = data.filter(i => i.group === doc.group)
    const docIndex = sameGroupDocs.findIndex(i => i.name === documentId)
    if (docIndex < sameGroupDocs.length - 1) {
      goToDocument(userId, sameGroupDocs[docIndex + 1].name)
    } else {
      goToComplete(userId)
    }
  }

  renderDocument = () => {
    const {
      routeParams: { userId, document_id: documentId },
      completeUrl,
      fileSizeValidation,
      myDocuments,
      uploadFile
    } = this.props

    if (!myDocuments.data.length) {
      return null
    }

    return (
      <div className='layout'>
        <div className='layout__content'>
          <Menu languagesMenuEnabled />
          <DocumentBox
            goToNextStep={this.goToNextStep}
            myDocuments={myDocuments}
            uploadFile={uploadFile}
            key={documentId}
            documentKey={documentId}
            userId={userId}
            completeUrl={completeUrl}
            fileSizeValidation={fileSizeValidation}
          />
        </div>
        <Footer />
      </div>
    )
  }

  render() {
    const { identifications: { isLoading } } = this.props

    return <div>{isLoading ? <Spinner /> : this.renderDocument()}</div>
  }
}

IdentificationsDocument.propTypes = {
  routeParams: PropTypes.shape({
    userId: PropTypes.string
  }),
  identifications: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        group: PropTypes.string
      })
    ),
    isLoading: PropTypes.bool
  }),
  myDocuments: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        key: PropTypes.string,
        name: PropTypes.string,
        uploaded: PropTypes.bool
      })
    ),
    isUploading: PropTypes.bool
  }),
  uploadFile: PropTypes.func.isRequired,
  apiFetchDocuments: PropTypes.func.isRequired,
  fetchIdentificationsDocuments: PropTypes.func.isRequired,
  goToComplete: PropTypes.func.isRequired,
  goToDocument: PropTypes.func.isRequired,
  completeUrl: PropTypes.func.isRequired,
  fileSizeValidation: PropTypes.bool
}

export default IdentificationsDocument
