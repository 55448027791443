import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { fetchIdentificationsDocuments } from '../actions/identifications'
import { fetchDocuments, uploadFile } from '../actions/my_documents'
import Document from '../components/identifications/document'

const mapStateToProps = state => ({
  fileSizeValidation: state.settings.fileSizeValidation,
  identifications: state.identifications,
  myDocuments: state.myDocuments,
  images: state.settings.images,
  os: state.settings.os
})

const mapDispatchToProps = dispatch => ({
  fetchIdentificationsDocuments: () => dispatch(fetchIdentificationsDocuments()),
  goToDocument: (userId, documentId) => dispatch(push(`/become-a-cleaner/${userId}/identifications/${documentId}`)),
  goToComplete: userId => dispatch(push(`/become-a-cleaner/${userId}/complete`)),
  completeUrl: userId => `/become-a-cleaner/${userId}/complete`,
  uploadFile: (file, key) => dispatch(uploadFile(file, key)),
  apiFetchDocuments: () => dispatch(fetchDocuments())
})

export default connect(mapStateToProps, mapDispatchToProps)(Document)
