import { connect } from 'react-redux'
import Experience from '../../components/post_onfido/experience'
import { getExperienceQuestion, saveExperience } from '../../actions/post_onfido/experience'
import { makeDispatcher } from '../../utils/shared'

export default connect(
  state => ({
    isLoading: state.experience.isLoading,
    isSaving: state.experience.isSaving,
    selected: state.experience.selected,
    question: state.experience.experienceQuestion
  }),
  dispatch => ({
    getExperienceQuestion: makeDispatcher(dispatch, getExperienceQuestion),
    saveExperience: makeDispatcher(dispatch, saveExperience)
  })
)(Experience)
