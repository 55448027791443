import gql from 'graphql-tag'
import client from './gql'

export const fetchApplicantMobile = () => {
  const query = gql`
    query fetchApplicantMobile {
      currentProvider {
        mobile
      }
      mobileVerificationRequired {
        required
      }
    }
  `
  return client.query({ query })
}

export const updateApplicantMobile = mobile => {
  const mutation = gql`
    mutation updateApplicantMobile($mobile: String!) {
      updateMobile(mobile: $mobile) {
        success
        mobile
        errors {
          mobile
        }
      }
    }
  `
  return client.mutate({ mutation, variables: { mobile } })
}

export const mobileVerificationCodeRequest = () => {
  const mutation = gql`
    mutation applicantMobileVerificationCodeRequest {
      mobileVerificationCodeRequest {
        success
      }
    }
  `

  return client.mutate({ mutation })
}

export const mobileVerificationCodeCheck = code => {
  const mutation = gql`
    mutation applicantMobileVerificationCodeCheck($code: String!) {
      mobileVerificationCodeCheck(code: $code) {
        success
      }
    }
  `

  return client.mutate({ mutation, variables: { code } })
}
