import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Modal from '../../shared/components/modal'
import TrackingUtils from '../../shared/utils/tracking_utils'
import plusIcon from '../../../svg/plus.svg'
import minusIcon from '../../../svg/minus.svg'
import { staticMapUrl, directionUrl } from '../../utils/shared'

class DocumentWhere extends Component {
  state = {
    modalVisible: false,
    expanded: -1
  }

  onExpandClick = idx => {
    this.setState({
      expanded: this.state.expanded === idx ? -1 : idx
    })
  }

  open = () => {
    TrackingUtils.trackEvent('autoTrack', {
      eventCategory: 'Provider Funnel',
      eventLabel: `with language ${I18n.locale}, in the document ${this.props.documentKey}`,
      eventAction: 'click on where to get'
    })
    this.setState({ modalVisible: true })
  }

  close = () => {
    this.setState({ modalVisible: false })
  }

  renderItem = ({ office, address, opening }, idx) => {
    const { expanded } = this.state
    const { isMobileApp } = this.props
    const hide = { display: 'none' }

    return (
      <div className='accordion where__accordion' key={idx}>
        <button className='accordion__question where__question' onClick={() => this.onExpandClick(idx)}>
          {office}
          <svg style={expanded === idx ? hide : {}}>
            <use xlinkHref={`#${plusIcon.id}`} />
          </svg>
          <svg style={expanded !== idx ? hide : {}}>
            <use xlinkHref={`#${minusIcon.id}`} />
          </svg>
        </button>
        {expanded === idx && (
          <div className='accordion__answer row'>
            <div className='small-12 medium-6 columns where__details'>
              <strong>{I18n.t('my_documents.where.address')}</strong>
              <p className='where__details-info'>{address}</p>
              {!isMobileApp && (
                <a
                  className='where__details-link'
                  href={directionUrl(address.replace(/\s+/g, '+'))}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {I18n.t('my_documents.where.check_directions')}
                </a>
              )}
            </div>

            <div className='small-12 medium-6 columns where__details'>
              <strong>{I18n.t('my_documents.where.opening_hours')}</strong>
              <p className='where__details-info'>{opening}</p>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderSingle = ({ office, address, opening }) => {
    const { isMobileApp } = this.props

    return (
      <div className='row'>
        <div className='small-12 medium-6 columns'>
          <div className='where__details'>
            <strong>{office}</strong>
            <p className='where__details-info'>{address}</p>
          </div>

          <div className='where__details'>
            <strong>{I18n.t('my_documents.where.opening_hours')}</strong>
            <p className='where__details-info'>{opening}</p>
          </div>
        </div>

        <div className='small-12 medium-6 columns'>
          <div className='where__map'>
            <img
              alt={address}
              src={staticMapUrl({
                address: address.replace(/\s+/g, '+'),
                width: 300,
                height: 200,
                zoom: 14
              })}
            />

            {!isMobileApp && (
              <a
                className='where__details-link'
                href={directionUrl(address.replace(/\s+/g, '+'))}
                target='_blank'
                rel='noopener noreferrer'
              >
                {I18n.t('my_documents.where.check_directions')}
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { where, documentKey, isMobileApp } = this.props

    const metroName = where[0].metro_name

    return (
      <div>
        <Modal closeHandler={this.close} showModal={this.state.modalVisible} classModificator={['medium']}>
          <div className='row'>
            <div className='small-12 medium-12 columns where'>
              <div className='where__title'>{I18n.t(`my_documents.where.${documentKey}.title`)}</div>
              <div className='where__intro'>
                <p>{I18n.t(`my_documents.where.${documentKey}.intro`)}</p>
                <p>
                  {where.length > 1 &&
                    I18n.t(`my_documents.where.${documentKey}.intro_list`, {
                      metro: metroName
                    })}
                  {where.length === 1 && I18n.t(`my_documents.where.${documentKey}.intro_single`)}
                </p>
              </div>

              {where.length > 1 && <div className='where__list'>{where.map(this.renderItem)}</div>}

              {where.length === 1 && <div className='where__single'>{this.renderSingle(where[0])}</div>}

              {!isMobileApp && (
                <div
                  className='where__outro'
                  dangerouslySetInnerHTML={{
                    __html: I18n.t(`my_documents.where.${documentKey}.outro`)
                  }}
                />
              )}
            </div>
          </div>
        </Modal>
        <button className='document-box__upload-example document-box__upload-example-last' onClick={this.open}>
          {I18n.t(`my_documents.where.${documentKey}.title`)}
        </button>
      </div>
    )
  }
}

DocumentWhere.propTypes = {
  documentKey: PropTypes.string.isRequired,
  where: PropTypes.array.isRequired,
  isMobileApp: PropTypes.bool.isRequired
}

export default DocumentWhere
