import {
  GET_AVERAGE_HOURLY_EARNINGS,
  GET_AVERAGE_HOURLY_EARNINGS_SUCCESS,
  GET_AVERAGE_HOURLY_EARNINGS_FAIL
} from '../constants/earnings'

export const initialState = {
  hourlyPaidByCustomer: 0,
  hourlyCleanerPayout: 0,
  metro: '',
  loading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AVERAGE_HOURLY_EARNINGS: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_AVERAGE_HOURLY_EARNINGS_SUCCESS: {
      return {
        ...state,
        ...action.payload.data.data,
        loading: false
      }
    }
    case GET_AVERAGE_HOURLY_EARNINGS_FAIL: {
      return {
        ...state,
        loading: false
      }
    }
    default:
      return state
  }
}
