import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'

const Answer = props => (
  <button
    className={cx('answer', { 'answer--selected': props.isSelected })}
    onClick={() => props.onSelectAnswer(props.value)}
  >
    {props.answerText}
  </button>
)

Answer.propTypes = {
  answerText: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onSelectAnswer: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
}

Answer.defaultProps = {
  answerText: '',
  isSelected: false,
  onSelectAnswer: () => {},
  value: ''
}

export default Answer
