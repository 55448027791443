import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'

class Modal extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.showModal && !this.props.showModal) {
      setTimeout(() => document.addEventListener('click', this.handleClickOutside), 0)
    }
    if (!nextProps.showModal && this.props.showModal) {
      document.removeEventListener('click', this.handleClickOutside)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  handleClickOutside = e => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.props.closeHandler()
    }
  }

  render() {
    const { withBubblesBg, closeHandler, showModal, classModificator, withOverlappingPhoto } = this.props
    const modificators = classModificator ? classModificator.map(i => `modal--${i}`).join(' ') : ''

    return (
      <div
        className={cx(`modal ${modificators}`, {
          modal__opened: showModal,
          modal__closed: !showModal
        })}
      >
        <a role='button' className='modal__close' onClick={closeHandler}>
          ×
        </a>

        <div
          ref={this.setWrapperRef}
          className={cx('modal__content', {
            'modal__content--with-animated-bubbles': withBubblesBg,
            'modal__content--with-overlapping-photo': withOverlappingPhoto
          })}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  children: PropTypes.node,
  closeHandler: PropTypes.func.isRequired,
  withBubblesBg: PropTypes.bool,
  withOverlappingPhoto: PropTypes.bool,
  showModal: PropTypes.bool.isRequired,
  classModificator: PropTypes.array
}

export default Modal
