import { connect } from 'react-redux'
import { sendForm, updateFormData, getSettings } from '../actions/contact_details'
import ContactDetails from '../components/contact_details'

const mapStateToProps = state => ({
  country: state.settings.country.name,
  errors: state.contactDetails.errors,
  isLoading: state.contactDetails.isLoading,
  user: state.contactDetails.user,
  localSettings: state.contactDetails.settings
})

const mapDispatchToProps = dispatch => ({
  sendForm: user => dispatch(sendForm(user)),
  getSettings: postcode => dispatch(getSettings(postcode)),
  updateFormData: (value, name) => dispatch(updateFormData(name, value))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails)
