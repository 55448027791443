import { connect } from 'react-redux'
import { getAverageHourlyEarnings } from '../actions/earnings'
import Earnings from '../components/earnings'

const mapStateToProps = state => ({
  hourlyPaidByCustomer: state.earnings.hourlyPaidByCustomer,
  hourlyCleanerPayout: state.earnings.hourlyCleanerPayout,
  metro: state.earnings.metro,
  loading: state.earnings.loading,
  selectedForFastTrack: state.complete.selectedForFastTrack
})

const mapDispatchToProps = dispatch => ({
  getAverageHourlyEarnings: () => dispatch(getAverageHourlyEarnings())
})

export default connect(mapStateToProps, mapDispatchToProps)(Earnings)
