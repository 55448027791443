import {
  HANDLE_SERVICE_CHANGE,
  SET_SERVICES_REQUEST,
  SET_SERVICES_FAILURE,
  SET_SERVICES_SUCCESS
} from '../../constants/post_onfido/services'

export const initialState = {
  isSaving: false,
  selectedServices: ['pets']
}

export default (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SERVICE_CHANGE: {
      return {
        ...state,
        selectedServices:
          state.selectedServices.indexOf(action.service) === -1
            ? state.selectedServices.concat([action.service])
            : state.selectedServices.filter(i => i !== action.service)
      }
    }
    case SET_SERVICES_REQUEST: {
      return {
        ...state,
        isSaving: true
      }
    }
    case SET_SERVICES_SUCCESS:
    case SET_SERVICES_FAILURE: {
      return {
        ...state,
        isSaving: false
      }
    }
    default:
      return state
  }
}
