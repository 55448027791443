import {
  GET_ONFIDO_TOKEN_REQUEST,
  GET_ONFIDO_TOKEN_SUCCESS,
  GET_ONFIDO_TOKEN_FAILURE,
  START_ONFIDO_CHECK_REQUEST,
  START_ONFIDO_CHECK_SUCCESS,
  GET_ONFIDO_DATA_SUCCESS
} from '../constants/onfido'

export const initialState = {
  token: '',
  loading: true,
  status: 'upload',
  timer: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ONFIDO_TOKEN_REQUEST: {
      return { ...state, loading: true }
    }
    case GET_ONFIDO_TOKEN_FAILURE: {
      return { ...state, loading: false }
    }
    case GET_ONFIDO_TOKEN_SUCCESS: {
      return { ...state, loading: false, token: action.data.createOnfidoJWT.token }
    }
    case START_ONFIDO_CHECK_REQUEST: {
      return { ...state, status: 'started', loading: true }
    }
    case START_ONFIDO_CHECK_SUCCESS: {
      return { ...state, loading: false }
    }
    case GET_ONFIDO_DATA_SUCCESS: {
      return {
        ...state,
        nationality: action.nationality,
        state: action.state,
        loading: false
      }
    }
    default:
      return state
  }
}
