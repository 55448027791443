import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { fetchIdentificationsDocumentsOrRedirect } from '../actions/identifications'
import Identifications from '../components/identifications'

const mapStateToProps = state => ({
  identifications: state.identifications
})

const mapDispatchToProps = dispatch => ({
  fetchIdentificationsDocumentsOrRedirect: userId => dispatch(fetchIdentificationsDocumentsOrRedirect(userId)),
  goToDocument: (userId, documentId) => dispatch(push(`/become-a-cleaner/${userId}/identifications/${documentId}`)),
  completeUrl: userId => `/become-a-cleaner/${userId}/complete`
})

export default connect(mapStateToProps, mapDispatchToProps)(Identifications)
