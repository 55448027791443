import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Button } from 'lasagna'
import Page from '../shared/page'

const ExperienceText = ({
  saveExperienceText,
  updateExperienceField,
  isSaving,
  headline,
  description,
  routeParams: { userId }
}) => (
  <Page progress={30}>
    <div className='row experience'>
      <div className='small-12 large-6 large-offset-3 column'>
        <div className='experience__section'>
          <p>
            <strong>{I18n.t('experience_text.headline')}</strong>
            <br />
            <i>{I18n.t('experience_text.headline_sub')}</i>
          </p>
          <div>
            <input
              onChange={event => updateExperienceField('headline', event.target.value)}
              type='text'
              value={headline}
              maxLength={100}
            />
            <div className='text-right experience__char-left'>
              <small>
                {I18n.t('experience_text.char_limit', {
                  length: headline.length,
                  maxLength: 100
                })}
              </small>
            </div>
          </div>
        </div>
        <div className='experience__section'>
          <p>
            <strong>{I18n.t('experience_text.description')}</strong>
          </p>
          <div>
            <textarea
              onChange={event => updateExperienceField('description', event.target.value)}
              maxLength={500}
              rows={3}
              value={description}
            />
            <div className='text-right experience__char-left'>
              <small>
                {I18n.t('experience_text.char_limit', {
                  length: description.length,
                  maxLength: 500
                })}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='row experience'>
      <div className='small-12 large-6 large-offset-3 column'>
        <Button
          action
          expanded
          rounded
          screaming
          disabled={isSaving}
          onClick={() => saveExperienceText(headline, description, userId)}
        >
          {I18n.t('experience_text.next')}
        </Button>

        <Link className='back-button back-button--no-padding' to={`/become-a-cleaner/${userId}/services`}>
          <u>{I18n.t('experience_text.skip')}</u>
        </Link>

        <Link className='back-button' to={`/become-a-cleaner/${userId}/experience`}>
          &lt; {I18n.t('experience_text.back')}
        </Link>
      </div>
    </div>
  </Page>
)

ExperienceText.propTypes = {
  saveExperienceText: PropTypes.func.isRequired,
  updateExperienceField: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  routeParams: PropTypes.shape({
    userId: PropTypes.string.isRequired
  })
}

export default ExperienceText
