import PropTypes from 'prop-types'
import React, { Component } from 'react'

class LanguagesMenu extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  }

  setWrapperRef = node => {
    this.wrapperRef = node
  }

  setLanguage = (e, key) => {
    e.preventDefault()
    this.props.setPreferredLanguage(key)
  }

  handleClickOutside = e => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.props.onClose()
    }
  }

  render() {
    const { languages, currentLanguage } = this.props

    return (
      <div className='row menu__lang-wrapper'>
        <ul ref={this.setWrapperRef} className='menu__lang-list'>
          {Object.keys(languages).map(key => (
            <li key={key}>
              {key === currentLanguage ? (
                <span className='menu__lang-item menu__lang-item--active'>{languages[key]}</span>
              ) : (
                <a className='menu__lang-item' href='#' onClick={e => this.setLanguage(e, key)}>
                  {languages[key]}
                </a>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

LanguagesMenu.propTypes = {
  languages: PropTypes.object,
  currentLanguage: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  setPreferredLanguage: PropTypes.func.isRequired
}

LanguagesMenu.defaultProps = {
  languages: []
}

export default LanguagesMenu
