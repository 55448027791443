export const SET_PREFERRED_LANGUAGE = 'SET_PREFERRED_LANGUAGE'
export const SET_PREFERRED_LANGUAGE_SUCCESS = 'SET_PREFERRED_LANGUAGE_SUCCESS'
export const SET_PREFERRED_LANGUAGE_FAIL = 'SET_PREFERRED_LANGUAGE_FAIL'
export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST'
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS'
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE'
export const UPDATE_WELCOME_CARDS = 'UPDATE_WELCOME_CARDS'

export const LOCALE_AU = 'au'
export const LOCALE_DE = 'de'
export const LOCALE_EN = 'en'
export const LOCALE_FR = 'fr'
export const LOCALE_IE = 'ie'
export const LOCALE_IT = 'it'
export const LOCALE_NL = 'nl'
export const LOCALE_SG = 'sg'
export const LOCALE_UK = 'uk'
export const LOCALE_DE_EN = 'de_en'
export const LOCALE_NL_EN = 'nl_en'
