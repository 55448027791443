import humps from 'humps'

const getErrors = action => action.payload.data.errors

export const getPayload = (prevState, action) => {
  if (getErrors(action).length) return getErrors(action)[0].message

  return !action.payload.data.data.redirect_to
    ? {
      data: humps.camelizeKeys(action.payload.data.data)
    }
    : {
      redirectTo: action.payload.data.data.redirect_to
    }
}

export default getPayload
