import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Button } from 'lasagna'
import Modal from '../../shared/components/modal'
import styles from './change_mobile_modal.scss'
import PhoneInput from '../shared/phone_input'

const ChangeMobileModal = ({ visible, mobile, errors, onChange, onClose, onSubmit }) => (
  <Modal closeHandler={onClose} showModal={visible} classModificator={['small']}>
    <React.Fragment>
      <div className={styles.field}>
        <label htmlFor='change_mobile_modal_input' className={cx('modal__title', styles.label)}>
          {I18n.t('mobile_verification.update_mobile')}
        </label>

        <PhoneInput
          name='change_mobile_modal_input'
          label={I18n.t('contact_details.mobile')}
          value={mobile}
          onChange={onChange}
          error={errors.mobile}
          inModal
        />

        {errors.base && <div className={styles.error}>{errors.base}</div>}
      </div>

      <Button disabled={!mobile || mobile === ''} action expanded rounded screaming onClick={() => onSubmit(mobile)}>
        {I18n.t('mobile_verification.update')}
      </Button>

      <Button hollow expanded rounded screaming onClick={onClose}>
        {I18n.t('mobile_verification.cancel')}
      </Button>
    </React.Fragment>
  </Modal>
)

ChangeMobileModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  mobile: PropTypes.string,
  errors: PropTypes.shape({
    mobile: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default ChangeMobileModal
