import {
  GET_AVERAGE_HOURLY_EARNINGS,
  GET_AVERAGE_HOURLY_EARNINGS_SUCCESS,
  GET_AVERAGE_HOURLY_EARNINGS_FAIL
} from '../constants/earnings'

export const getAverageHourlyEarnings = () => ({
  types: [GET_AVERAGE_HOURLY_EARNINGS, GET_AVERAGE_HOURLY_EARNINGS_SUCCESS, GET_AVERAGE_HOURLY_EARNINGS_FAIL],
  payload: {
    request: {
      method: 'get',
      url: 'applicant/earnings',
      params: {}
    }
  }
})

export default getAverageHourlyEarnings
