import {
  FETCH_COMPLETE_PROGRESS,
  FETCH_COMPLETE_PROGRESS_SUCCESS,
  FETCH_COMPLETE_PROGRESS_FAIL,
  COMPLETE_SET_CURRENT_PAGE,
  APPLICATION_PROGRESS_PATH
} from '../constants/complete'

export const fetchProgress = () => ({
  types: [FETCH_COMPLETE_PROGRESS, FETCH_COMPLETE_PROGRESS_SUCCESS, FETCH_COMPLETE_PROGRESS_FAIL],
  payload: {
    request: {
      method: 'GET',
      url: APPLICATION_PROGRESS_PATH
    }
  }
})

export const setCurrentPage = page => ({
  type: COMPLETE_SET_CURRENT_PAGE,
  page
})
