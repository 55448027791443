import PropTypes from 'prop-types'
import React, { Component } from 'react'
import YouTubeIframeLoader from 'youtube-iframe'

class YoutubeVideo extends Component {
  componentDidMount() {
    const { videoId, description, height } = this.props
    const videoObj = {
      event: 'autoTrack',
      eventCategory: 'Provider Funnel',
      eventLabel: `in the video ${videoId}(${description || 'no description'})`
    }

    const videoFractionThresholds = [0.1, 0.2, 0.5, 0.75]
    let playedTriggered = false
    let endedTriggered = false

    dataLayer.push({
      ...videoObj,
      eventAction: 'youtube video showed'
    })

    YouTubeIframeLoader.load(YT => {
      this.ytPlayer = new YT.Player('youtube-video', {
        width: '100%',
        height,
        videoId,
        playerVars: {
          controls: 0,
          showinfo: 0,
          rel: 0
        },
        events: {
          onReady: () => {
            this.videoFraction = setInterval(() => {
              if (!videoFractionThresholds.length) {
                clearInterval(this.videoFraction)
                return
              }
              const videoFraction = this.ytPlayer.getCurrentTime() / this.ytPlayer.getDuration()
              const item = videoFractionThresholds[0]

              if (videoFraction >= item) {
                dataLayer.push({
                  ...videoObj,
                  eventAction: `youtube video reached ${item * 100}%`
                })
                videoFractionThresholds.shift()
              }
            }, 2000)
          },
          onStateChange: ({ data }) => {
            if (data === 1 && !playedTriggered) {
              playedTriggered = true
              dataLayer.push({
                ...videoObj,
                eventAction: 'youtube video played'
              })
            } else if (data === 0 && !endedTriggered) {
              endedTriggered = true
              dataLayer.push({
                ...videoObj,
                eventAction: 'youtube video ended'
              })
            }
          }
        }
      })
    })
  }

  componentWillUnmount() {
    clearInterval(this.videoFraction)
  }

  render() {
    return <div id='youtube-video' />
  }
}

YoutubeVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  description: PropTypes.string,
  height: PropTypes.string
}

YoutubeVideo.defaultProps = {
  description: '',
  height: '360px'
}

export default YoutubeVideo
