import gql from 'graphql-tag'
import client from './gql'

export const storeRightToWorkQuestion = value => {
  const mutation = gql`
    mutation storeRightToWork($value: Boolean!) {
      storeRightToWork(value: $value) {
        success
        successRedirectUrl
      }
    }
  `

  return client.mutate({ mutation, variables: { value } })
}
