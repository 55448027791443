import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import NavigationBox from './navigation_box'
import ProgressBar from '../shared/progress_bar'
import StateListItem from './state_list_item'
import ListItem from './list_item'
import ListItemWithIcon from './list_item_with_icon'

const QualifiedLeadSidebar = ({
  currentPage,
  callPreferencesSet,
  disabledReason,
  application: { progress, states },
  userId,
  selectedForFastTrack,
  documentsUploaded
}) => {
  const ftStates = [
    {
      extraClasses: ['list-item--completed'],
      icon: '#icon-complete',
      text: I18n.t('complete.progress.registration.title')
    },
    {
      extraClasses: [documentsUploaded ? 'list-item--completed' : 'list-item--active'],
      icon: '#icon-document-dark',
      text: I18n.t('complete.progress.upload_id.title')
    },
    {
      extraClasses: [],
      icon: '#icon-dollar',
      text: I18n.t('complete.progress.live.title')
    }
  ]
  const menu = selectedForFastTrack
    ? ftStates.map(state => <ListItemWithIcon key={state.text} {...state} />)
    : states.map(state => (
      <StateListItem
        key={state.identifier}
        currentPage={currentPage}
        callPreferencesSet={callPreferencesSet}
        disabledReason={disabledReason}
        {...state}
      />
    ))

  return (
    <div>
      <NavigationBox title={I18n.t('complete.progress.navigation.title')}>
        <ProgressBar progress={progress} modifierClass={'progress-bar--desktop'} />
        {menu}
      </NavigationBox>

      <NavigationBox title={I18n.t('complete.progress.more_info.title')}>
        <ListItem>
          <Link to={`/become-a-cleaner/${userId}/earnings`}>{I18n.t('complete.progress.earnings.title')}</Link>
        </ListItem>
      </NavigationBox>
    </div>
  )
}

QualifiedLeadSidebar.propTypes = {
  userId: PropTypes.string.isRequired,
  application: PropTypes.shape({
    progress: PropTypes.number.isRequired,
    states: PropTypes.arrayOf(
      PropTypes.shape({
        identifier: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  currentPage: PropTypes.string.isRequired,
  callPreferencesSet: PropTypes.bool.isRequired,
  disabledReason: PropTypes.string,
  selectedForFastTrack: PropTypes.bool.isRequired,
  documentsUploaded: PropTypes.bool.isRequired
}

export default QualifiedLeadSidebar
