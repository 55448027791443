import React from 'react'
import conversation from '../../../svg/conversation.svg'
import Page from '../shared/page'
import { mainAppUrl } from '../../utils/urls'

const CallBack = () => (
  <Page progress={100}>
    <div className='row post-onfido text-left'>
      <div className='small-12 medium-offset-2 medium-8 columns'>
        <div className='welcome'>
          <div className='provider-header row'>
            <div className='small-12 large-offset-2 large-8 columns'>
              <h2 className='provider-header__title'>{I18n.t('welcome.call_back_page.title')}</h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: I18n.t('welcome.call_back_page.desc')
                }}
              />

              <div className='welcome__image'>
                <svg>
                  <use xlinkHref={`#${conversation.id}`} />
                </svg>
              </div>

              <div className='welcome__details'>
                <a
                  className='button btn action medium expanded'
                  href={`//${mainAppUrl}/providers/home`}
                  alt='dashboard'
                >
                  {I18n.t('welcome.go_to_dashboard')}
                </a>
                <p>{I18n.t('welcome.offers.details')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
)

export default CallBack
