import { connect } from 'react-redux'
import QualifiedLead from '../components/qualified_lead'
import { fetchProgress } from '../actions/complete'

const mapStateToProps = state => ({
  isLoading: state.complete.isLoading,
  application: state.complete.progress,
  currentPage: state.complete.currentPage,
  callPreferencesSet: state.complete.callPreferencesSet,
  disabledReason: state.complete.disabledReason,
  selectedForFastTrack: state.complete.selectedForFastTrack,
  documents: state.myDocuments.data
})
const mapDispatchToProps = dispatch => ({
  apiFetchCompleteProgress: () => dispatch(fetchProgress())
})

export default connect(mapStateToProps, mapDispatchToProps)(QualifiedLead)
