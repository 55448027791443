import {
  FETCH_DOCUMENTS,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAIL,
  UPLOAD_DOCUMENT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAIL
} from '../constants/my_documents'
import TrackingUtils from '../shared/utils/tracking_utils'

import { fetchDocumentsPath, uploadFilePath } from '../api/my_documents'

const parseDocumentData = documentKey =>
  documentKey === 'profile_photo'
    ? {
      fixedDocumentKey: 'profile[picture]',
      urlSuffix: 'picture'
    }
    : {
      fixedDocumentKey: `application[${documentKey}]`,
      urlSuffix: 'scans'
    }

export const fetchDocuments = () => ({
  types: [FETCH_DOCUMENTS, FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_FAIL],
  payload: {
    request: {
      method: 'GET',
      url: fetchDocumentsPath()
    }
  }
})

export const uploadFile = (file, documentKey) => {
  const { fixedDocumentKey, urlSuffix } = parseDocumentData(documentKey)

  const formData = new FormData()
  formData.append(fixedDocumentKey, file, file.name)

  return {
    types: [UPLOAD_DOCUMENT, UPLOAD_DOCUMENT_SUCCESS, UPLOAD_DOCUMENT_FAIL],
    documentKey,
    payload: {
      request: {
        method: 'POST',
        url: uploadFilePath(urlSuffix),
        data: formData
      },
      headers: {
        'content-type': 'multipart/form-data'
      }
    },
    filename: file.name
  }
}

export const sendFile = (file, documentKey) => dispatch =>
  dispatch(uploadFile(file, documentKey)).then(res => {
    TrackingUtils.trackEvent('uploadedDocument', {
      document: documentKey
    })
    return res
  })
