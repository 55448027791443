import React from 'react'
import PropTypes from 'prop-types'
import map from 'lodash.map'
import { Input } from 'lasagna'

const Option = ({ postcode }) =>
  Array.isArray(postcode) ? (
    <option value={postcode[1]} key={postcode[1]}>
      {postcode[0]}
    </option>
  ) : (
    <option value={postcode} key={postcode}>
      {postcode}
    </option>
  )

Option.propTypes = {
  postcode: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

const PostcodeSelector = ({ error, onChange, value, allowedPostcodes }) => (
  <Input
    name='postcode'
    type={allowedPostcodes ? 'select' : 'text'}
    label={I18n.t('personal_details.label.postcode')}
    error={error ? I18n.t('personal_details.errors.postcode') : ''}
    value={value}
    onChange={onChange}
    optionsHtml={
      allowedPostcodes ? (
        <React.Fragment>
          {map(allowedPostcodes, (postcodes, label) => (
            <optgroup key={label} label={label}>
              {map(postcodes, postcode => <Option postcode={postcode} key={postcode} />)}
            </optgroup>
          ))}
        </React.Fragment>
      ) : null
    }
  />
)

PostcodeSelector.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  allowedPostcodes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array, PropTypes.string])))
}

export default PostcodeSelector
