import { connect } from 'react-redux'
import {
  closeErrorBox,
  openErrorBox,
  successRedirect,
  sendForm,
  updateFormData,
  getSettings
} from '../actions/personal_details'
import PersonalDetails from '../components/personal_details'

const mapStateToProps = state => ({
  errors: state.personalDetails.errors,
  isLoading: state.personalDetails.isLoading,
  isErrorBoxVisible: state.personalDetails.isErrorBoxVisible,
  nationalities: state.settings.nationalities,
  localSettings: state.personalDetails.settings,
  user: state.personalDetails.user
})

const mapDispatchToProps = dispatch => ({
  sendForm: () => dispatch(sendForm()),
  closeErrorBox: () => dispatch(closeErrorBox()),
  openErrorBox: () => dispatch(openErrorBox()),
  successRedirect: url => dispatch(successRedirect(url)),
  updateFormData: (value, name) => dispatch(updateFormData(name, value)),
  getSettings: () => dispatch(getSettings())
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails)
