import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cx from 'classnames'
import { Button, Input } from 'lasagna'
import PhoneInput from '../shared/phone_input'
import Menu from '../../containers/menu'
import Spinner from '../../shared/components/spinner'
import { mainAppUrl } from '../../utils/urls'

class ContactDetails extends Component {
  state = {
    dataDeferValidation: true
  }

  componentDidMount() {
    this.props.getSettings(this.props.location.query.postcode)
    this.props.updateFormData('postcode', this.props.location.query.postcode)
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.sendForm(this.props.user)
  }

  title() {
    const { localSettings } = this.props

    if (localSettings.fullPostcode) {
      return I18n.t(`contact_details.${localSettings.postcodeIsSearchable ? 'good_news' : 'looking_for_work'}`, {
        postcode: localSettings.fullPostcode
      })
    }

    return I18n.t('contact_details.complete_registration')
  }

  renderForm() {
    const formAttr = this.state.dataDeferValidation ? { 'data-defer-validation': true } : {}
    const { country, user, errors, localSettings, updateFormData, isLoading } = this.props
    const short = error => error && error.length && error.length < 50 && error !== ''
    const long = error => error && error.length && error.length >= 50
    const displayedError = (error, label) => {
      if (error === '') {
        return ''
      }
      return short(error) ? error : label
    }

    return (
      <div>
        {!!localSettings.city &&
          localSettings.mobileImage &&
          localSettings.desktopImage && (
          <div className='personal-details__city-map'>
            <style
              dangerouslySetInnerHTML={{
                __html: `
                .personal-details__city-map { background-image: url('${localSettings.mobileImage}') }

                @media (min-width: 640px) {
                  .personal-details__city-map { background-image: url('${localSettings.desktopImage}') }
                }
              `
              }}
            />
            <span
              dangerouslySetInnerHTML={{
                __html: I18n.t('contact_details.customers_in_city', {
                  city: localSettings.city,
                  customers: localSettings.customers
                })
              }}
            />
          </div>
        )}

        <div className={cx('row', { 'hide-for-small-only': !!localSettings.city })}>
          <div className='small-12 large-offset-2 large-8'>
            <header className='personal-details__header'>
              <h2
                className='personal-details__title'
                dangerouslySetInnerHTML={{
                  __html: this.title()
                }}
              />
            </header>
          </div>
        </div>

        <div className='row'>
          <div className='small-12 large-offset-3 large-6'>
            <form onSubmit={this.handleSubmit} disabled={isLoading} className='personal-details__form' {...formAttr}>
              <div className='row'>
                <div className='small-12 columns'>
                  <Input
                    required
                    type='email'
                    name='email'
                    label={I18n.t('contact_details.email')}
                    error={displayedError(errors.email, I18n.t('contact_details.email'))}
                    value={user.email}
                    onChange={updateFormData}
                  />
                  {long(errors.email) && (
                    <p className='personal-details__text' dangerouslySetInnerHTML={{ __html: errors.email }} />
                  )}

                  <Input
                    required
                    name='firstname'
                    label={I18n.t('contact_details.firstname')}
                    value={user.firstname}
                    onChange={updateFormData}
                  />

                  <Input
                    required
                    name='lastname'
                    label={I18n.t('contact_details.lastname')}
                    value={user.lastname}
                    onChange={updateFormData}
                  />

                  <PhoneInput
                    name='mobile'
                    label={I18n.t('contact_details.mobile')}
                    value={user.mobile}
                    error={displayedError(errors.mobile, I18n.t('contact_details.mobile'))}
                    onChange={phone => updateFormData(phone, 'mobile')}
                    defaultCountry={country}
                  />
                  {long(errors.mobile) && (
                    <p className='personal-details__text' dangerouslySetInnerHTML={{ __html: errors.mobile }} />
                  )}
                </div>
              </div>
              <div
                className='row'
                style={{
                  marginLeft: 0,
                  marginRight: 0
                }}
              >
                {localSettings.selfEmploymentCheckbox && (
                  <div className='small-2 medium-1 columns'>
                    <input
                      checked={user.terms}
                      type='checkbox'
                      className='fancy-checkbox'
                      id='terms'
                      onChange={e => updateFormData(e.target.checked, 'terms')}
                    />
                    <label htmlFor='terms' className='personal-details__terms-cb-label' />
                  </div>
                )}
                <div className={`${localSettings.selfEmploymentCheckbox ? 'small-10 medium-11' : 'small-12'} columns`}>
                  <p
                    className='personal-details__text'
                    dangerouslySetInnerHTML={{
                      __html: I18n.t(
                        `${
                          localSettings.businessLicenseCheckbox
                            ? 'contact_details.data_privacy_html'
                            : 'contact_details.accept_terms_html'
                        }`,
                        { main_app_url: mainAppUrl }
                      )
                    }}
                  />
                </div>
              </div>
              {localSettings.businessLicenseCheckbox && (
                <div
                  className='row'
                  style={{
                    marginLeft: 0,
                    marginRight: 0
                  }}
                >
                  {localSettings.selfEmploymentCheckbox && (
                    <div className='small-2 medium-1 columns'>
                      <input
                        checked={user.businessLicense}
                        type='checkbox'
                        className='fancy-checkbox'
                        id='businessLicense'
                        onChange={e => updateFormData(e.target.checked, 'businessLicense')}
                      />
                      <label htmlFor='businessLicense' className='personal-details__terms-cb-label' />
                    </div>
                  )}
                  <div
                    className={`${localSettings.selfEmploymentCheckbox ? 'small-10 medium-11' : 'small-12'} columns`}
                  >
                    <p
                      className='personal-details__text'
                      dangerouslySetInnerHTML={{
                        __html: I18n.t('contact_details.self_employment_html', { main_app_url: mainAppUrl })
                      }}
                    />
                  </div>
                </div>
              )}

              <div className='simple-box'>
                <Button
                  disabled={!user.terms || !user.businessLicense}
                  type='submit'
                  action
                  expanded
                  rounded
                  screaming
                  onClick={() => this.setState({ dataDeferValidation: false })}
                >
                  {I18n.t('contact_details.sign_up')}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { isLoading, localSettings } = this.props

    return (
      <div
        className={cx('personal-details', {
          'personal-details--loading': isLoading
        })}
      >
        <Menu languagesMenuEnabled withoutUser />

        {!localSettings && <Spinner config={{ top: '250px' }} />}
        {localSettings && this.renderForm()}
      </div>
    )
  }
}

ContactDetails.propTypes = {
  country: PropTypes.string.isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      postcode: PropTypes.string
    })
  }),
  sendForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  updateFormData: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    mobile: PropTypes.string,
    terms: PropTypes.bool,
    businessLicense: PropTypes.bool
  }),
  errors: PropTypes.shape({
    email: PropTypes.string,
    mobile: PropTypes.string
  }),
  getSettings: PropTypes.func.isRequired,
  localSettings: PropTypes.object
}

ContactDetails.defaultProps = {
  isLoading: false,
  localSettings: null
}

export default ContactDetails
