export const it = {
  welcome: {
    title: 'Ci siamo quasi!',
    description_p_1:
      "Il tuo profilo Helpling è stato creato correttamente. Come ultimo passaggio, verrà controllato il tuo documento d'identità per verificare le tue generalità.",
    description_p_2:
      'Helpling collabora con Onfido per effettuare una verifica sicura e veloce del tuo documento d\'identità. Cliccando sul pulsante "Procedi alla verifica", verrai indirizzato alla pagina di Onfido per caricare il tuo documento.',
    next_button: 'Procedi alla verifica'
  },
  document_selector: {
    title: 'Verifica la tua identità',
    hint: 'Scegli il tipo di documento che vuoi caricare'
  },
  capture: {
    driving_licence: {
      front: {
        title: 'Forderseite des Führerscheins',
        instructions: 'Laden Sie die Vorderseite Ihres Führerscheines über den Computer hoch',
        webcam: 'Halten Sie die Vorderseite des Führerscheins in den Rahmen. (er wird automatisch erkannt)'
      },
      back: {
        title: 'Rückseite des Führerscheins ',
        instructions: 'Laden Sie die Rückseite Ihres Führerscheins über den Computer',
        webcam: 'Halten Sie die Rückseite des Führerscheins in den Rahmen. (er wird automatisch erkannt)'
      }
    },
    national_identity_card: {
      front: {
        title: "Carta di'identità - Fronte",
        instructions: "Carica una foto del fronte della tua carta d'identità",
        webcam:
          "Posiziona il fronte della carta d'identità all'interno della cornice (verrà automaticamente riconosciuto)"
      },
      back: {
        title: "Carta di'identità - Retro",
        instructions: "Carica una foto del retro della tua carta d'identità",
        webcam:
          "Posiziona il retro della carta d'identità all'interno della cornice (verrà automaticamente riconosciuto)"
      }
    },
    passport: {
      front: {
        title: 'Passaporto con fotografia',
        instructions: 'Carica una foto della pagina del passaporto con la tua fotografia',
        webcam:
          "Posiziona la pagina del passaporto con la tua fotografia all'interno della cornice (verrà automaticamente riconosciuta)"
      }
    },
    document: {
      help: 'Problemi? Puoi anche caricare un file'
    },
    face: {
      title: 'Aggiungi una foto-profilo',
      upload_title: 'Foto-profilo',
      instructions: 'Carica una foto di te stesso',
      webcam: "Posiziona il tuo viso all'interno dello spazio ovale",
      help: "Hai dei problemi? Puoi anche caricare un'immagine che già hai",
      button: 'Fai un "selfie"'
    }
  },
  confirm: {
    document: {
      title: 'Controlla la leggibilità'
    },
    driving_licence: {
      message: 'Stellen Sie sicher, dass die Daten auf Ihrem Führerschein gut sichtbar sind, ohne Glanz und Unschärfe'
    },
    national_identity_card: {
      message:
        "Assicurati che i dati del tuo documento d'identità siano chiaramente leggibili, senza riflessi o sfocature"
    },
    passport: {
      message: 'Assicurati che i dati del tuo passaporto siano chiaramente leggibili, senza riflessi o sfocature'
    },
    face: {
      title: 'Controlla bene la foto-profilo',
      message: ''
    },
    confirm: 'Conferma',
    continue: 'Procedi',
    redo: 'Riprova'
  },
  capture_parentheses: '(oppure carica qui i dati)',
  complete: {
    message: 'Verifica completata',
    submessage: 'Grazie mille'
  },
  cross_device: {
    client_success: {
      title: 'Caricamento completato',
      sub_title: "Ora puoi procedere con l'utilizzo del sito",
      body: 'Serviranno alcuni secondi al tuo computer per ricaricarsi'
    },
    link: {
      title: 'Procedere con la verifica via smartphone',
      sub_title: 'Invieremo al tuo smartphone un link protetto valido per una sola volta',
      link_copy: { action: 'Copia', success: 'copiato' },
      button_copy: { action: 'Invia link', status: 'inviato' },
      sms_label: 'Numero di telefono',
      copy_link_label: 'Oppure copia il link:'
    },
    submit: {
      title: 'Ottimo, questo è tutto quello che ci serve',
      sub_title: 'Ora siamo pronti a verificare la tua identità',
      selfie_uploaded: 'Caricare foto-profilo',
      action: 'Avvia verifica',
      multiple_docs_uploaded: 'Caricare documenti',
      one_doc_uploaded: 'Caricare documento'
    },
    phone_number_placeholder: 'Inserisci il tuo numero di telefono',
    loading: 'Carica...',
    mobile_connected: {
      title: {
        message: 'Collegato con il tuo telefono',
        submessage: 'Non appena avrai finito, verrai diretto al prossimo passaggio'
      },
      tips: {
        item_1: 'Lascia aperta questa schermata mentre utilizzi il tuo smartphone',
        item_2: "Il tuo link per lo smartphone sarà valido solo per un'ora",
        item_3: 'Non ricaricare la pagina'
      }
    },
    mobile_notification_sent: {
      title: 'Controlla il tuo smartphone',
      submessage: 'Ti abbiamo inviato un link protetto',
      bold_message: "L'invio può durare alcuni minuti",
      tips: {
        item_1: 'Lascia aperta questa schermata mentre utilizzi il tuo smartphone',
        item_2: "Il tuo link per lo smartphone scade fra un'ora"
      },
      resend_link: 'Invia nuovamente il link'
    },
    switch_device: {
      header: 'Hai bisogno del tuo smartphone per scattare foto?',
      submessage: 'Procedi con connessione protetta sul computer'
    },
    tips: 'Consigli'
  },
  errors: {
    invalid_capture: {
      message: 'Nessun documento rilevato',
      instruction: "Per favore, assicurati che l'intero documento sia visibile nella foto"
    },
    invalid_type: { message: 'I dati non sono stati caricati', instruction: 'Prova a utilizzare un altro file' },
    unsupported_file: {
      message: 'Il tipo di file non è supportato',
      instruction: 'Per favore, usa un file .jpg. o .png.'
    },
    invalid_size: { message: 'Il file è troppo grande', instruction: 'Il file deve essere più piccolo di 10 Mb' },
    no_face: {
      message: 'Non è stato riconosciuto alcun volto',
      instruction: 'Nella foto-profilo deve essere visibile il tuo viso'
    },
    multiple_faces: {
      message: 'Sono stati riconosciuti vari volti',
      instruction: 'Nella foto-profilo deve essere visibile solo il tuo viso'
    },
    server_error: { message: 'La connessione si è interrotta', instruction: 'Per favore, prova di nuovo' },
    glare_detected: {
      message: 'Rilevato un riflesso',
      instruction: 'Tutti i dettagli devono essere chiari e leggibili'
    },
    sms_failed: {
      message: 'Si è verificato un errore',
      instruction: 'Prova a copiare sul tuo smartphone il link qui sotto'
    },
    sms_overuse: {
      message: 'Troppi tentativi di inviare di nuovo',
      instruction: 'Prova a copiare sul tuo smartphone il link qui sotto'
    },
    lazy_loading: { message: 'Si è verificato un errore durante il caricamento di un elemento' },
    invalid_number: { message: 'Controlla che il tuo numero di telefono sia corretto.' },
    generic_client_error: {
      message: 'Si è verificato un errore',
      instruction: 'È necessario riavviare la procedura di verifica sul tuo computer'
    }
  },
  passport: 'Passaporto',
  driving_licence: 'Führerschein',
  national_identity_card: "Carta d'identità",
  short_passport: 'Passaporto',
  short_driving_licence: 'Führerschein',
  short_national_identity_card: 'CdI',
  back: 'Indietro',
  cancel: 'Cancella'
}
