import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router'
import Menu from '../../containers/menu'
import notes from '../../../svg/notes-color.svg'

const DownloadAppBack = ({ location: { state: { redirect } } }) => (
  <div>
    <Menu languagesMenuEnabled />

    <div className='row'>
      <div className='small-12 large-6 large-offset-3 column descriptive-step'>
        <h2 className='text-center descriptive-step__title'>{I18n.t('download_app_back.title')}</h2>

        <div className='descriptive-step__body'>
          <div className='text-center'>
            <svg>
              <use xlinkHref={`#${notes.id}`} />
            </svg>
          </div>
          <p>{I18n.t('download_app_back.body')}</p>
        </div>

        <div className='descriptive-step__actions'>
          <Link className='button action expanded' to={redirect}>
            {I18n.t('download_app_back.continue')}
          </Link>
        </div>
      </div>
    </div>
  </div>
)

DownloadAppBack.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      redirect: PropTypes.string
    })
  })
}

export default DownloadAppBack
