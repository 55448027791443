import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Input, Button } from 'lasagna'

class FreeTextAnswerList extends Component {
  state = {
    selectedValue: '',
    showFreeText: false,
    freeText: ''
  }

  componentWillMount() {
    const selectedAnswer = this.props.answers.find(i => i.selected)

    if (!selectedAnswer) {
      return
    }

    this.setState({
      selectedValue: selectedAnswer.value,
      showFreeText: !!selectedAnswer.allowFreeText,
      freeText: selectedAnswer.freeTextValue || ''
    })
  }

  onRadioSelect = (value, allowFreeText) => {
    this.setState({
      selectedValue: value,
      showFreeText: allowFreeText
    })
  }

  setFreeText = value => {
    this.setState({
      freeText: value
    })
  }

  answerList = () =>
    this.props.answers.map(a => (
      <div className='answer answer--radio' key={a.value}>
        <input
          id={a.value}
          type='radio'
          name='radio-list'
          value={a.value}
          onChange={() => this.onRadioSelect(a.value, !!a.allowFreeText)}
          checked={this.state.selectedValue === a.value}
        />
        <label htmlFor={a.value}>{a.human}</label>
      </div>
    ))

  render() {
    const { selectedValue, showFreeText, freeText } = this.state

    return (
      <div className='answer-list'>
        {this.answerList()}
        {this.state.showFreeText && (
          <Input
            required
            name='free-text'
            label={I18n.t('eligibility.specify')}
            value={freeText}
            onChange={this.setFreeText}
          />
        )}

        <div className='answer-list__actions'>
          <Button
            disabled={selectedValue === '' || (showFreeText === true && freeText === '')}
            action
            expanded
            rounded
            screaming
            onClick={() => this.props.onContinue(selectedValue, freeText)}
          >
            {I18n.t('eligibility.continue')}
          </Button>
        </div>
      </div>
    )
  }
}

FreeTextAnswerList.propTypes = {
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      human: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      selected: PropTypes.bool,
      allowFreeText: PropTypes.bool,
      freeTextValue: PropTypes.string
    })
  ),
  onContinue: PropTypes.func
}

FreeTextAnswerList.defaultProps = {
  answers: [],
  onContinue: () => {}
}

export default FreeTextAnswerList
