import { INVALIDATE_APPLICANT, INVALIDATE_APPLICANT_FAIL } from '../constants/applicant'

export const initialState = {
  invalidating: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case INVALIDATE_APPLICANT: {
      return { invalidating: true }
    }
    case INVALIDATE_APPLICANT_FAIL: {
      return { invalidating: false }
    }
    default:
      return state
  }
}
