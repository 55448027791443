import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ProgressBar from '../shared/progress_bar'
import RegistrationComplete from './registration_complete'
import CallPreferences from './call_preferences'
import PageWithLink from './page_with_link'
import MyDocuments from '../../containers/my_documents'
import Spinner from '../../shared/components/spinner'
import {
  COMPLETE_CALL_PREFERENCES_PAGE,
  COMPLETE_DOCUMENTS_PAGE,
  COMPLETE_WELCOME_PHONE_CALL_PAGE,
  COMPLETE_EXTERNAL_DOCUMENTS_PAGE,
  COMPLETE_DISABLED_PAGE
} from '../../constants/complete'

class Index extends Component {
  renderCurrentPage() {
    const {
      currentPage,
      setCurrentPage,
      sendCallRequest,
      goToUrl,
      params,
      introductionVideoId,
      externalDocumentsPage,
      disabledReason,
      directPhoneNumber,
      score,
      country
    } = this.props

    switch (currentPage) {
      case COMPLETE_DOCUMENTS_PAGE: {
        return <MyDocuments params={params} />
      }
      case COMPLETE_CALL_PREFERENCES_PAGE: {
        return (
          <CallPreferences
            sendCallRequest={sendCallRequest}
            nextAction={() =>
              externalDocumentsPage
                ? setCurrentPage(COMPLETE_EXTERNAL_DOCUMENTS_PAGE)
                : setCurrentPage(COMPLETE_DOCUMENTS_PAGE)
            }
            goToUrl={goToUrl}
            directPhoneNumber={directPhoneNumber}
            score={score}
          />
        )
      }
      case COMPLETE_EXTERNAL_DOCUMENTS_PAGE: {
        return (
          <PageWithLink
            title={I18n.t('complete.external_documents.title')}
            description={I18n.t('complete.external_documents.description')}
            link={I18n.t('complete.external_documents.link')}
            cta={I18n.t('complete.external_documents.cta')}
          />
        )
      }
      case COMPLETE_WELCOME_PHONE_CALL_PAGE: {
        return (
          <PageWithLink
            title={I18n.t('complete.welcome_phone_call.title')}
            description={I18n.t('complete.welcome_phone_call.description')}
            link={I18n.t('complete.welcome_phone_call.link')}
            cta={I18n.t('complete.welcome_phone_call.cta')}
          />
        )
      }
      case COMPLETE_DISABLED_PAGE: {
        return (
          <PageWithLink
            title={I18n.t(`complete.${disabledReason}.title`)}
            description={I18n.t(`complete.${disabledReason}.description`)}
          />
        )
      }
      default:
        return (
          <RegistrationComplete
            nextAction={() => setCurrentPage(COMPLETE_CALL_PREFERENCES_PAGE)}
            introductionVideoId={introductionVideoId}
            country={country}
          />
        )
    }
  }

  render() {
    const { isLoading, application } = this.props

    if (isLoading) {
      return <Spinner config={{ top: '20rem' }} />
    }

    return (
      <div>
        <ProgressBar progress={application.progress} modifierClass='progress-bar--mobile' />
        {this.renderCurrentPage()}
      </div>
    )
  }
}

Index.propTypes = {
  params: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }),
  isLoading: PropTypes.bool.isRequired,
  application: PropTypes.shape({
    progress: PropTypes.number.isRequired,
    states: PropTypes.arrayOf(
      PropTypes.shape({
        identifier: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  goToUrl: PropTypes.func.isRequired,
  currentPage: PropTypes.string.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  sendCallRequest: PropTypes.func.isRequired,
  introductionVideoId: PropTypes.string,
  externalDocumentsPage: PropTypes.bool.isRequired,
  disabledReason: PropTypes.string,
  directPhoneNumber: PropTypes.string,
  score: PropTypes.number,
  country: PropTypes.string.isRequired
}

Index.defaultProps = {
  introductionVideoId: ''
}

export default Index
