import { connect } from 'react-redux'
import RightToWork from '../components/right_to_work'
import { saveRightToWork } from '../actions/right_to_work'
import { makeDispatcher } from '../utils/shared'

export default connect(
  state => ({
    isSaving: state.rightToWork.isSaving,
    selected: state.rightToWork.selected
  }),
  dispatch => ({
    saveRightToWork: makeDispatcher(dispatch, saveRightToWork)
  })
)(RightToWork)
