import PropTypes from 'prop-types'
import React, { Component } from 'react'
import OnfidoSdk from '../onfido'
import Spinner from '../../shared/components/spinner'

class Welcome extends Component {
  componentWillMount() {
    const { onfido: { status }, checkAlreadyStarted, getOnfidoData, routeParams: { userId } } = this.props

    return status !== 'upload' ? checkAlreadyStarted(userId) : getOnfidoData(userId)
  }

  render() {
    const {
      onfido: { token, loading, nationality },
      country,
      onDocumentUploadCompleted,
      routeParams: { userId }
    } = this.props

    if (loading) {
      return (
        <div className='welcome'>
          <Spinner />
        </div>
      )
    }

    return (
      <div className='welcome'>
        <div className='row'>
          <div className='small-12 medium-offset-1 medium-10 columns'>
            <OnfidoSdk
              country={country}
              nationality={nationality}
              token={token}
              onDocumentUploadCompleted={() => onDocumentUploadCompleted(userId)}
            />
          </div>
        </div>
      </div>
    )
  }
}

Welcome.propTypes = {
  onfido: PropTypes.object.isRequired,
  country: PropTypes.string,
  getOnfidoData: PropTypes.func.isRequired,
  onDocumentUploadCompleted: PropTypes.func.isRequired,
  checkAlreadyStarted: PropTypes.func.isRequired,
  routeParams: PropTypes.shape({
    userId: PropTypes.string.isRequired
  })
}

export default Welcome
