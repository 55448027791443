import { connect } from 'react-redux'
import Verticals from '../../components/post_onfido/verticals'
import { handleVerticalChange, handleCommentChange, saveVerticals } from '../../actions/post_onfido/verticals'

export default connect(
  state => ({
    isSaving: state.verticals.isSaving,
    verticals: state.verticals.verticals,
    comment: state.verticals.comment
  }),
  dispatch => ({
    saveVerticals: (verticals, comment, applicationId) => dispatch(saveVerticals(verticals, comment, applicationId)),
    handleVerticalChange: (group, item) => dispatch(handleVerticalChange(group, item)),
    handleCommentChange: value => dispatch(handleCommentChange(value))
  })
)(Verticals)
