import { push } from 'react-router-redux'
import { storeWorkingHours, updateBidDurationRange } from '../../api/availability'
import {
  HANDLE_DURATION_CHANGE,
  SET_DURATION_REQUEST,
  SET_DURATION_FAILURE,
  SET_DURATION_SUCCESS,
  HANDLE_AVAILABILITY_CHANGE,
  SET_AVAILABILITY_REQUEST,
  SET_AVAILABILITY_FAILURE,
  SET_AVAILABILITY_SUCCESS,
  SET_AVAILABILITY_VALIDATION_FAILURE
} from '../../constants/post_onfido/availability'

export const handleDurationChange = (field, value) => dispatch => {
  dispatch({ type: HANDLE_DURATION_CHANGE, field, value })
}

export const handleAvailabilityChange = (day, changes) => dispatch => {
  dispatch({ type: HANDLE_AVAILABILITY_CHANGE, day, changes })
}

export const setAvailability = (hours, duration, userId) => async dispatch => {
  dispatch({ type: SET_AVAILABILITY_REQUEST })
  dispatch({ type: SET_DURATION_REQUEST })

  // duration is stored as seconds in database, so multiplying by 3600 here
  updateBidDurationRange(duration.minDuration * 3600, duration.maxDuration * 3600)
    .then(data => {
      dispatch({
        type: SET_DURATION_SUCCESS,
        data
      })
    })
    .catch(error => {
      dispatch({
        type: SET_DURATION_FAILURE,
        error
      })
    })

  storeWorkingHours(hours)
    .then(({ data }) => {
      const valid = !data.storeWorkingHours.some(hour => hour.valid === false)

      if (valid) {
        dispatch({
          type: SET_AVAILABILITY_SUCCESS,
          hours: data.storeWorkingHours
        })
        dispatch(push(`/become-a-cleaner/${userId}/cards/start`))
      } else {
        dispatch({
          type: SET_AVAILABILITY_VALIDATION_FAILURE,
          hours: data.storeWorkingHours
        })
      }
    })
    .catch(error => {
      dispatch({ type: SET_AVAILABILITY_FAILURE, error })
    })
}
