import PropTypes from 'prop-types'
import React from 'react'

const NavigationBox = ({ title, children }) => (
  <div className='navigation-box'>
    <h2>{title}</h2>
    {children}
  </div>
)

NavigationBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default NavigationBox
