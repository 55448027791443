import { push } from 'react-router-redux'
import {
  CLOSE_ERROR_BOX,
  OPEN_ERROR_BOX,
  SUBMIT_FORM,
  SUBMIT_FORM_SUCCESS,
  SUBMIT_FORM_FAIL,
  SUCCESS_REDIRECT,
  UPDATE_FORM_DATA,
  FIELD_PASSWORD,
  FIELD_GENDER,
  FIELD_DOB_DAY,
  FIELD_DOB_MONTH,
  FIELD_DOB_YEAR,
  FIELD_NATIONALITY,
  FIELD_ADDRESS1,
  FIELD_ADDRESS2,
  FIELD_TOWN,
  FIELD_POSTCODE,
  GET_PERSONAL_DETAILS_SETTINGS,
  GET_PERSONAL_DETAILS_SETTINGS_SUCCESS,
  GET_PERSONAL_DETAILS_SETTINGS_FAIL
} from '../constants/personal_details'
import { submitFormPath, getSettingsPath } from '../api/personal_details'
import TrackingUtils from '../shared/utils/tracking_utils'

export const closeErrorBox = () => ({
  type: CLOSE_ERROR_BOX
})

export const openErrorBox = () => ({
  type: OPEN_ERROR_BOX
})

export const submitForm = (user, profileId) => ({
  types: [SUBMIT_FORM, SUBMIT_FORM_SUCCESS, SUBMIT_FORM_FAIL],
  payload: {
    request: {
      method: 'patch',
      url: submitFormPath,
      data: {
        user: {
          password: user[FIELD_PASSWORD],
          profile_attributes: {
            id: profileId,
            gender: user[FIELD_GENDER],
            'dob(3i)': user[FIELD_DOB_DAY],
            'dob(2i)': user[FIELD_DOB_MONTH],
            'dob(1i)': user[FIELD_DOB_YEAR],
            nationality: user[FIELD_NATIONALITY]
          },
          addresses_attributes: {
            0: {
              address1: user[FIELD_ADDRESS1],
              address2: user[FIELD_ADDRESS2],
              town: user[FIELD_TOWN],
              postcode: user[FIELD_POSTCODE]
            }
          }
        }
      }
    }
  }
})

export const successRedirect = url => dispatch => {
  dispatch(push(url))

  return {
    type: SUCCESS_REDIRECT
  }
}

export const updateFormData = (key, val) => ({
  type: UPDATE_FORM_DATA,
  payload: { key, val }
})

export const sendForm = () => (dispatch, getState) =>
  dispatch(submitForm(getState().personalDetails.user, getState().personalDetails.settings.profileId))
    .then(res => {
      const { currentUser } = res.payload.data.data
      const { os, isMobile, name } = getState().settings

      TrackingUtils.trackApplicant({
        mobileOs: os,
        mobileDevice: isMobile,
        country: name,
        ...currentUser
      })

      dispatch(successRedirect(getState().personalDetails.data.redirectTo))
    })
    .catch(() => {
      dispatch(openErrorBox())
      return Promise.reject(new Error())
    })

export const getSettings = () => ({
  types: [GET_PERSONAL_DETAILS_SETTINGS, GET_PERSONAL_DETAILS_SETTINGS_SUCCESS, GET_PERSONAL_DETAILS_SETTINGS_FAIL],
  payload: {
    request: {
      method: 'get',
      url: getSettingsPath
    }
  }
})
