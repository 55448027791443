import { connect } from 'react-redux'
import MyDocuments from '../components/my_documents'
import { fetchDocuments, sendFile } from '../actions/my_documents'

export default connect(
  state => ({
    myDocuments: state.myDocuments,
    os: state.settings.os,
    fileSizeValidation: state.settings.fileSizeValidation,
    isMobileApp: state.settings.isMobileApp,
    isMobile: state.settings.isMobile
  }),
  dispatch => ({
    uploadFile: (file, key) => dispatch(sendFile(file, key)),
    apiFetchDocuments: () => dispatch(fetchDocuments())
  })
)(MyDocuments)
