import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import styles from './progress.scss'

const Progress = ({ current, total }) => (
  <div className={styles.progress}>
    {Array.from({ length: total }, (v, i) => i).map(i => (
      <div
        key={i}
        className={cx(styles.item, {
          [styles.item_active]: i + 1 === current
        })}
      />
    ))}
  </div>
)

Progress.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default Progress
