const MIN_FILE_SIZE = 128000 // in bytes: 125 KB

// Check if featureFlag is true, if not, it will skip second part (size comparison)
export const isFileSizeValid = (file, featureFlag) => !featureFlag || file.size > MIN_FILE_SIZE

export const parseErrorKeys = key => (key.substring(0, 3) === 'dob' ? 'dob' : key)

export const isAnyDocumentRequired = documents => documents && !!documents.length

export const allDocumentsUploaded = documents => isAnyDocumentRequired(documents) && !!documents.every(d => d.uploaded)

// for maxValue = n it returns 1-n, if not number - returns 0
export const getRandomNumber = maxValue => (Number.isInteger(maxValue) ? Math.floor(Math.random() * maxValue) + 1 : 0)

export const staticMapUrl = ({ address, width, height, zoom }) =>
  `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAeqGcvW5VqTzXqin5PwzawLkw7Z7uOqIg&markers=${address}` +
  `&size=${width}x${height}&zoom=${zoom}`

export const directionUrl = address => `https://www.google.com/maps/dir/?api=1&destination=${address}`

export const makeDispatcher = (dispatch, fn) => (...args) => dispatch(fn(...args))

export const formatGraphQLErrors = graphQLErrors =>
  Object.entries(graphQLErrors).reduce((errors, [field, messages]) => {
    if (!!messages && messages.length > 0 && field !== '__typename') {
      const [message] = messages
      errors[field] = message // eslint-disable-line no-param-reassign
    }
    return errors
  }, {})

export const countryToAlpha2 = country =>
  ({
    UK: 'GB',
    Ireland: 'IE',
    France: 'FR',
    Germany: 'DE',
    Netherlands: 'NL',
    Australia: 'AU',
    Singapore: 'SG',
    Italy: 'IT'
  }[country])
