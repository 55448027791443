import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cx from 'classnames'
import Modal from '../../shared/components/modal'
import documentIcon from '../../../svg/document.svg'
import pendingDocument from '../../../svg/document_pending.svg'
import completeIcon from '../../../svg/complete.svg'
import plusIcon from '../../../svg/plus.svg'
import minusIcon from '../../../svg/minus.svg'
import uploadIcon from '../../../svg/upload.svg'
import TrackingUtils from '../../shared/utils/tracking_utils'
import { isFileSizeValid } from '../../utils/shared'
import DocumentWhere from './document_where'

class DocumentBox extends Component {
  state = {
    expanded: false,
    exampleVisible: false,
    helpVisible: false,
    temporaryErrorMessage: ''
  }

  handleClick = () => {
    if (this.props.approved) {
      return false
    }

    this.setState({
      expanded: !this.state.expanded,
      temporaryErrorMessage: ''
    })
    return this.props.clickHandler()
  }

  collapseBox = () => this.setState({ expanded: false, temporaryErrorMessage: '' })

  handleUpload = e => {
    const file = e.target.files && e.target.files[0]
    const { documentKey, fileSizeValidation, uploadFile } = this.props

    if (file && isFileSizeValid(file, fileSizeValidation)) {
      uploadFile(file, documentKey, fileSizeValidation)
        // TODO This has to be moved into actions, otherwise when component is
        // unmounted and this didn't finish, we get warnings in console.
        .then(ev => {
          this.setState({ temporaryErrorMessage: '' })

          return !ev.error && this.collapseBox()
        })
    } else {
      this.setState({
        temporaryErrorMessage: 'File size too small. Please upload a file larger than 125KB'
      })
    }
  }

  openExample = () => {
    this.setState({ exampleVisible: true })
  }

  closeExample = () => {
    this.setState({ exampleVisible: false })
  }

  openHelp = () => {
    TrackingUtils.trackEvent('autoTrack', {
      eventCategory: 'Provider Funnel',
      eventLabel: `with language ${I18n.locale}, in the document ${this.props.documentKey}`,
      eventAction: 'click on more information'
    })
    this.setState({ helpVisible: true })
  }

  closeHelp = buttonType => {
    TrackingUtils.trackEvent('autoTrack', {
      eventCategory: 'Provider Funnel',
      eventLabel: `with language ${I18n.locale}, in the document ${this.props.documentKey}, using ${buttonType} button`,
      eventAction: 'closed more information'
    })
    this.setState({ helpVisible: false })
  }

  createMarkup = html => ({ __html: html })

  acceptPdfFilesExceptProfilePhoto = docKey => {
    if (docKey === 'profile_photo') {
      return 'image/gif,image/jpeg,image/jpg,image/png'
    }
    if (!this.props.isIos) {
      return 'image/gif,image/jpeg,image/jpg,image/png, application/pdf'
    }
    return ''
  }

  renderHelp() {
    const { help } = this.props
    const { helpVisible } = this.state

    return (
      <div>
        <Modal closeHandler={() => this.closeHelp('close')} showModal={helpVisible}>
          <div className='row document-box__help-body'>
            <div className='small-12 medium-12 columns'>
              <span dangerouslySetInnerHTML={{ __html: help }} />
            </div>
            <div className='small-12 medium-12 columns'>
              <h1>{I18n.t('my_documents.help_box.was_it_helpful')}</h1>
            </div>
            <div className='small-6 medium-6 columns'>
              <button
                id='document-box__help-no'
                className='button btn medium expanded button-sbmt info-page__ghost-button document-box__help-button'
                onClick={() => this.closeHelp('not helpful')}
              >
                {I18n.t('my_documents.help_box.answer_no')}
              </button>
            </div>
            <div className='small-6 medium-6 columns'>
              <button
                id='document-box__help-yes'
                className='button btn medium expanded button-sbmt info-page__ghost-button document-box__help-button'
                onClick={() => this.closeHelp('helpful')}
              >
                {I18n.t('my_documents.help_box.answer_yes')}
              </button>
            </div>
          </div>
        </Modal>
        <button className='document-box__upload-example document-box__upload-example-last' onClick={this.openHelp}>
          {I18n.t('my_documents.help_box.see_more_information')}
        </button>
      </div>
    )
  }

  renderIcon = () => {
    const { uploaded, approved } = this.props

    if (!approved && !uploaded) {
      return (
        <svg className='document-box__icon'>
          <use xlinkHref={`#${documentIcon.id}`} />
        </svg>
      )
    }

    if (!approved && uploaded) {
      return (
        <svg className='document-box__icon'>
          <use xlinkHref={`#${pendingDocument.id}`} />
        </svg>
      )
    }

    return (
      <svg className='document-box__icon'>
        <use xlinkHref={`#${completeIcon.id}`} />
      </svg>
    )
  }

  render() {
    const {
      description,
      documentKey,
      name,
      uploaded,
      filename,
      exampleImage,
      help,
      approved,
      where,
      isMobileApp,
      isMobile,
      uploadIdsImageExample
    } = this.props
    const { exampleVisible, temporaryErrorMessage } = this.state
    const expanded = this.state.expanded || (uploadIdsImageExample && !isMobile)

    return (
      <div className='document-box'>
        <Modal closeHandler={this.closeExample} showModal={exampleVisible}>
          <div className='text-center'>
            <div className='modal__title'>{name}</div>
            <div className='modal__body'>
              <img src={exampleImage} alt='exampleImage' />
            </div>
            <button className='button btn action medium expanded button-sbmt' onClick={this.closeExample}>
              {I18n.t('my_documents.close_example')}
            </button>
          </div>
        </Modal>
        <button className='document-box__header' onClick={this.handleClick}>
          {this.renderIcon()}

          <div className='document-box__title-box'>
            <h4 className='document-box__title'>{name}</h4>
            <p
              className={cx('document-box__subtitle', {
                'document-box__subtitle--uploaded': uploaded
              })}
            >
              {filename || (uploaded ? I18n.t('my_documents.is_uploaded') : I18n.t('my_documents.is_not_uploaded'))}
            </p>
          </div>
          <div
            className={cx('document-box__button', {
              'document-box__button--expanded': uploaded
            })}
          >
            <svg
              className={cx('document-box__button-icon', {
                'document-box__button-icon--visible': expanded && !approved
              })}
            >
              <use xlinkHref={`#${minusIcon.id}`} />
            </svg>
            <svg
              className={cx('document-box__button-icon', {
                'document-box__button-icon--visible': !expanded && !approved
              })}
            >
              <use xlinkHref={`#${plusIcon.id}`} />
            </svg>
          </div>
        </button>
        <div
          className={cx('document-box__body', {
            'document-box__body--expanded': expanded
          })}
        >
          {uploadIdsImageExample && (
            <button onClick={this.openExample} className='document-box__example-box'>
              <span>{I18n.t('my_documents.example_title')}</span>
              <img src={exampleImage} alt='open example' />
              <small>{I18n.t('my_documents.example_enlarge')}</small>
            </button>
          )}
          <div className='document-box__desc'>
            <p className='document-box__title document-box__title--action'>{temporaryErrorMessage}</p>
            <ul className='document-box__desc-list' dangerouslySetInnerHTML={this.createMarkup(description)} />
          </div>
          <div className='document-box__upload'>
            <label htmlFor={documentKey} className='document-box__upload-wrapper'>
              {!uploaded && (
                <svg>
                  <use xlinkHref={`#${uploadIcon.id}`} />
                </svg>
              )}
              {!!uploaded && (
                <div className='document-box__upload-success'>
                  <div>{I18n.t('my_documents.upload_successfull')}</div>
                  <svg>
                    <use xlinkHref={`#${completeIcon.id}`} />
                  </svg>
                </div>
              )}
              <h4 className='document-box__upload-title'>{name}</h4>
              <p className='document-box__upload-desc'>
                <span>{I18n.t('my_documents.click_here')} </span>
                {!uploaded ? I18n.t('my_documents.upload_click') : I18n.t('my_documents.upload_change_click')}
              </p>
            </label>
            <input
              accept={this.acceptPdfFilesExceptProfilePhoto(documentKey)}
              id={documentKey}
              onChange={this.handleUpload}
              type='file'
            />
            <button onClick={this.openExample} className='document-box__upload-example'>
              {I18n.t('my_documents.see_example')}
            </button>
            {!!help && this.renderHelp()}
            {!!where && <DocumentWhere where={where} documentKey={documentKey} isMobileApp={isMobileApp} />}
          </div>
        </div>
      </div>
    )
  }
}

DocumentBox.propTypes = {
  description: PropTypes.string,
  documentKey: PropTypes.string,
  name: PropTypes.string,
  exampleImage: PropTypes.string,
  uploaded: PropTypes.bool,
  fileSizeValidation: PropTypes.bool,
  filename: PropTypes.string,
  uploadFile: PropTypes.func,
  clickHandler: PropTypes.func,
  isIos: PropTypes.bool,
  help: PropTypes.string,
  approved: PropTypes.bool,
  where: PropTypes.array,
  isMobileApp: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  uploadIdsImageExample: PropTypes.bool
}

DocumentBox.defaultProps = {
  uploaded: false,
  isIos: false,
  help: '',
  approved: false,
  where: null,
  uploadIdsImageExample: false,
  clickHandler: () => {}
}

export default DocumentBox
