import { push } from 'react-router-redux'
import {
  GET_ONFIDO_TOKEN_REQUEST,
  GET_ONFIDO_TOKEN_SUCCESS,
  GET_ONFIDO_TOKEN_FAILURE,
  START_ONFIDO_CHECK_REQUEST,
  START_ONFIDO_CHECK_SUCCESS,
  START_ONFIDO_CHECK_FAILURE,
  GET_ONFIDO_DATA,
  GET_ONFIDO_DATA_SUCCESS,
  GET_ONFIDO_DATA_FAIL
} from '../constants/onfido'
import { createOnfidoJWT, createOnfidoCheck } from '../api'
import { fetchOnfidoData } from '../api/onfido'

const getOnfidoTokenFailure = err => ({ type: GET_ONFIDO_TOKEN_FAILURE, err })

const getOnfidoTokenSuccess = data => ({ type: GET_ONFIDO_TOKEN_SUCCESS, data })

export const getOnfidoToken = () => dispatch => {
  dispatch({
    type: GET_ONFIDO_TOKEN_REQUEST
  })

  return createOnfidoJWT()
    .then(res => {
      dispatch(getOnfidoTokenSuccess(res.data))
    })
    .catch(err => {
      dispatch(getOnfidoTokenFailure(err))
    })
}

const startOnfidoCheckFailure = err => ({ type: START_ONFIDO_CHECK_FAILURE, err })

const startOnfidoCheckSuccess = data => ({ type: START_ONFIDO_CHECK_SUCCESS, data })

export const startOnfidoCheck = userId => dispatch => {
  dispatch({
    type: START_ONFIDO_CHECK_REQUEST
  })

  return createOnfidoCheck()
    .then(res => {
      dispatch(startOnfidoCheckSuccess(res.data))
      dispatch(push(`/become-a-cleaner/${userId}/experience`))
    })
    .catch(err => {
      dispatch(startOnfidoCheckFailure(err))
    })
}

export const checkAlreadyStarted = userId => dispatch => {
  dispatch(push(`/become-a-cleaner/${userId}/experience`))
}

export const getOnfidoData = userId => async dispatch => {
  dispatch({ type: GET_ONFIDO_DATA })
  try {
    const { data: { currentProvider: { nationality }, onfidoApplicant } } = await fetchOnfidoData()
    const state = onfidoApplicant ? onfidoApplicant.state : null
    dispatch({ type: GET_ONFIDO_DATA_SUCCESS, nationality, state })

    if (state === 'documents' || state === 'check') {
      dispatch(checkAlreadyStarted(userId))
    } else {
      dispatch(getOnfidoToken())
    }
  } catch (error) {
    dispatch({ type: GET_ONFIDO_DATA_FAIL, error })
  }
}
