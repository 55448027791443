import moment from 'moment-timezone'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/it'
import { GET_SETTINGS_SUCCESS, UPDATE_WELCOME_CARDS } from '../constants/settings'

export const initialState = null
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS: {
      const settings = action.payload.data.data
      const { country: { locale } } = settings

      I18n.locale = locale
      moment.locale(settings.country.momentLocale)
      moment.tz.setDefault(settings.country.timezone)

      return settings
    }
    case UPDATE_WELCOME_CARDS:
      return {
        ...state,
        welcomeCards: action.welcomeCards
      }
    default:
      return state
  }
}
