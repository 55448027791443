import { push } from 'react-router-redux'
import {
  FETCH_IDENTIFICATIONS_DOCUMENTS,
  FETCH_IDENTIFICATIONS_DOCUMENTS_SUCCESS,
  FETCH_IDENTIFICATIONS_DOCUMENTS_FAIL
} from '../constants/identifications'
import { fetchIdentificationsDocumentsPath } from '../api/identifications'

export const fetchIdentificationsDocuments = () => ({
  types: [
    FETCH_IDENTIFICATIONS_DOCUMENTS,
    FETCH_IDENTIFICATIONS_DOCUMENTS_SUCCESS,
    FETCH_IDENTIFICATIONS_DOCUMENTS_FAIL
  ],
  payload: {
    request: {
      method: 'GET',
      url: fetchIdentificationsDocumentsPath()
    }
  }
})

/**
 * fetch the list of documents and:
 * - return a list if we have more than 1 document group
 * - redirect to the first document upload if we have less document groups
 * @param  {string} userId required to build the url to redirect
 */
export const fetchIdentificationsDocumentsOrRedirect = userId => dispatch =>
  dispatch(fetchIdentificationsDocuments()).then(({ type, payload }) => {
    if (
      type === FETCH_IDENTIFICATIONS_DOCUMENTS_SUCCESS &&
      payload.data &&
      payload.data.data &&
      payload.data.data.length &&
      [...new Set(payload.data.data.map(i => i.group))].length < 2
    ) {
      dispatch(push(`/become-a-cleaner/${userId}/identifications/${payload.data.data[0].name}`))
    }
  })
