import {
  HANDLE_VERTICAL_CHANGE,
  HANDLE_COMMENT_CHANGE,
  SET_VERTICALS_REQUEST,
  SET_VERTICALS_SUCCESS,
  SET_VERTICALS_FAILURE
} from '../../constants/post_onfido/verticals'

export const initialState = {
  isSaving: false,
  verticals: {
    smallTask: {
      acceptDeliveries: false,
      groceryShopping: false,
      furnitureAssembly: false,
      cooking: false,
      dryCleaning: false,
      windowCleaning: false,
      movingHelp: false,
      snowShoveling: false,
      postHandling: false
    },
    petCare: {
      dogWalking: false,
      dogSitting: false,
      catSitting: false,
      petFeeding: false,
      fishTankCleanUp: false
    },
    gardening: {
      wateringPlants: false,
      lawnMowing: false,
      lightYardWork: false
    },
    other: {
      carWashing: false
    }
  },
  comment: ''
}

export default (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_COMMENT_CHANGE:
      return {
        ...state,
        comment: action.value
      }
    case HANDLE_VERTICAL_CHANGE: {
      const { group, item } = action
      return {
        ...state,
        verticals: {
          ...state.verticals,
          [group]: {
            ...state.verticals[group],
            [item]: !state.verticals[group][item]
          }
        }
      }
    }
    case SET_VERTICALS_REQUEST: {
      return {
        ...state,
        isSaving: true
      }
    }
    case SET_VERTICALS_SUCCESS:
    case SET_VERTICALS_FAILURE: {
      return {
        ...state,
        isSaving: false
      }
    }
    default:
      return state
  }
}
