export const de = {
  welcome: {
    title: 'Sie haben es fast geschafft!',
    description_p_1:
      'Ihr Helpling Account wurde erfolgreich erstellt. Im letzten Schritt wird nun Ihr Ausweisdokument überprüft, um Ihre Identität zu überprüfen.',
    description_p_2:
      'Für eine schnelle Verifizierung Ihres Ausweidokumentes arbeitet Helpling mit Onfido zusammen. Mit einem Klick auf "Weiter" werden Sie auf Onfido\'s Upload Seite weitergeleitet.',
    next_button: 'Weiter'
  },
  document_selector: {
    title: 'Verifizieren Sie Ihre Identität',
    hint: 'Wählen Sie die Dokumentenart, die Sie hochladen möchten'
  },
  capture: {
    driving_licence: {
      front: {
        title: 'Forderseite des Führerscheins',
        instructions: 'Laden Sie die Vorderseite Ihres Führerscheines über den Computer hoch',
        webcam: 'Halten Sie die Vorderseite des Führerscheins in den Rahmen. (er wird automatisch erkannt)'
      },
      back: {
        title: 'Rückseite des Führerscheins ',
        instructions: 'Laden Sie die Rückseite Ihres Führerscheins über den Computer',
        webcam: 'Halten Sie die Rückseite des Führerscheins in den Rahmen. (er wird automatisch erkannt)'
      }
    },
    national_identity_card: {
      front: {
        title: 'Vorderseite des Personalausweises',
        instructions: 'Laden Sie die Vorderseite Ihres Personalausweises über den Computer hoch',
        webcam: 'Halten Sie die Vorderseite des Personalausweises in den Rahmen. (er wird automatisch erkannt)'
      },
      back: {
        title: 'Rückseite des Dokuments',
        instructions: 'Laden Sie die Rückseite Ihres Personalausweises über den Computer',
        webcam: 'Halten Sie die Rückseite des Personalausweises in den Rahmen. (er wird automatisch erkannt)'
      }
    },
    passport: {
      front: {
        title: 'Reisepassseite mit Foto',
        instructions: 'Laden Sie die Reisepasseite mit Foto über den Computer hoch',
        webcam: 'Halten Sie die Reisepasseite mit Foto in den Rahmen. (er wird automatisch erkannt)'
      }
    },
    document: {
      help: 'Probleme? Sie können stattdessen auch eine Datei hochladen'
    },
    face: {
      title: 'Machen Sie ein "Selfie"',
      upload_title: 'Selfie',
      instructions: 'Laden Sie ein Selfie von Ihrem Computer',
      webcam: 'Positionieren Sie Ihr Gesicht in dem Oval',
      help: 'Haben Sie Probleme? Sie können stattdessen auch ein "Selfie" hochladen',
      button: 'Machen Sie ein "Selfie"'
    }
  },
  confirm: {
    document: {
      title: 'Kontrollieren Sie die Lesbarkeit'
    },
    driving_licence: {
      message: 'Stellen Sie sicher, dass die Daten auf Ihrem Führerschein gut sichtbar sind, ohne Glanz und Unschärfe'
    },
    national_identity_card: {
      message:
        'Stellen Sie sicher, dass die Daten auf Ihrem Personalausweis gut sichtbar sind, ohne Glanz und Unschärfe'
    },
    passport: {
      message: 'Stellen Sie sicher, dass die Daten auf Ihrem Reisepass gut sichtbar sind, ohne Glanz oder Unschärfe'
    },
    face: {
      title: 'Kotrollieren Sie das "Selfie"',
      message: ''
    },
    confirm: 'Bestätigen',
    continue: 'Fortfahren',
    redo: 'Wiederholen'
  },
  capture_parentheses: '(oder laden Sie die Datei hier hoch)',
  complete: {
    message: 'Verifizierung komplett',
    submessage: 'Vielen Dank'
  },
  cross_device: {
    client_success: {
      title: 'Upload erfolgreich',
      sub_title: 'Sie können jetzt an Ihrem Computer fortfahren',
      body: 'Ihr Computer braucht wahrscheinlich ein paar Sekunden um neu zu laden'
    },
    link: {
      title: 'Verifizierung mit dem Mobiltelefon fortsetzen',
      sub_title: 'Wir schicken Ihnen einen einmaligen geschützen Link auf Ihr Mobiltelefon',
      link_copy: { action: 'Kopie', success: 'kopiert' },
      button_copy: { action: 'Link senden', status: 'wird gesendet' },
      sms_label: 'Handynummer',
      copy_link_label: 'Kopieren Sie den Link stattdessen:'
    },
    submit: {
      title: 'Super, das ist alles was wir benötigen',
      sub_title: 'Wir sind nun bereit Ihre Identität zu prüfen',
      selfie_uploaded: '"Selfie" hochgeladen',
      action: 'Verifizierung abschicken',
      multiple_docs_uploaded: 'Dokumente hochgeladen',
      one_doc_uploaded: 'Dokument hochgeladen'
    },
    phone_number_placeholder: 'Geben Sie Ihre Telefonnummer an',
    loading: 'Lädt...',
    mobile_connected: {
      title: {
        message: 'Mit Ihrem Mobiltelefon verbunden',
        submessage: 'Sobald Sie fertig sind, werden Sie zum nächsten Schritt geleitet'
      },
      tips: {
        item_1: 'Lassen Sie dieses Fenster geöffnet während Sie Ihr Mobiltelefon nutzen',
        item_2: 'Ihr mobiler Link ist in einer Stunde ungültig',
        item_3: 'Laden Sie die Seite nicht neu'
      }
    },
    mobile_notification_sent: {
      title: 'Kontrollieren Sie Ihr Mobiltelefon',
      submessage: 'Wir haben einen geschützen Link verschickt',
      bold_message: 'Es kann ein paar Minuten dauern bis es ankommt',
      tips: {
        item_1: 'Lassen Sie das Fenster geöffnet während Sie Ihr Handy nutzen',
        item_2: 'Ihr mobiler Link läuft in 1 Stunde ab'
      },
      resend_link: 'Link erneut versenden'
    },
    switch_device: {
      header: 'Benötigen Sie Ihr Mobiltelefon um Fotos zu machen?',
      submessage: 'Fahren Sie, geschützt auf dem Computer, fort'
    },
    tips: 'Tips'
  },
  errors: {
    invalid_capture: {
      message: 'Kein Dokument erkannt',
      instruction: 'Bitte stellen Sie sicher, dass das gesamte Dokument auf dem Foto zu sehen ist'
    },
    invalid_type: { message: 'Datei wird nicht hochgeladen', instruction: 'Probieren Sie eine andere Datei zu nutzen' },
    unsupported_file: {
      message: 'Die Art des Dokuments wird nicht unterstützt',
      instruction: 'Bitte nutzen Sie eine.jpg. oder ein .png. Datei'
    },
    invalid_size: { message: 'Die Datei ist zu groß', instruction: 'Die Datei sollte kleiner als 10 MB sein' },
    no_face: { message: 'Es wurde kein Gesicht erkannt', instruction: 'Ihr Gesicht sollte im "Selfie" zu sehen sein' },
    multiple_faces: {
      message: 'Mehrere Gesichter erkannt',
      instruction: 'Nur Ihr Gesicht sollte im "Selfie" zu sehen sein'
    },
    server_error: { message: 'Verbindung ist abgebrochen', instruction: 'Bitte probieren Sie es erneut' },
    glare_detected: { message: 'Blendung entdeckt', instruction: 'Alle Details sollten klar und deutlich lesbar sein' },
    sms_failed: {
      message: 'Es ist ein Fehler aufgetreten',
      instruction: 'Kopieren Sie stattdessen den untenstehenden Link in Ihr Handy'
    },
    sms_overuse: {
      message: 'Zu viele Versuche um erneut zu senden',
      instruction: 'Kopieren Sie stattdessen den untenstehenden Link in Ihr Handy'
    },
    lazy_loading: { message: 'Es ist ein Fehler aufgetreten während die Komponente hochgeladen wurde' },
    invalid_number: { message: 'Kontrollieren Sie ob Ihre Telefonnummer korrekt ist.' },
    generic_client_error: {
      message: 'Es ist ein Fehler aufgetreten',
      instruction: 'Sie müssen den Verifizierungsprozess erneut auf Ihrem Computer starten'
    }
  },
  passport: 'Reisepass',
  driving_licence: 'Führerschein',
  national_identity_card: 'Personalausweis',
  short_passport: 'Reisepass',
  short_driving_licence: 'Führerschein',
  short_national_identity_card: 'Karte',
  back: 'zurück',
  cancel: 'stornieren'
}
