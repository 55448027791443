import {
  GET_APPLICANT_MOBILE_REQUEST,
  GET_APPLICANT_MOBILE_SUCCESS,
  OPEN_EDIT_MOBILE_MODAL,
  CLOSE_EDIT_MOBILE_MODAL,
  SET_APPLICANT_MOBILE_REQUEST,
  SET_APPLICANT_MOBILE_SUCCESS,
  SET_APPLICANT_MOBILE_FAILURE,
  CHANGE_MODAL_MOBILE,
  CHANGE_CODE,
  VERIFICATION_CODE_REQUEST,
  VERIFICATION_CODE_SUCCESS,
  VERIFICATION_CODE_FAILURE,
  VERIFY_REQUEST,
  VERIFY_FAILURE
} from '../constants/mobile_verification'

export const initialState = {
  loading: true,
  mobile: '',
  invalid: false,
  modal: {
    visible: false,
    saving: false,
    errors: {},
    mobile: ''
  },
  code: ['', '', '', ''],
  resent: false,
  resendError: false,
  allowSubmit: false,
  attempts: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICANT_MOBILE_REQUEST:
      return { ...state, loading: true }
    case GET_APPLICANT_MOBILE_SUCCESS:
      return {
        ...state,
        loading: false,
        mobile: action.mobile,
        userId: action.userId,
        modal: {
          ...state.modal,
          mobile: action.mobile
        }
      }

    case OPEN_EDIT_MOBILE_MODAL:
      return {
        ...state,
        modal: { ...state.modal, visible: true }
      }
    case CLOSE_EDIT_MOBILE_MODAL:
      return {
        ...state,
        modal: { ...state.modal, visible: false, mobile: state.mobile }
      }

    case CHANGE_MODAL_MOBILE:
      return {
        ...state,
        modal: { ...state.modal, mobile: action.mobile, errors: {} }
      }

    case SET_APPLICANT_MOBILE_REQUEST:
      return {
        ...state,
        modal: { ...state.modal, saving: true }
      }
    case SET_APPLICANT_MOBILE_SUCCESS:
      return {
        ...state,
        mobile: action.mobile,
        modal: { ...state.modal, saving: false, visible: false, mobile: action.mobile }
      }
    case SET_APPLICANT_MOBILE_FAILURE:
      return {
        ...state,
        modal: { ...state.modal, errors: action.errors }
      }

    case CHANGE_CODE:
      return {
        ...state,
        code: action.code,
        invalid: false
      }

    case VERIFY_REQUEST:
      return {
        ...state,
        allowSubmit: false,
        invalid: false,
        attempts: state.attempts + 1
      }
    case VERIFY_FAILURE:
      return {
        ...state,
        allowSubmit: true,
        invalid: true
      }

    case VERIFICATION_CODE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        allowSubmit: true,
        resent: action.resend
      }
    case VERIFICATION_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        allowSubmit: true,
        resendError: action.resend
      }

    default:
      return state
  }
}
