import { connect } from 'react-redux'
import Availability from '../../components/post_onfido/availability'
import { handleDurationChange, handleAvailabilityChange, setAvailability } from '../../actions/post_onfido/availability'
import { makeDispatcher } from '../../utils/shared'

const mapStateToProps = state => ({
  isSaving: state.availability.isSaving,
  hours: state.availability.hours,
  duration: state.availability.duration,
  canGoBack: !state.settings.featureFlags.verticalServices
})

const mapDispatchToProps = dispatch => ({
  handleDurationChange: makeDispatcher(dispatch, handleDurationChange),
  handleAvailabilityChange: makeDispatcher(dispatch, handleAvailabilityChange),
  setAvailability: makeDispatcher(dispatch, setAvailability)
})

export default connect(mapStateToProps, mapDispatchToProps)(Availability)
