import React from 'react'
import PropTypes from 'prop-types'
import Menu from '../../containers/menu'

const RightToWorkFailed = ({ router, route, country }) => {
  // prevent applicant from going back
  router.setRouteLeaveHook(route, () => false)
  const isIE = country === 'ie'

  return (
    <div className='page'>
      <Menu languagesMenuEnabled />

      <div className='row post-onfido text-left'>
        <div className='small-12 large-6 large-offset-3 column'>
          <h2>{I18n.t('right_to_work_fail.title')}</h2>
          <p>{I18n.t('right_to_work_fail.paragraph_1')}</p>
        </div>
      </div>
      <div className='row text-left'>
        <div className='small-12 large-6 large-offset-3 column'>
          <h3>{I18n.t('right_to_work_fail.subtitle')}</h3>
          {!isIE && (
            <React.Fragment>
              <p>{I18n.t('right_to_work_fail.paragraph_2')}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: I18n.t('right_to_work_fail.availability')
                }}
              />
            </React.Fragment>
          )}
          {isIE && (
            <p
              dangerouslySetInnerHTML={{
                __html: I18n.t('right_to_work_fail.paragraph_2')
              }}
            />
          )}
        </div>
      </div>
      {!isIE && (
        <React.Fragment>
          <div className='row text-left'>
            <div className='small-12 large-6 large-offset-3 column'>
              <h3>{I18n.t('right_to_work_fail.email_label')}</h3>
              <p>
                <a href={`mailto:${I18n.t('right_to_work_fail.email_value')}`}>
                  {I18n.t('right_to_work_fail.email_value')}
                </a>
              </p>
            </div>
          </div>
          <div className='row text-left'>
            <div className='small-12 large-6 large-offset-3 column'>
              <h3>{I18n.t('right_to_work_fail.phone_label')}</h3>
              <p>
                <a href={`tel:${I18n.t('right_to_work_fail.phone_value')}`}>
                  {I18n.t('right_to_work_fail.phone_value')}
                </a>
              </p>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

RightToWorkFailed.propTypes = {
  router: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired
}

export default RightToWorkFailed
