import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import PhoneNumberInput from 'react-phone-number-input'
import SmartInput from 'react-phone-number-input/smart-input'
import 'react-phone-number-input/style.css'
import { Input } from 'lasagna'
import styles from './phone_input.scss'
import { countryToAlpha2 } from '../../utils/shared'

class PhoneInput extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
    defaultCountry: PropTypes.string,
    inModal: PropTypes.bool
  }

  state = { focused: false }

  onFocus = () => {
    this.setState({ focused: true })
  }

  onBlur = () => {
    this.setState({ focused: false })
  }

  render() {
    const { label, value, onChange, error, defaultCountry, inModal, name, ...props } = this.props

    return (
      <Input
        type='tel'
        className={cx(
          styles['phone-input'],
          { [styles['phone-input--in-modal']]: inModal },
          { [styles['phone-input--focus']]: this.state.focused },
          { [styles['phone-input--error']]: error && error !== '' }
        )}
        name={name}
        label={label}
        error={error}
        value={value}
        customInput={
          <PhoneNumberInput
            inputComponent={SmartInput}
            required
            id={name}
            name={name}
            value={value}
            onChange={onChange}
            international={false}
            country={countryToAlpha2(defaultCountry) || defaultCountry}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            {...props}
          />
        }
      />
    )
  }
}

export default PhoneInput
