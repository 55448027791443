import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import Spinner from '../../shared/components/spinner'

class Identifications extends Component {
  componentWillMount() {
    const { routeParams: { userId }, fetchIdentificationsDocumentsOrRedirect } = this.props

    fetchIdentificationsDocumentsOrRedirect(userId)
  }

  renderButton = group => {
    const { routeParams: { userId }, identifications: { data } } = this.props

    return (
      <button
        key={group}
        className='answer'
        onClick={() => this.props.goToDocument(userId, data.filter(i => i.group === group)[0].name)}
      >
        {I18n.t(`identifications.${group}`)}
      </button>
    )
  }

  renderDocuments = () => {
    const { identifications: { data }, routeParams: { userId }, completeUrl } = this.props

    const groups = [...new Set(data.map(item => item.group))]
    return (
      <div className='page small-12 large-offset-3 large-6'>
        <h3 className='page__title'>
          <span>{I18n.t('identifications.question')}</span>
        </h3>
        <div className='page__wrapper'>
          <div className='answer-list'>
            {groups.map(item => this.renderButton(item))}
            <Link
              className='document-box__upload-skip-button document-box__upload-skip-button--padded'
              to={completeUrl(userId)}
            >
              {I18n.t('identifications.skip_link')}
            </Link>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { identifications: { isLoading } } = this.props

    return <div className='row'>{isLoading ? <Spinner /> : this.renderDocuments()}</div>
  }
}

Identifications.propTypes = {
  routeParams: PropTypes.shape({
    userId: PropTypes.string
  }),
  identifications: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        group: PropTypes.string
      })
    ),
    isLoading: PropTypes.bool
  }),
  fetchIdentificationsDocumentsOrRedirect: PropTypes.func.isRequired,
  goToDocument: PropTypes.func.isRequired,
  completeUrl: PropTypes.func.isRequired
}

export default Identifications
