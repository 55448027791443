import { getCountryCallingCode } from 'libphonenumber-js'
import {
  SUBMIT_CONTACTS_FORM,
  SUBMIT_CONTACTS_FORM_SUCCESS,
  SUBMIT_CONTACTS_FORM_FAIL,
  UPDATE_CONTACTS_FORM_DATA,
  GET_CONTACTS_SETTINGS_SUCCESS
} from '../constants/contact_details'

import { GET_SETTINGS_SUCCESS } from '../constants/settings'
import { countryToAlpha2 } from '../utils/shared'

import { mainAppUrl } from '../utils/urls'

export const initialState = {
  user: {
    email: '',
    firstname: '',
    lastname: '',
    mobile: '',
    postcode: '',
    terms: true,
    businessLicense: true
  },
  errors: {
    email: '',
    firstname: '',
    lastname: '',
    mobile: '',
    postcode: ''
  },
  isLoading: false,
  isErrorBoxVisible: false,
  settings: null
}

const parseErrors = errors => {
  const email =
    errors.email && errors.email.length ? errors.email[0].replace('users/sign_in', `//${mainAppUrl}/users/sign_in`) : ''
  const mobile = errors.mobile && errors.mobile.length ? errors.mobile[0] : ''

  return {
    email,
    mobile
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_CONTACTS_FORM: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SUBMIT_CONTACTS_FORM_SUCCESS: {
      return {
        ...state,
        isLoading: false
      }
    }
    case SUBMIT_CONTACTS_FORM_FAIL: {
      return {
        ...state,
        isLoading: false,
        errors: {
          ...state.errors,
          ...parseErrors(action.error.response ? action.error.response.data.errors : {})
        }
      }
    }
    case UPDATE_CONTACTS_FORM_DATA: {
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.key]: action.payload.val
        },
        errors: {
          ...state.errors,
          [action.payload.key]: ''
        }
      }
    }
    case GET_CONTACTS_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: action.payload.data.data,
        user: {
          ...state.user,
          businessLicense: !action.payload.data.data.businessLicenseCheckbox,
          terms: !action.payload.data.data.selfEmploymentCheckbox
        }
      }
    }
    case GET_SETTINGS_SUCCESS: {
      const country = countryToAlpha2(action.payload.data.data.country.name)

      return {
        ...state,
        user: {
          ...state.user,
          mobile: `+${getCountryCallingCode(country)}`
        }
      }
    }
    default:
      return state
  }
}
