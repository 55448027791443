import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router'
import debounce from 'lodash.debounce'
import Spinner from '../../shared/components/spinner'
import TrackingUtils from '../../shared/utils/tracking_utils'
import earningsBg from '../../../images/earnings-bg.png'

class Earnings extends Component {
  state = {
    weeklyAvailability: 10
  }

  componentWillMount() {
    this.props.getAverageHourlyEarnings()
    this.trackWeeklyAvailability = debounce(
      () =>
        TrackingUtils.trackEvent('autoTrack', {
          eventCategory: 'Provider Funnel',
          eventLabel: 'in my earnings page',
          eventAction: `choosen weekly availability is: ${this.state.weeklyAvailability} hours`
        }),
      300
    )
  }

  onSliderMove = e => {
    this.setState({ weeklyAvailability: e.target.value }, () => {
      this.trackWeeklyAvailability()
    })
  }

  formatCurrency = price =>
    I18n.t('earnings.price_with_currency', {
      price: parseFloat(price).toFixed(2)
    })

  render() {
    const { loading, hourlyPaidByCustomer, hourlyCleanerPayout, metro, params, selectedForFastTrack } = this.props

    if (loading) {
      return (
        <div className='earnings earnings--loading'>
          <Spinner config={{ top: '14rem' }} />
        </div>
      )
    }

    return (
      <div
        className='earnings'
        style={{
          backgroundImage: `url('${earningsBg}')`
        }}
      >
        <div className='earnings__title'>{I18n.t('earnings.intro')}</div>

        <div className='earnings__content'>
          <div className='earnings__entry'>
            <div className='earnings__label'>
              <div>{I18n.t('earnings.weekly_availability')}</div>
              <input
                className='slider'
                type='range'
                min='5'
                max='40'
                defaultValue={this.state.weeklyAvailability}
                onChange={this.onSliderMove}
              />
            </div>
            <div className='earnings__value earnings__value--big'>
              {I18n.t('earnings.hours', {
                hours: this.state.weeklyAvailability
              })}
            </div>
          </div>

          <div className='earnings__entry'>
            <div className='earnings__label'>{I18n.t('earnings.average_customer_price', { metro })}</div>
            <div className='earnings__value'>{this.formatCurrency(hourlyPaidByCustomer)}</div>
          </div>

          <div className='earnings__entry'>
            <div className='earnings__label'>{I18n.t('earnings.average_cleaner_payout')}</div>
            <div className='earnings__value'>{this.formatCurrency(hourlyCleanerPayout)}</div>
          </div>

          <div className='earnings__entry'>
            <div className='earnings__label'>{I18n.t('earnings.average_monthly_payout')}</div>
            <div className='earnings__value earnings__value--big earnings__value--green'>
              {this.formatCurrency(hourlyCleanerPayout * this.state.weeklyAvailability * 4.33)}
            </div>
          </div>
        </div>

        <Link
          className='button action expanded'
          to={
            selectedForFastTrack
              ? `/become-a-cleaner/${params.userId}/welcome`
              : `/become-a-cleaner/${params.userId}/complete`
          }
        >
          {I18n.t('earnings.continue')}
        </Link>
      </div>
    )
  }
}

Earnings.propTypes = {
  params: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }),
  hourlyPaidByCustomer: PropTypes.number.isRequired,
  hourlyCleanerPayout: PropTypes.number.isRequired,
  metro: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  getAverageHourlyEarnings: PropTypes.func.isRequired,
  selectedForFastTrack: PropTypes.bool.isRequired
}

export default Earnings
