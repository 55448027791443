export const GET_EXPERIENCE_REQUEST = 'GET_EXPERIENCE_REQUEST'
export const GET_EXPERIENCE_SUCCESS = 'GET_EXPERIENCE_SUCCESS'
export const GET_EXPERIENCE_FAILURE = 'GET_EXPERIENCE_FAILURE'

export const SET_EXPERIENCE_REQUEST = 'SET_EXPERIENCE_REQUEST'
export const SET_EXPERIENCE_FAILURE = 'SET_EXPERIENCE_FAILURE'
export const SET_EXPERIENCE_SUCCESS = 'SET_EXPERIENCE_SUCCESS'

export const SET_EXPERIENCE_TEXT_REQUEST = 'SET_EXPERIENCE_TEXT_REQUEST'
export const SET_EXPERIENCE_TEXT_FAILURE = 'SET_EXPERIENCE_TEXT_FAILURE'
export const SET_EXPERIENCE_TEXT_SUCCESS = 'SET_EXPERIENCE_TEXT_SUCCESS'

export const UPDATE_EXPERIENCE_FIELD = 'UPDATE_EXPERIENCE_FIELD'
