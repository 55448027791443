export const GET_APPLICANT_MOBILE_REQUEST = 'GET_APPLICANT_MOBILE_REQUEST'
export const GET_APPLICANT_MOBILE_SUCCESS = 'GET_APPLICANT_MOBILE_SUCCESS'
export const GET_APPLICANT_MOBILE_FAILURE = 'GET_APPLICANT_MOBILE_FAILURE'

export const OPEN_EDIT_MOBILE_MODAL = 'OPEN_EDIT_MOBILE_MODAL'
export const CLOSE_EDIT_MOBILE_MODAL = 'CLOSE_EDIT_MOBILE_MODAL'

export const SET_APPLICANT_MOBILE_REQUEST = 'SET_APPLICANT_MOBILE_REQUEST'
export const SET_APPLICANT_MOBILE_SUCCESS = 'SET_APPLICANT_MOBILE_SUCCESS'
export const SET_APPLICANT_MOBILE_FAILURE = 'SET_APPLICANT_MOBILE_FAILURE'

export const CHANGE_MODAL_MOBILE = 'CHANGE_MODAL_MOBILE'
export const CHANGE_CODE = 'CHANGE_CODE'

export const VERIFICATION_CODE_REQUEST = 'VERIFICATION_CODE_REQUEST'
export const VERIFICATION_CODE_SUCCESS = 'VERIFICATION_CODE_SUCCESS'
export const VERIFICATION_CODE_FAILURE = 'VERIFICATION_CODE_FAILURE'

export const VERIFY_REQUEST = 'VERIFY_REQUEST'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAILURE = 'VERIFY_FAILURE'
