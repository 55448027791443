import { getPayload } from '../api/action_payload'

import {
  FETCH_QUESTION,
  FETCH_QUESTION_SUCCESS,
  FETCH_QUESTION_FAIL,
  SELECT_ANSWER,
  SUBMIT_ANSWER,
  SUBMIT_ANSWER_SUCCESS,
  SUBMIT_ANSWER_FAIL,
  CHANGE_TEXT_FIELD_ANSWER
} from '../constants/eligibility'

export const initialState = {
  data: {
    question: {
      answers: [],
      humanSubtitle: '',
      humanDescription: '',
      humanTitle: '',
      key: '',
      textAnswer: ''
    },
    answeredQuestionsTotal: 0,
    questionsTotal: 0,
    previousQuestion: '',
    nextQuestion: ''
  },
  isLoading: false
}

const updateAnswer = (state = {}, action) => {
  switch (action.type) {
    case SELECT_ANSWER: {
      if (state.value === action.answerValue) {
        return {
          ...state,
          selected: true
        }
      }
      return {
        ...state,
        selected: false
      }
    }
    default:
      return state
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ANSWER: {
      const answers = state.data.question.answers
      return {
        isLoading: false,
        data: {
          ...state.data,
          question: {
            ...state.data.question,
            answers: [...answers.map(a => updateAnswer(a, action))]
          }
        }
      }
    }
    case FETCH_QUESTION: {
      return {
        ...state,
        isLoading: true
      }
    }
    case FETCH_QUESTION_SUCCESS: {
      return {
        ...state,
        ...getPayload(state, action),
        isLoading: false
      }
    }
    case FETCH_QUESTION_FAIL: {
      return {
        ...state,
        isLoading: false
      }
    }
    case SUBMIT_ANSWER: {
      return {
        ...state,
        isLoading: true
      }
    }
    case SUBMIT_ANSWER_SUCCESS: {
      return {
        ...state,
        isLoading: false
      }
    }
    case SUBMIT_ANSWER_FAIL: {
      return {
        ...state,
        isLoading: false
      }
    }
    case CHANGE_TEXT_FIELD_ANSWER: {
      return {
        ...state,
        data: {
          ...state.data,
          question: {
            ...state.data.question,
            textAnswer: action.textAnswer
          }
        }
      }
    }
    default:
      return state
  }
}
