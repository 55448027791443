import PropTypes from 'prop-types'
import React from 'react'
import ListItemWithIcon from './list_item_with_icon'
import complete from '../../../svg/complete.svg'
import call from '../../../svg/call.svg'
import pending from '../../../svg/pending.svg'
import dollar from '../../../svg/dollar.svg'
import documentDark from '../../../svg/document_dark.svg'
import {
  COMPLETE_CALL_PREFERENCES_PAGE,
  COMPLETE_DOCUMENTS_PAGE,
  COMPLETE_WELCOME_PHONE_CALL_PAGE,
  COMPLETE_EXTERNAL_DOCUMENTS_PAGE
} from '../../constants/complete'

const StateListItem = ({ identifier, state, currentPage, callPreferencesSet, disabledReason }) => {
  const stateMap = {
    registration: {
      icon: complete.id
    },
    phone_interview: {
      icon: call.id
    },
    documents: {
      icon: documentDark.id
    },
    welcome_phone_call: {
      icon: call.id
    },
    live: {
      icon: dollar.id
    }
  }

  const getExtraClasses = () => {
    const extraClasses = []

    if (disabledReason) {
      return ['list-item--pending']
    }

    if (state === 'completed') {
      extraClasses.push('list-item--completed')
    }

    if (identifier === 'phone_interview' && currentPage === COMPLETE_CALL_PREFERENCES_PAGE) {
      extraClasses.push('list-item--active')
    }

    if (identifier === 'documents' && currentPage === COMPLETE_DOCUMENTS_PAGE) {
      extraClasses.push('list-item--active')
    }

    if (identifier === 'documents' && currentPage === COMPLETE_EXTERNAL_DOCUMENTS_PAGE) {
      extraClasses.push('list-item--active')
    }

    if (identifier === 'phone_interview' && callPreferencesSet) {
      extraClasses.push('list-item--pending')
    }

    if (identifier === 'welcome_phone_call' && currentPage === COMPLETE_WELCOME_PHONE_CALL_PAGE) {
      extraClasses.push('list-item--pending')
    }

    return extraClasses
  }

  const getProps = () => {
    let icon = stateMap[identifier].icon

    if (state === 'completed') {
      icon = complete.id
    } else if (identifier === 'phone_interview' && callPreferencesSet) {
      icon = pending.id
    } else if (identifier === 'welcome_phone_call' && currentPage === COMPLETE_WELCOME_PHONE_CALL_PAGE) {
      icon = pending.id
    }

    return {
      icon: `#${icon}`,
      text: I18n.t(`complete.progress.${identifier}.title`),
      extraClasses: getExtraClasses()
    }
  }

  return <ListItemWithIcon {...getProps()} />
}

StateListItem.propTypes = {
  identifier: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired,
  callPreferencesSet: PropTypes.bool.isRequired,
  disabledReason: PropTypes.string
}

export default StateListItem
