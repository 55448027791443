import PropTypes from 'prop-types'
import React from 'react'
import Modal from '../../shared/components/modal'

const DocumentExample = ({ isVisible, exampleImage, close }) => (
  <Modal closeHandler={close} showModal={isVisible && exampleImage !== ''}>
    <div className='text-center'>
      <div className='modal__body'>
        <img src={exampleImage} alt='exampleImage' />
      </div>
      <button className='button btn action medium expanded button-sbmt modal-button' onClick={close}>
        {I18n.t('my_documents.close_example')}
      </button>
    </div>
  </Modal>
)

DocumentExample.propTypes = {
  isVisible: PropTypes.bool,
  exampleImage: PropTypes.string,
  close: PropTypes.func.isRequired
}

DocumentExample.defaultProps = {
  exampleImage: ''
}

export default DocumentExample
