import { createLogger } from 'redux-logger'
import { applyMiddleware, compose, createStore } from 'redux'
import axios from 'axios'
import { multiClientMiddleware } from 'redux-axios-middleware'
import { routerMiddleware } from 'react-router-redux'
import { browserHistory } from 'react-router'
import thunk from 'redux-thunk'
import rootReducer from './src/reducers'
import { apiUrl } from './src/utils/urls'

const clients = {
  default: {
    client: axios.create({
      baseURL: `//${apiUrl}/api/v1/`,
      responseType: 'json',
      withCredentials: true
    })
  },
  custom: {
    client: axios.create({
      baseURL: `//${apiUrl}/`,
      responseType: 'json',
      withCredentials: true
    })
  }
}

let middleware = [routerMiddleware(browserHistory), thunk, multiClientMiddleware(clients)]

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({ collapsed: () => true })

  middleware = [...middleware, logger]
}

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
/* eslint-enable */

/**
 * Reference: http://redux.js.org/docs/api/createStore.html#createstorereducer-preloadedstate-enhancer
 */
export default (initialState = {}) => {
  const store = createStore(
    rootReducer,
    { ...rootReducer, ...initialState },
    composeEnhancers(applyMiddleware(...middleware))
  )

  if (module.hot) {
    module.hot.accept('./src/reducers', () => {
      /* eslint-disable global-require */
      const nextRootReducer = require('./src/reducers')
      /* eslint-enable */
      store.replaceReducer(nextRootReducer.default)
    })
  }

  return store
}
