import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { Button } from 'lasagna'
import Page from '../shared/page'
import VerticalsGroup from './verticals_group'

const Verticals = ({
  verticals,
  comment,
  handleVerticalChange,
  handleCommentChange,
  saveVerticals,
  routeParams: { userId },
  isSaving
}) => (
  <Page isLoading={isSaving} progress={70}>
    <div className='row services'>
      <div className='small-12 large-6 large-offset-3 column'>
        <p>{I18n.t('verticals.title')}</p>
        {Object.keys(verticals).map(key => (
          <VerticalsGroup key={key} handleVerticalChange={handleVerticalChange} verticals={verticals} group={key} />
        ))}
        <div>
          <p className='text-left'>{I18n.t('verticals.summary.description')}:</p>
          <textarea rows={3} onChange={e => handleCommentChange(e.currentTarget.value)} value={comment} />
        </div>
      </div>
      <div className='small-12 large-6 large-offset-3 column'>
        <Button
          action
          expanded
          rounded
          screaming
          disabled={false}
          onClick={() => saveVerticals(verticals, comment, userId)}
        >
          {I18n.t('services.next')}
        </Button>
        <Link className='back-button' to={`/become-a-cleaner/${userId}/services`}>
          &lt; {I18n.t('availability.back')}
        </Link>
      </div>
    </div>
  </Page>
)

Verticals.propTypes = {
  verticals: PropTypes.object.isRequired,
  comment: PropTypes.string.isRequired,
  handleVerticalChange: PropTypes.func.isRequired,
  handleCommentChange: PropTypes.func.isRequired,
  saveVerticals: PropTypes.func.isRequired,
  routeParams: PropTypes.shape({
    userId: PropTypes.string
  }),
  isSaving: PropTypes.bool.isRequired
}

export default Verticals
