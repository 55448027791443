import { connect } from 'react-redux'
import { getQuestion, sendAnswer, changeTextFieldAnswer } from '../actions/eligibility'
import Eligibility from '../components/eligibility'

const mapStateToProps = state => ({
  question: state.eligibility.data.question,
  country: state.settings.country.locale,
  isLoading: state.eligibility.isLoading,
  questionsTotal: state.eligibility.data.questionsTotal,
  answeredQuestionsTotal: state.eligibility.data.answeredQuestionsTotal,
  previousQuestion: state.eligibility.data.previousQuestion,
  nextQuestion: state.eligibility.data.nextQuestion
})

const mapDispatchToProps = dispatch => ({
  apiGetQuestion: question => dispatch(getQuestion(question)),
  apiSendAnswer: (req, nextQuestion) => dispatch(sendAnswer(req, nextQuestion)),
  onTextFieldAnswerChange: textAnswer => dispatch(changeTextFieldAnswer(textAnswer))
})

export default connect(mapStateToProps, mapDispatchToProps)(Eligibility)
