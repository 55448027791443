import PropTypes from 'prop-types'
import React from 'react'
import Header from '../shared/header'
import YoutubeVideo from '../shared/youtube_video'
import conversation from '../../../svg/conversation.svg'
import TrackingUtils from '../../shared/utils/tracking_utils'

const RegistrationComplete = ({ nextAction, introductionVideoId, country }) => (
  <div className='text-center registration-complete'>
    <Header title={I18n.t('complete.registration.title')} description={I18n.t('complete.registration.description')} />
    <div className='align-center registration-complete__image'>
      {!!introductionVideoId && (
        <div className='large-12 small-12'>
          <YoutubeVideo videoId={introductionVideoId} description='Introduction video on complete page' />
        </div>
      )}
      {!introductionVideoId && (
        <div className='large-12 small-12'>
          <svg>
            <use xlinkHref={`#${conversation.id}`} />
          </svg>
        </div>
      )}
    </div>
    {!!introductionVideoId && (
      <div className='align-center'>
        <p>{I18n.t('complete.registration.phone_call_title')}</p>
      </div>
    )}
    <div className='align-center'>
      <div className='large-5 small-12'>
        {country === 'fr' ? (
          <a
            className='button btn medium expanded button-sbmt action'
            href='https://helplingpartner.typeform.com/to/ha9KsG'
          >
            {I18n.t('complete.registration.set_call_preferences')}
          </a>
        ) : (
          <button
            onClick={() => {
              TrackingUtils.trackEvent('autoTrack', {
                eventCategory: 'Provider Funnel',
                eventLabel: 'in complete page, under the video',
                eventAction: 'click on set call preferences'
              })
              return nextAction()
            }}
            className='button btn medium expanded button-sbmt action'
          >
            {I18n.t('complete.registration.set_call_preferences')}
          </button>
        )}
      </div>
    </div>
  </div>
)

RegistrationComplete.propTypes = {
  nextAction: PropTypes.func.isRequired,
  introductionVideoId: PropTypes.string,
  country: PropTypes.string.isRequired
}

RegistrationComplete.defaultProps = {
  introductionVideoId: ''
}

export default RegistrationComplete
