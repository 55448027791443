import { connect } from 'react-redux'
import Card from '../components/cards'
import { updateWelcomeCard } from '../actions/cards'

export default connect(
  state => ({
    welcomeCards: state.settings.welcomeCards
  }),
  dispatch => ({
    updateWelcomeCard: (card, userId) => dispatch(updateWelcomeCard(card, userId))
  })
)(Card)
