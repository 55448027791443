import PropTypes from 'prop-types'
import React from 'react'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { Router, Route } from 'react-router'
import ContactDetails from './src/containers/contact_details'
import MobileVerification from './src/containers/mobile_verification'
import Eligibility from './src/containers/eligibility'
import PersonalDetails from './src/containers/personal_details'
import Complete from './src/containers/complete'
import Earnings from './src/containers/earnings'
import Welcome from './src/containers/welcome'
import Experience from './src/containers/post_onfido/experience'
import ExperienceText from './src/containers/post_onfido/experience_text'
import Verticals from './src/containers/post_onfido/verticals'
import Identifications from './src/containers/identifications'
import Document from './src/containers/document'
import QualifiedLead from './src/containers/qualified_lead'
import Services from './src/containers/post_onfido/services'
import Availability from './src/containers/post_onfido/availability'
import CallBack from './src/components/post_onfido/call_back'
import RightToWork from './src/containers/right_to_work'
import RightToWorkFailed from './src/containers/right_to_work_failed'
import DownloadApp from './src/components/download-app'
import DownloadAppBack from './src/components/download-app/back'
import Cards from './src/containers/cards'
import End from './src/containers/end'
import Index from './src/containers'
import './styles/index.scss'

class Application extends React.Component {
  render() {
    const { store, history } = this.props
    const routes = [
      <Route component={Index}>
        <Route path='become-a-cleaner/new' component={ContactDetails} />
        <Route path='become-a-cleaner/:userId/mobile_verification' component={MobileVerification} />
        <Route path='become-a-cleaner/:userId/eligibility' component={Eligibility} />
        <Route path=':country/become-a-cleaner/:userId/eligibility' component={Eligibility} />
        <Route path='become-a-cleaner/:userId/personal_details' component={PersonalDetails} />
        <Route component={QualifiedLead}>
          <Route path='become-a-cleaner/:userId/complete' component={Complete} />
          <Route path='become-a-cleaner/:userId/earnings' component={Earnings} />,
          <Route path='become-a-cleaner/:userId/welcome' component={Welcome} />
        </Route>,
        <Route path='become-a-cleaner/:userId/identifications' component={Identifications} />
        <Route path='become-a-cleaner/:userId/identifications/:document_id' component={Document} />
        <Route path='become-a-cleaner/:userId/experience' component={Experience} />
        <Route path='become-a-cleaner/:userId/experience-text' component={ExperienceText} />
        <Route path='become-a-cleaner/:userId/services' component={Services} />
        <Route path='become-a-cleaner/:userId/verticals' component={Verticals} />
        <Route path='become-a-cleaner/:userId/availability' component={Availability} />
        <Route path='become-a-cleaner/:userId/right_to_work' component={RightToWork} />
        <Route path='become-a-cleaner/:userId/right_to_work_fail' component={RightToWorkFailed} />
        <Route path='become-a-cleaner/:userId/cards/end' component={End} />
        <Route path='become-a-cleaner/:userId/cards(/:card)' component={Cards} />
        <Route path='become-a-cleaner/:userId/call_back' component={CallBack} />
        <Route path='become-a-cleaner/:userId/download_app' component={DownloadApp} />
        <Route path='become-a-cleaner/:userId/download_app_back' component={DownloadAppBack} />
      </Route>
    ]

    if (!this.routes) {
      this.routes = routes
    }

    return (
      <Provider store={store}>
        <CookiesProvider>
          <Router onUpdate={() => window.scrollTo(0, 0)} history={history} routes={this.routes} />
        </CookiesProvider>
      </Provider>
    )
  }
}

Application.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default Application
