import PropTypes from 'prop-types'
import React, { Component } from 'react'
import cx from 'classnames'
import { Link } from 'react-router'
import TrackingUtils from '../../shared/utils/tracking_utils'
import infoIcon from '../../../svg/info.svg'
import rightToWorkSvg from '../../../svg/right_to_work_scan.svg'
import passportSvg from '../../../svg/passport_scan.svg'
import idFrontSvg from '../../../svg/id_front_scan.svg'
import idBackSvg from '../../../svg/id_back_scan.svg'
import edit from '../../../svg/edit.svg'
import DocumentExample from './document_example'
import { UPLOAD_DOCUMENT_FAIL } from '../../constants/my_documents'

import { isFileSizeValid } from '../../utils/shared'

class DocumentBox extends Component {
  state = {
    imageFile: null,
    imageBlob: null,
    isImageSelected: false,
    exampleVisible: false,
    temporaryErrorMessage: ''
  }

  componentWillUnmount() {
    return this.state.imageBlob && window.URL.revokeObjectURL(this.state.imageBlob)
  }

  isLoading = () => this.props.myDocuments.isUploading

  handleUpload = () => {
    const { documentKey, uploadFile, goToNextStep } = this.props

    if (this.state.imageFile) {
      uploadFile(this.state.imageFile, documentKey).then(({ type }) => {
        if (type === UPLOAD_DOCUMENT_FAIL) {
          return null
        }

        TrackingUtils.trackEvent('uploadedDocument', {
          document: documentKey
        })

        return goToNextStep()
      })
    }
  }

  handleSelect = e => {
    const { documentKey, fileSizeValidation } = this.props
    const file = e.target.files && e.target.files[0]

    if (file && isFileSizeValid(file, fileSizeValidation)) {
      TrackingUtils.trackEvent('uploadedDocument', {
        document: documentKey
      })

      this.setState({
        imageFile: file,
        imageBlob: window.URL.createObjectURL(file),
        isImageSelected: true,
        temporaryErrorMessage: ''
      })
    } else {
      this.setState({
        temporaryErrorMessage: 'File size too small. Please upload a file larger than 125KB'
      })
    }
  }

  openExample = () => {
    this.setState({ exampleVisible: true })
  }

  closeExample = () => {
    this.setState({ exampleVisible: false })
  }

  renderButtons = () =>
    this.state.isImageSelected ? (
      <button
        id='button-photo-upload'
        className='button btn action medium expanded button-sbmt'
        onClick={this.handleUpload}
      >
        {I18n.t(`identifications.${this.props.documentKey}.button.continue`)}
      </button>
    ) : (
      <label
        id='label-photo-select'
        className='button btn action medium expanded button-sbmt'
        htmlFor={this.props.documentKey}
      >
        {I18n.t(`identifications.${this.props.documentKey}.button.upload`)}
      </label>
    )

  render() {
    const { completeUrl, documentKey, myDocuments, userId } = this.props
    const exampleImage = !myDocuments.data.filter(i => i.key === documentKey).length
      ? ''
      : myDocuments.data.filter(i => i.key === documentKey)[0].exampleImage
    const icons = {
      right_to_work_scan: rightToWorkSvg,
      passport_scan: passportSvg,
      id_front_scan: idFrontSvg,
      id_back_scan: idBackSvg
    }

    return (
      <div
        className={cx('row', 'info-page', {
          'info-page--loading': this.isLoading()
        })}
      >
        <div className='identifications small-12 large-offset-3 large-6 columns'>
          <div className='identifications__box identifications__box--header'>
            <h2 className='info-page__title text-center identifications__title'>
              {I18n.t(`identifications.${documentKey}.header`)}
            </h2>
          </div>

          <div className='identifications__box'>
            <div
              className={cx('document-box__image', {
                hide: !this.state.isImageSelected
              })}
            >
              <div className='document-box__image-wrapper'>
                <img className='document-box__image-inner' src={this.state.imageBlob} alt='profile' />
              </div>
              <label htmlFor={documentKey} className='document-box__edit-icon'>
                <svg viewBox='0 0 14 14'>
                  <use xlinkHref={`#${edit.id}`} />
                </svg>
              </label>
            </div>
            <div
              className={cx('document-box__body document-box__body--expanded document-box__body--wide', {
                hide: this.state.isImageSelected
              })}
            >
              <div className='document-box__upload'>
                <label htmlFor={documentKey} className='document-box__upload-wrapper'>
                  <svg>
                    <use xlinkHref={`#${icons[documentKey].id}`} />
                  </svg>
                  <h4 className='document-box__upload-title'>{I18n.t(`identifications.${documentKey}.title`)}</h4>
                  <p className='document-box__upload-desc'>{I18n.t(`identifications.${documentKey}.subtitle`)}</p>
                </label>
                <input
                  name={documentKey}
                  id={documentKey}
                  accept='image/gif,image/jpeg,image/jpg,image/png'
                  onChange={this.handleSelect}
                  type='file'
                />
              </div>
            </div>

            <div
              className='info-page__description'
              dangerouslySetInnerHTML={{
                __html: I18n.t(`identifications.${documentKey}.description`)
              }}
            />

            {this.state.temporaryErrorMessage && (
              <p className='document-box__title document-box__title--action'>{this.state.temporaryErrorMessage}</p>
            )}

            <div className='info-page__description info-page__description--small-font'>
              <a role='button' className='document-box__upload-example' onClick={this.openExample}>
                {I18n.t(`identifications.${documentKey}.button.example`)}
                <svg viewBox='0 0 14 14'>
                  <use xlinkHref={`#${infoIcon.id}`} />
                </svg>
              </a>
            </div>
          </div>

          <div className='identifications__box identifications__box--footer'>
            <Link
              onClick={() => {
                TrackingUtils.trackEvent('autoTrack', {
                  eventCategory: 'Provider Funnel',
                  eventLabel: `in ${documentKey}`,
                  eventAction: 'click on skip'
                })
              }}
              className='document-box__upload-skip-button'
              to={completeUrl(userId)}
            >
              {I18n.t('identifications.skip_link')}
            </Link>
            <div className='identifications__actions'>{this.renderButtons()}</div>
          </div>
        </div>

        <DocumentExample close={this.closeExample} isVisible={this.state.exampleVisible} exampleImage={exampleImage} />
      </div>
    )
  }
}

DocumentBox.propTypes = {
  userId: PropTypes.string.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  completeUrl: PropTypes.func.isRequired,
  fileSizeValidation: PropTypes.bool,
  myDocuments: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string,
        key: PropTypes.string,
        name: PropTypes.string,
        uploaded: PropTypes.bool,
        exampleImage: PropTypes.string
      })
    ),
    isUploading: PropTypes.bool
  }),
  uploadFile: PropTypes.func.isRequired,
  documentKey: PropTypes.string.isRequired
}

export default DocumentBox
