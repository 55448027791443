import {
  FETCH_IDENTIFICATIONS_DOCUMENTS,
  FETCH_IDENTIFICATIONS_DOCUMENTS_SUCCESS,
  FETCH_IDENTIFICATIONS_DOCUMENTS_FAIL
} from '../constants/identifications'

export const initialState = {
  data: [],
  isLoading: true
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IDENTIFICATIONS_DOCUMENTS: {
      return {
        ...state,
        isLoading: true
      }
    }
    case FETCH_IDENTIFICATIONS_DOCUMENTS_FAIL: {
      return {
        ...state,
        isLoading: false
      }
    }
    case FETCH_IDENTIFICATIONS_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data.data,
        isLoading: false
      }
    }
    default:
      return state
  }
}
