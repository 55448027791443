import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getAppSettings } from '../actions/settings'
import Spinner from '../shared/components/spinner'

class StepOne extends React.Component {
  componentDidMount() {
    this.props.getSettings(this.props.location.pathname)
  }

  render() {
    const { settings, children } = this.props

    return settings ? children : <Spinner config={{ top: '250px' }} />
  }
}

const mapStateToProps = state => ({
  settings: state.settings
})

const mapDispatchToProps = dispatch => ({
  getSettings: pathname => {
    dispatch(getAppSettings(pathname))
  }
})

StepOne.propTypes = {
  settings: PropTypes.object,
  getSettings: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(StepOne)
