import { connect } from 'react-redux'
import Services from '../../components/post_onfido/services'
import { saveServices, handleServiceChange } from '../../actions/post_onfido/services'

export default connect(
  state => ({
    isSaving: state.services.isSaving,
    selectedServices: state.services.selectedServices
  }),
  dispatch => ({
    saveServices: (selectedServices, userId) => dispatch(saveServices(selectedServices, userId)),
    handleServiceChange: (service, value) => dispatch(handleServiceChange(service, value))
  })
)(Services)
