import gql from 'graphql-tag'
import client from './gql'

export const updateWelcomeCard = card => {
  const mutation = gql`
    mutation updateWelcomeCard($card: String) {
      updateWelcomeCard(card: $card) {
        success
      }
    }
  `

  return client.mutate({ mutation, variables: { card } })
}

export const finishOnboarding = () => {
  const mutation = gql`
    mutation finishOnboarding {
      finishOnboarding {
        success
      }
    }
  `

  return client.mutate({ mutation })
}
