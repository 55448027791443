import { replace } from 'react-router-redux'
import {
  SET_PREFERRED_LANGUAGE,
  SET_PREFERRED_LANGUAGE_SUCCESS,
  SET_PREFERRED_LANGUAGE_FAIL,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE
} from '../constants/settings'
import TrackingUtils from '../shared/utils/tracking_utils'

const patchPreferredLanguage = language => ({
  types: [SET_PREFERRED_LANGUAGE, SET_PREFERRED_LANGUAGE_SUCCESS, SET_PREFERRED_LANGUAGE_FAIL],
  payload: {
    request: {
      method: 'PATCH',
      url: 'provider/profile/set_preferred_language',
      data: {
        preferred_language: language
      }
    }
  }
})

export const setPreferredLanguage = language => dispatch =>
  dispatch(patchPreferredLanguage(language)).then(() => window.location.reload())

export const fetchAppSettings = () => ({
  types: [GET_SETTINGS_REQUEST, GET_SETTINGS_SUCCESS, GET_SETTINGS_FAILURE],
  payload: {
    request: {
      method: 'get',
      url: 'applicant/settings',
      params: {}
    }
  }
})

export const getAppSettings = pathname => dispatch =>
  dispatch(fetchAppSettings(pathname)).then(res => {
    if (!res.payload.data) {
      dispatch({ type: GET_SETTINGS_FAILURE })
    }

    const {
      redirect,
      userId,
      os,
      isMobile,
      isMobileApp,
      country: { name },
      currentUser,
      applicationId
    } = res.payload.data.data

    TrackingUtils.trackApplicant({
      mobileOs: os,
      mobileDevice: isMobile,
      country: name,
      ...currentUser
    })

    if (!!redirect && (pathname !== '/become-a-cleaner/new' || userId)) {
      if (redirect.startsWith('//') || redirect.startsWith('http')) {
        window.location.replace(redirect)
      } else {
        if (currentUser.userAccountType === 'provider' && isMobileApp) {
          dispatch(
            replace({
              pathname: `/become-a-cleaner/${applicationId}/download_app_back`,
              state: { redirect }
            })
          )
        } else {
          dispatch(replace(redirect))
        }
      }
    }
  })

export default setPreferredLanguage
