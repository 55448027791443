import gql from 'graphql-tag'
import client from './gql'

export const fetchOnfidoData = () => {
  const query = gql`
    query applicantFetchOnfidoData {
      currentProvider {
        nationality
      }
      onfidoApplicant {
        state
      }
    }
  `

  return client.query({ query })
}
