import {
  HANDLE_DURATION_CHANGE,
  HANDLE_AVAILABILITY_CHANGE,
  SET_AVAILABILITY_REQUEST,
  SET_AVAILABILITY_FAILURE,
  SET_AVAILABILITY_SUCCESS,
  SET_AVAILABILITY_VALIDATION_FAILURE
} from '../../constants/post_onfido/availability'

export const initialState = {
  duration: {
    minDuration: 2,
    maxDuration: 12
  },
  isSaving: false,
  hours: [
    { day: 0, from: '7:00', to: '21:00', available: true, valid: true }, // Sunday
    { day: 1, from: '7:00', to: '21:00', available: true, valid: true },
    { day: 2, from: '7:00', to: '21:00', available: true, valid: true },
    { day: 3, from: '7:00', to: '21:00', available: true, valid: true },
    { day: 4, from: '7:00', to: '21:00', available: true, valid: true },
    { day: 5, from: '7:00', to: '21:00', available: true, valid: true },
    { day: 6, from: '7:00', to: '21:00', available: true, valid: true }
  ]
}

// eslint-disable-next-line no-underscore-dangle
const normalizeTime = time => (time._isAMomentObject ? time.format('HH:mm') : time)

const applyChanges = (hour, changes) => {
  const newHour = Object.assign({}, hour, changes)
  newHour.from = normalizeTime(newHour.from)
  newHour.to = normalizeTime(newHour.to)
  newHour.valid = true
  return newHour
}

export default (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_DURATION_CHANGE: {
      const { field, value } = action
      return {
        ...state,
        duration: {
          ...state.duration,
          [field]: value
        }
      }
    }
    case HANDLE_AVAILABILITY_CHANGE: {
      return {
        ...state,
        hours: state.hours.map((day, index) => (index === action.day ? applyChanges(day, action.changes) : day))
      }
    }
    case SET_AVAILABILITY_REQUEST: {
      return {
        ...state,
        isSaving: true
      }
    }
    case SET_AVAILABILITY_FAILURE:
    case SET_AVAILABILITY_SUCCESS: {
      return { ...state, isSaving: false }
    }
    case SET_AVAILABILITY_VALIDATION_FAILURE: {
      const validation = action.hours.reduce((acc, hour) => {
        acc[hour.day] = hour.valid
        return acc
      }, {})

      return {
        ...state,
        hours: state.hours.map((day, index) => Object.assign({}, day, { valid: validation[index] })),
        isSaving: false
      }
    }
    default:
      return state
  }
}
