import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import RcSlider from 'rc-slider'
import TimePicker from 'rc-time-picker'
import { Button } from 'lasagna'
import cx from 'classnames'
import { Link } from 'react-router'
import Page from '../shared/page'
import durationSvg from '../../../svg/duration.svg'

const timePickerAddon = () => (
  <div className='rc-time-picker-panel-labels'>
    <span>HR</span>
    <span>MIN</span>
  </div>
)

const { createSliderWithTooltip } = RcSlider
const Range = createSliderWithTooltip(RcSlider.Range)

// TODO: fix the code so this is unnecessary:
const Availability = ({
  isSaving,
  hours,
  duration,
  handleDurationChange,
  handleAvailabilityChange,
  setAvailability,
  params: { userId },
  canGoBack
}) => (
  <Page progress={80}>
    <div className='row post-onfido'>
      <div className='small-12 large-6 large-offset-3 column'>
        <h3 className='page__title'>
          <span>{I18n.t('availability.title')}</span>
          <br />
          <small>{I18n.t('availability.subtitle')}</small>
        </h3>
        <div className='availability-duration'>
          <div className='availability-duration__content'>
            <svg>
              <use xlinkHref={`#${durationSvg.id}`} />
            </svg>
            <p className='pull-left'>{I18n.t('availability.cleaning_duration')}</p>
            <Range
              className='duration-slider'
              min={2}
              max={12}
              marks={{
                2: '2h',
                12: '12h'
              }}
              tipFormatter={v => `${v}h`}
              step={0.5}
              defaultValue={[duration.minDuration, duration.maxDuration]}
              allowCross={false}
              onAfterChange={interval => {
                handleDurationChange('minDuration', interval[0])
                handleDurationChange('maxDuration', interval[1])
              }}
            />
          </div>
        </div>
        <div className='page__wrapper'>
          <div className='availability'>
            {hours.map(hour => (
              <div
                className={cx({
                  availability__item: true,
                  'availability__item--collapsed': !hour.available,
                  'availability__item--invalid': !hour.valid
                })}
                key={hour.day}
              >
                <button
                  className='availability__available'
                  onClick={() =>
                    handleAvailabilityChange(hour.day, {
                      available: !hour.available
                    })
                  }
                >
                  <input
                    name={`availability[${hour.day}][available]`}
                    type='checkbox'
                    className='fancy-checkbox'
                    checked={hour.available}
                    readOnly
                  />
                  <label htmlFor={`availability[${hour.day}][available]`}>
                    {I18n.t(`availability.day_names.${hour.day}`)}
                  </label>
                </button>
                <div className='availability__hours'>
                  <div className='availability__from'>
                    <TimePicker
                      value={moment(hour.from, 'HH:mm')}
                      showSecond={false}
                      allowEmpty={false}
                      minuteStep={15}
                      onChange={from => handleAvailabilityChange(hour.day, { from })}
                      disabled={!hour.available}
                      addon={timePickerAddon}
                    />
                  </div>
                  <div className='availability__separator'>to</div>
                  <div className='availability__to'>
                    <TimePicker
                      value={moment(hour.to, 'HH:mm')}
                      showSecond={false}
                      allowEmpty={false}
                      minuteStep={15}
                      onChange={to => handleAvailabilityChange(hour.day, { to })}
                      disabled={!hour.available}
                      addon={timePickerAddon}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className='row post-onfido'>
      <div className='small-12 large-6 large-offset-3'>
        <div className='simple-box'>
          <Button
            expanded
            action
            screaming
            rounded
            disabled={isSaving}
            onClick={() => setAvailability(hours, duration, userId)}
          >
            {I18n.t('availability.next')}
          </Button>

          {canGoBack && (
            <Link className='back-button' to={`/become-a-cleaner/${userId}/verticals`}>
              &lt; {I18n.t('availability.back')}
            </Link>
          )}
        </div>{' '}
      </div>{' '}
    </div>
  </Page>
)

Availability.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  duration: PropTypes.object.isRequired,
  hours: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
      selected: PropTypes.bool
    })
  ),
  handleAvailabilityChange: PropTypes.func.isRequired,
  handleDurationChange: PropTypes.func.isRequired,
  setAvailability: PropTypes.func.isRequired,
  params: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }),
  canGoBack: PropTypes.bool.isRequired
}

export default Availability
