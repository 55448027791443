import PropTypes from 'prop-types'
import React from 'react'
import ListItem from './list_item'
import arrowSmallGrey from '../../../svg/arrow_small_grey.svg'

const ListItemWithIcon = ({ icon, text, onClick, extraClasses }) => {
  const render = () => (
    <ListItem extraClasses={extraClasses}>
      <div className='list-item__icon'>
        <svg>
          <use xlinkHref={icon} />
        </svg>
      </div>
      <div className='list-item__text'>{text}</div>
      {!!onClick && (
        <div className='list-item__action'>
          <svg>
            <use xlinkHref={`#${arrowSmallGrey.id}`} />
          </svg>
        </div>
      )}
    </ListItem>
  )

  if (onClick) {
    return (
      <div role='button' onClick={onClick} className='list-item__clickable'>
        {render()}
      </div>
    )
  }

  return <div className='list-item__not-clickable'>{render()}</div>
}

ListItemWithIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  extraClasses: PropTypes.arrayOf(PropTypes.string)
}

ListItemWithIcon.defaultProps = {
  extraTextClasses: []
}

export default ListItemWithIcon
